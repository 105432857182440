import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import * as React from 'react';
import { SnackbarContext } from '../contexts/Snackbar';
import { addGroup } from '../services/Groups';
import { track } from '../utils/analytics';
import CustomizedDialogs from './Dialog';

export default function AddNewGroupDialog({ open, setOpen }) {
  const [groupName, setGroupName] = React.useState('');
  const [errorGroupName, setErrorGroupName] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const { show } = React.useContext(SnackbarContext);

  const add = async () => {
    setErrorGroupName(null);
    track('add_new_group_dialog_create_button_click', { groupName });

    if (groupName.length < 3) {
      setErrorGroupName('Group name is too short');
      return;
    }

    setLoading(true);
    await addGroup(groupName)
      .catch((e) => {
        show('Error occured', 'error');
        track('add_new_group_dialog_creation_failed', {
          groupName,
          error: e.toString(),
        });
        throw e;
      })
      .then((res) => {
        track('add_new_group_dialog_creation_success', { groupName });
      })
      .finally(() => {
        setLoading(false);
      });
    show('Group added', 'success');
    setOpen(false);
  };

  return (
    <CustomizedDialogs
      title={'Add connection group'}
      open={!!open}
      setOpen={setOpen}
    >
      <Stack spacing={3}>
        <TextField
          label="Enter group name"
          fullWidth
          type={'text'}
          sx={{ mt: 1 }}
          onChange={(e) => {
            setGroupName(e.target.value);
            setErrorGroupName(null);
          }}
          error={errorGroupName !== null}
          helperText={errorGroupName}
        />
        <LoadingButton
          loading={loading}
          onClick={() => add()}
          variant="contained"
          size="large"
        >
          Add new connection group
        </LoadingButton>
      </Stack>
    </CustomizedDialogs>
  );
}
