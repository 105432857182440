import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import * as React from 'react';
import CustomizedDialogs from './Dialog';

export default function UploadScheduleDialog({
  open,
  setOpen,
  actions,
  datetime,
  setDatetime,
}) {
  return (
    <CustomizedDialogs
      title={'Schedule for later'}
      open={!!open}
      setOpen={setOpen}
      actions={actions}
    >
      <DateTimePicker
        label="Choose date & time"
        renderInput={(params) => <TextField {...params} />}
        sx={{ mt: 2 }}
        value={dayjs(datetime)}
        minDate={dayjs()}
        onChange={(newValue) => {
          setDatetime(newValue.toDate().getTime());
        }}
        minutesStep={15}
      />
    </CustomizedDialogs>
  );
}
