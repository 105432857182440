import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import * as React from 'react';
import { SnackbarContext } from '../contexts/Snackbar';
import { deleteGroup, renameGroup } from '../services/Groups';
import { track } from '../utils/analytics';
import CustomizedDialogs from './Dialog';

export default function EditGroupDialog({ open, setOpen, groupId }) {
  const [groupName, setGroupName] = React.useState('');
  const [errorGroupName, setErrorGroupName] = React.useState(null);
  const [loadingRename, setLoadingRename] = React.useState(null);
  const [loadingDelete, setLoadingDelete] = React.useState(null);
  const { show } = React.useContext(SnackbarContext);

  const rename = async () => {
    const newGroupName = groupName;
    track('edit_group_dialog_rename_group_button_click', {
      groupId,
      newGroupName,
    });

    setErrorGroupName(null);
    if (newGroupName.length < 3) {
      setErrorGroupName('Group name is too short');
      return;
    }
    setLoadingRename(true);
    await renameGroup(groupId, newGroupName)
      .catch((e) => {
        show('Error occured while renaming group', 'error');
        track('edit_group_dialog_rename_group_action_failed', {
          groupId,
          newGroupName,
        });
        throw e;
      })
      .then((res) => {
        track('edit_group_dialog_rename_group_action_success', {
          groupId,
          newGroupName,
        });
      })
      .finally(() => {
        setLoadingRename(false);
      });
    show('Group renamed', 'success');
    setOpen(false);
  };

  const deleteGroupById = async () => {
    track('edit_group_dialog_delete_group_button_click', { groupId });

    setLoadingDelete(true);
    await deleteGroup(groupId)
      .catch((e) => {
        show('Error occured while deleting group', 'error');
        track('edit_group_dialog_delete_group_action_failed', { groupId });
        throw e;
      })
      .then((res) => {
        track('edit_group_dialog_delete_group_action_success', { groupId });
      })
      .finally(() => {
        setLoadingDelete(false);
      });
    show('Group deleted', 'success');
    setOpen(false);
  };

  return (
    <CustomizedDialogs
      title={'Rename a connection group'}
      open={!!open}
      setOpen={setOpen}
    >
      <Stack spacing={3}>
        <TextField
          label="Enter new group name"
          fullWidth
          type={'text'}
          sx={{ mt: 1 }}
          onChange={(e) => {
            setGroupName(e.target.value);
            setErrorGroupName(null);
          }}
          error={errorGroupName !== null}
          helperText={errorGroupName}
        />
        <Stack direction={'row'} spacing={3}>
          <LoadingButton
            loading={loadingDelete}
            onClick={() => deleteGroupById()}
            variant="contained"
            size="large"
            fullWidth
            sx={{ bgcolor: '#D32F2F' }}
          >
            Delete
          </LoadingButton>
          <LoadingButton
            loading={loadingRename}
            onClick={() => rename()}
            disabled={groupName === ''}
            variant="contained"
            size="large"
            fullWidth
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </CustomizedDialogs>
  );
}
