import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useState } from 'react';
import { GroupsContext } from '../contexts/Groups';
import firebase from '../Firebase';

import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { SnackbarContext } from '../contexts/Snackbar';
import { track } from '../utils/analytics';
import CustomizedDialogs from './Dialog';

const ClaimRequestDialog = ({ open, setOpen, requestId }) => {
  const { show } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(true);
  const [request, setRequest] = useState(null);
  const { groups, channelsByGroupId, channelById } = useContext(GroupsContext);

  useEffect(() => {
    if (!requestId) return;

    track('claim_request_dialog_view', { requestId });

    setIsLoadingRequest(true);
    const snapshot = firebase
      .firestore()
      .collection('claimRequests')
      .doc(requestId)
      .onSnapshot((doc) => {
        setRequest(doc.exists ? doc : null);
        setIsLoadingRequest(false);
      });

    return () => {
      snapshot();
    };
  }, [requestId]);

  const onAcceptButtonClick = () => {
    track('claim_request_dialog_accept_button_click', { requestId });

    setIsLoadingAccept(true);
    const functions = getFunctions(getApp());

    const createClaimRequest = httpsCallable(functions, 'claim-acceptRequest');
    createClaimRequest({ claimRequestId: request.id })
      .then((result) => {
        track('claim_request_dialog_accepted_successfully', { requestId });

        navigate('/dashboard/manage-accounts');
      })
      .catch((e) => {
        show(`Failed to accept claim request, ${e.toString()}`, 'error');
      })
      .finally(() => {
        setIsLoadingAccept(false);
      });
  };

  const onRejectButtonClick = () => {
    track('claim_request_dialog_reject_button_click', { requestId });

    navigate('/dashboard/calendar');
  };

  return (
    <CustomizedDialogs
      title={'A request to claim a video library is waiting for your approval'}
      open={!!open}
      setOpen={setOpen}
    >
      {isLoadingRequest && <CircularProgress />}
      {request?.data()?.status !== 'pending' && !isLoadingRequest && (
        <Typography>This request is no longer valid</Typography>
      )}
      {request?.data()?.status === 'pending' && (
        <>
          <Stack
            direction={'row'}
            spacing={3}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ marginTop: '24px' }}
          >
            <LoadingButton
              variant="contained"
              size="large"
              color="success"
              sx={{ height: 40 }}
              fullWidth={false}
              onClick={onAcceptButtonClick}
              loading={isLoadingAccept}
            >
              Approve
            </LoadingButton>
            <Button
              variant="contained"
              size="large"
              color="error"
              sx={{ height: 40 }}
              fullWidth={false}
              onClick={onRejectButtonClick}
              disabled={isLoadingAccept}
            >
              Reject
            </Button>
          </Stack>
        </>
      )}
    </CustomizedDialogs>
  );
};

export default ClaimRequestDialog;
