import {
  Box,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import AccountListItem from '../components/AccountListItem';
import AddNewAccountDialog from '../components/AddNewAccountDialog';
import AddNewGroupDialog from '../components/AddNewGroupDialog';
import CustomCalendar from '../components/CustomCalendar';
import ManageYoutubeAccountDialog from '../components/ManageYoutubeAccountDialog';
import { AuthContext } from '../contexts/Auth';
import { GroupsContext } from '../contexts/Groups';
import { SubscriptionContext } from '../contexts/Subscription';
import useResponsive from '../hooks/useResponsive';
import { track } from '../utils/analytics';

const events = [
  { title: 'Meeting1', start: new Date() },
  { title: 'Meeting1', start: new Date() },
  {
    title: 'Meeting1',
    start: new Date(new Date().getTime() - 86400000 - 4 * 60 * 60 * 1000),
  },
  {
    title: 'Meeting1',
    start: new Date(new Date().getTime() - 2 * 86400000 - 6 * 60 * 60 * 1000),
  },
  {
    title: 'Meeting1',
    start: new Date(new Date().getTime() - 4 * 86400000 - 2 * 60 * 60 * 1000),
  },
];

const Calender = () => {
  const isDesktop = useResponsive('up', 'md');
  useEffect(() => {}, []);
  const [openAddNewGroupDialog, setOpenAddNewGroupDialog] =
    React.useState(false);
  const [openAddNewAccountDialog, setOpenAddNewAccountDialog] =
    React.useState(null);
  const [openManageYoutubeAccountDialog, setOpenManageYoutubeAccountDialog] =
    React.useState(null);
  const [openAccountsDrawer, setOpenAccountsDrawer] = React.useState(null);
  const navigate = useNavigate();
  const { groups, channelsByGroupId } = useContext(GroupsContext);
  const { currentUser, logout, appUser, subscriptionMaybeActive } =
    React.useContext(AuthContext);
  const { leftFreeSchedules } = React.useContext(SubscriptionContext);

  const getAccountsContent = () => {
    return (
      <Stack
        direction={'column'}
        sx={{
          width: '100%',
          bgcolor: '#FFFFFF',
          maxWidth: 248,
          minWidth: 248,
          height: 'calc(100vh - 64px)',
          position: isDesktop ? 'relative' : 'relative',
          left: isDesktop ? 0 : 0,
          top: 0,
          zIndex: 99999,
        }}
      >
        <Box sx={{ overflow: 'auto' }}>
          {groups &&
            groups.map((group) => (
              <List key={group.id} sx={{ p: 0 }}>
                <ListItem
                  sx={{
                    bgcolor: '#F6FAFD',
                    borderLeft: '3px solid #1976D2',
                  }}
                >
                  <ListItemText>{group.data().name}</ListItemText>
                </ListItem>
                {channelsByGroupId(group.id)?.length === 0 && (
                  <Typography
                    sx={{ fontSize: 14, textAlign: 'left', ml: 2.5, mt: 1 }}
                  >
                    No accounts
                  </Typography>
                )}
                {channelsByGroupId(group.id)?.map((account, index) => (
                  <AccountListItem
                    key={account.id}
                    account={account}
                    onClick={() =>
                      account.data().type === 'youtube' &&
                      setOpenManageYoutubeAccountDialog({
                        groupId: group.id,
                        channelId: account.id,
                      })
                    }
                  />
                ))}
              </List>
            ))}
        </Box>
        <Stack
          sx={{
            pl: 2,
            pr: 2,
            pb: 1,
          }}
          direction={'column'}
          alignItems={'center'}
          justifyItems={'center'}
        >
          {groups.length === 0 && (
            <Typography sx={{ fontSize: 14, fontWeight: 400, pt: 3 }}>
              You don&apos;t have any connections, please add your social
              network accounts to start scheduling.
            </Typography>
          )}
          <Button
            onClick={() => {
              track('calendar_manage_accounts_button_click');

              navigate('/dashboard/manage-accounts');
            }}
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            size="medium"
          >
            Manage Accounts
          </Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ flex: 1 }}>
      <Drawer
        open={openAccountsDrawer}
        onClose={() => setOpenAccountsDrawer(false)}
        anchor={'left'}
      >
        {getAccountsContent()}
      </Drawer>
      <AddNewGroupDialog
        open={openAddNewGroupDialog}
        setOpen={setOpenAddNewGroupDialog}
        groupId={openAddNewAccountDialog}
      />
      <AddNewAccountDialog
        open={openAddNewAccountDialog}
        setOpen={setOpenAddNewAccountDialog}
      />
      <ManageYoutubeAccountDialog
        open={openManageYoutubeAccountDialog}
        setOpen={setOpenManageYoutubeAccountDialog}
        groupId={openManageYoutubeAccountDialog?.groupId}
        channelId={openManageYoutubeAccountDialog?.channelId}
      />
      <Stack
        direction={'row'}
        spacing={3}
        sx={{ width: '100%', bgcolor: '#F6FAFD' }}
      >
        {isDesktop && getAccountsContent()}
        <Box
          sx={{
            pr: isDesktop ? 3 : 0,
            pl: isDesktop ? 3 : 0,
            pt: 3,
            flexGrow: 1,
            overflowX: 'auto',
          }}
        >
          {!isDesktop &&
            appUser?.subscriptionStatus !== 'active' &&
            !subscriptionMaybeActive && (
              <Stack direction={'row'} sx={{ mb: 3 }}>
                <Box
                  sx={{
                    flexGrow: 1,
                    marginInlineEnd: '16px',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'start',
                    pl: 3,
                  }}
                >
                  {leftFreeSchedules > 0 ? (
                    <Typography>{leftFreeSchedules} posts left</Typography>
                  ) : (
                    <Typography sx={{ color: 'red' }}>
                      No more posts left
                    </Typography>
                  )}
                </Box>

                <Button
                  variant="contained"
                  sx={{ bgcolor: '#089d6f', mr: 3 }}
                  onClick={() => {
                    track('app_bar_start_free_trial_button_click', {
                      location: window.location.href,
                    });

                    navigate('/plans');
                  }}
                >
                  Get unlimited posts
                </Button>
              </Stack>
            )}
          <CustomCalendar
            setOpenAccountsDrawer={setOpenAccountsDrawer}
            isSubscriber={
              !(
                appUser?.subscriptionStatus !== 'active' &&
                !subscriptionMaybeActive
              )
            }
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default Calender;
