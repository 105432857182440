import mixpanel from 'mixpanel-browser';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { AuthContext } from './Auth';

export const SubscriptionContext = createContext();

const MAX_FREE_SCHEDULES = 20;

function SubscriptionContextProvider(props) {
  const { appUser } = useContext(AuthContext);

  useEffect(() => {
    mixpanel.people.set({
      role: appUser?.role,
      subscriptionStatus: appUser?.subscriptionStatus,
      subscriptionType: appUser?.subscriptionType ?? 'free',
      subscriptionAllowedAccountSets: appUser?.subscriptionAllowedAccountSets,
      freeUploadedSchedules: appUser?.freeUploadedSchedulesIds?.length,
    });
  }, [appUser]);

  const freeUploadedSchedules = useMemo(
    () => appUser?.freeUploadedSchedulesIds?.length ?? 0,
    [appUser],
  );
  const leftFreeSchedules = useMemo(
    () => MAX_FREE_SCHEDULES - freeUploadedSchedules,
    [freeUploadedSchedules],
  );
  const subscriptionAllowedAccountSets = useMemo(
    () => appUser?.subscriptionAllowedAccountSets ?? 1,
    [appUser],
  );

  return (
    <SubscriptionContext.Provider
      value={{
        freeUploadedSchedules,
        leftFreeSchedules,
        MAX_FREE_SCHEDULES,
        subscriptionAllowedAccountSets,
      }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  );
}

export default SubscriptionContextProvider;
