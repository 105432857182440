import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';

export function track(eventName, parameters) {
  try {
    console.debug('[EVENT_TRACK]', eventName, 'parameters:', parameters);
  } catch (e) {}

  if (process.env.NODE_ENV === 'development') {
    return;
  }

  try {
    mixpanel.track(eventName, {
      ...(parameters || {}),
      url: window.location.href,
    });
  } catch (e) {
    console.error('failed to send event to mixpanel');
    // TODO: report to sentry
  }

  try {
    if (hotjar.initialized()) {
      hotjar.event(eventName);
    }
  } catch (e) {
    console.error('failed to send event to hotjar');
    // TODO: report to sentry
  }
}
