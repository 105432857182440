import {
  AccessTimeRounded,
  PauseCircleFilled,
  PlayCircleFilled,
  Warning,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { GroupsContext } from '../contexts/Groups';
import { getChannelConfigProperty, insertBetweenItems } from '../utils';
import { track } from '../utils/analytics';
import { getScheduleConfigIssues } from '../utils/schedule';

const CalendarEvent = ({ schedule, inTheFuture }) => {
  useEffect(() => {}, []);

  const { channelById, groups } = useContext(GroupsContext);
  const [playIsOn, setPlayIsOn] = useState(false);
  const navigate = useNavigate();

  const play = () => {
    return;
    const videoPlayer = document.getElementById(`videoPlayer_${schedule.id}`);
    // Auto play, half volume.
    if (playIsOn) {
      videoPlayer.pause();
      setPlayIsOn(false);
    } else {
      videoPlayer.play();
      setPlayIsOn(true);
    }
  };

  return (
    <Box
      sx={{ flex: 1, cursor: inTheFuture ? 'pointer' : 'auto' }}
      onClick={() => {
        if (inTheFuture) {
          track('calendar_event_click', {
            scheduleId: schedule.id,
          });
          navigate(`/dashboard/edit/${schedule.id}`);
        }
      }}
    >
      <Stack
        direction={'column'}
        spacing={0.5}
        sx={{
          height: '100%',
          borderLeft: '4px solid #1565C0',
        }}
        divider={<Divider />}
      >
        {schedule.data().channels.map((channel) => {
          const issues = getScheduleConfigIssues(schedule.data(), [
            channelById(channel.groupId, channel.channelId)?.data()?.type,
          ]);
          const uploadStatus =
            schedule.data().uploadStatus?.[channel.channelId];
          return (
            <Box
              key={channel.channelId}
              style={{
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
              }}
            >
              <Stack
                direction={'row'}
                spacing={1}
                alignItems={'center'}
                sx={{ p: 0.5, height: '100%' }}
              >
                <Stack direction={'column'} spacing={0.25} sx={{ flexGrow: 1 }}>
                  <Stack
                    direction={'row'}
                    spacing={1}
                    alignItems={'center'}
                    justifyContent={'start'}
                  >
                    <img
                      src={require(`../assets/icons/${
                        channelById(channel.groupId, channel.channelId)?.data()
                          .type ?? 'unknown'
                      }Colored.png`)}
                      height={14}
                    />
                    <Typography
                      sx={{
                        color: 'black',
                        fontSize: 11,
                        fontWeight: 700,
                        textAlign: 'start',
                      }}
                    >
                      {channelById(channel.groupId, channel.channelId)?.data()
                        .channelName ?? 'unknown'}
                    </Typography>
                    {!!issues.length && (
                      <Tooltip
                        title={
                          <Box sx={{ whiteSpace: 'normal' }}>
                            Action needed: This post does not contain any
                            description/title. Please add the details for the
                            missing information:
                            <br />
                            {insertBetweenItems(issues, <br />)}
                          </Box>
                        }
                      >
                        <Warning fontSize="12px" color="warning" />
                      </Tooltip>
                    )}
                    {uploadStatus?.status === 'failed' && (
                      <Tooltip
                        title={`Failed to upload, ${uploadStatus?.reason}`}
                      >
                        <Warning fontSize="12px" color="warning" />
                      </Tooltip>
                    )}
                  </Stack>
                  <Typography
                    sx={{ color: 'black', fontSize: 9, textAlign: 'start' }}
                  >
                    {(
                      getChannelConfigProperty(
                        'description',
                        schedule.data().config,
                        channel.channelId,
                        channelById(channel.groupId, channel.channelId)?.data()
                          .type,
                      ) ?? ''
                    ).slice(0, 24)}
                  </Typography>
                  <Stack direction={'row'} spacing={0.25} alignItems={'center'}>
                    <AccessTimeRounded
                      sx={{ color: 'primary.main' }}
                      fontSize={'12'}
                    />
                    <Typography sx={{ color: 'black', fontSize: 11 }}>
                      {moment(
                        schedule.data().scheduledOn.seconds * 1000,
                      ).format('hh:mm A')}
                    </Typography>
                  </Stack>
                </Stack>
                <Box
                  sx={{
                    width: 52,
                    height: 60,
                    bgcolor: 'lightgray',
                    borderRadius: 0.5,
                    position: 'relative',
                  }}
                >
                  <video
                    style={{ height: '100%', width: '100%' }}
                    controls={false}
                    id={`videoPlayer_${schedule.id}`}
                  >
                    <source src={schedule.data().videoUrl} />
                  </video>
                  <IconButton
                    sx={{ position: 'absolute', top: 12, left: 3 }}
                    onClick={() => play()}
                  >
                    {playIsOn ? (
                      <PauseCircleFilled sx={{ color: 'black' }} />
                    ) : (
                      <PlayCircleFilled sx={{ color: 'black' }} />
                    )}
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default CalendarEvent;
