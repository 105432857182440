import { LoadingButton } from '@mui/lab';
import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { track } from '../utils/analytics';

const DownloadCard = ({ orderId, link }) => {
  return (
    <Box
      sx={{
        // border: '1px solid #dce4ec',
        borderRadius: 1,
        p: 2,
        pt: 3,
        pb: 3,
        bgcolor: 'white',
        boxShadow: '0px 4px 12px #1976D220',
        width: '100%',
        flexGrow: 1,
      }}
    >
      <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
        <Typography
          sx={{
            fontSize: 32,
            fontWeight: 700,
            textAlign: 'start',
            color: 'black',
          }}
        >
          Download videos
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            textAlign: 'start',
          }}
        >
          Download your videos from Google Drive
        </Typography>
        <div></div>
        <Divider />
        <div></div>
        <div>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: 'start',
              opacity: 0.7,
              minHeight: 120,
            }}
          >
            Download our engaging short videos from Google Drive and infuse your
            brand&apos;s essence. Tailor your posts, captions, and timing as you
            manually upload them across various social media platforms. Shape
            each post to reflect your uniqueness.
          </Typography>
        </div>
        <div></div>
        <div style={{ height: 24, width: '100%' }}></div>
        <LoadingButton
          variant="contained"
          onClick={() => {
            track('order_page_click_download_videos', {
              orderId,
              link,
            });
            window.open(link, '_blank');
          }}
          size="large"
          sx={{
            'height': 56,
            'bgcolor': 'white',
            'color': 'black',
            'boxShadow': 'red',
            'pl': 2,
            'pr': 2,
            'width': '100%',
            ':hover': {
              bgcolor: 'white',
            },
          }}
          startIcon={
            <img
              src={require('../assets/icons/driveClear.png')}
              alt=""
              width={32}
              height={32}
              style={{ marginRight: 8 }}
            />
          }
        >
          Download from Google Drive
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default DownloadCard;
