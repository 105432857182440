import mixpanel from 'mixpanel-browser';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import firebase from '../Firebase';
import { AuthContext } from './Auth';

export const GroupsContext = createContext();

function GroupsContextProvider(props) {
  const { isAuthenticated, currentUserId } = useContext(AuthContext);

  const [teams, setTeams] = useState(null);
  const [groupsMap, setGroupsMap] = useState({});
  // const [groups, setGroups] = useState([]);
  const [channelsByGroupMap, setChannelsByGroupMap] = useState({});

  const groups = useMemo(() => {
    return Object.values(groupsMap);
  }, [groupsMap]);

  // useEffect(() => {
  //   setGroups(Object.values(groupsMap));
  // }, [groupsMap, setGroups]);

  useEffect(() => {
    mixpanel.people.set({
      groupsCount: Object.keys(groupsMap).length,
      channelsCount: Object.entries(channelsByGroupMap ?? {})
        .map(([k, c]) => c)
        .flat().length,
      channelTypes: Array.from(
        new Set(
          Object.entries(channelsByGroupMap ?? {})
            .map(([k, c]) => c.map((cc) => cc.data().type))
            .flat(),
        ),
      ),
    });
  }, [groupsMap]);

  useEffect(() => {
    setTeams(null);
    setGroupsMap({});
    setChannelsByGroupMap({});
  }, [currentUserId]);

  useEffect(() => {
    if (!isAuthenticated) {
      setTeams(null);
      setGroupsMap({});
      setChannelsByGroupMap({});
      return;
    }
    const unsubscribe = firebase
      .firestore()
      .collectionGroup('team')
      .where('userId', '==', currentUserId || firebase.auth().currentUser.uid)
      .orderBy('createdAt', 'asc')
      .onSnapshot(
        (event) => setTeams(event.docs),
        (e) => console.error(e),
      );
    return () => {
      unsubscribe();
    };
  }, [
    isAuthenticated,
    setTeams,
    setGroupsMap,
    setChannelsByGroupMap,
    currentUserId,
  ]);

  useEffect(() => {
    if (!teams) {
      setGroupsMap({});
      setChannelsByGroupMap({});
      return;
    }
    const unsubscribers = teams
      .map((team) => {
        return [
          firebase
            .firestore()
            .collection('groups')
            .doc(team.ref.parent.parent.id)
            .onSnapshot((event) => {
              setGroupsMap((groups) => {
                if (!event.exists) {
                  delete groups[team.ref.parent.parent.id];
                } else {
                  groups[team.ref.parent.parent.id] = event;
                }
                return { ...groups };
              });
            }),
          firebase
            .firestore()
            .collection('groups')
            .doc(team.ref.parent.parent.id)
            .collection('channels')
            .onSnapshot((event) => {
              setChannelsByGroupMap((channelsByGroups) => {
                channelsByGroups[team.ref.parent.parent.id] = event.docs;
                return { ...channelsByGroups };
              });
            }),
        ];
      })
      .flat();
    return () => {
      unsubscribers.map((s) => s());
    };
  }, [teams, setGroupsMap, setChannelsByGroupMap]);

  return (
    <GroupsContext.Provider
      value={{
        groupsMap,
        groups,
        channelsByGroupMap: channelsByGroupMap,
        channelsByGroupId: (id) => channelsByGroupMap?.[id],
        channelById: (groupId, channelId) =>
          channelsByGroupMap?.[groupId]?.find((c) => c.id === channelId),
        allChannels: () =>
          Object.entries(channelsByGroupMap ?? {})
            .map(([k, c]) => c)
            .flat(),
      }}
    >
      <div>{props.children}</div>
    </GroupsContext.Provider>
  );
}

export default GroupsContextProvider;
