import { React, useContext, useEffect, useState } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import './App.css';

import firebase from './Firebase';
import Login from './screens/Login';
import Signup from './screens/Signup';

import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';
import { useIntercom } from 'react-use-intercom';
import DashboardLayout from './components/DashboardLayout';
import Loader from './components/Loader';
import { AuthContext } from './contexts/Auth';
import Account from './screens/Account';
import Admin from './screens/Admin';
import AuthActions from './screens/AuthActions';
import Calender from './screens/Calender';
import ManageAccounts from './screens/ManageAccounts';
import OrderPage from './screens/OrderPage';
import Plans from './screens/Plans';
import Privacy from './screens/Privacy';
import ResetPassword from './screens/ResetPassword';
import Terms from './screens/Terms';
import Upload from './screens/Upload';
import { track } from './utils/analytics';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [updateComponent, setUpdateComponent] = useState([]);
  const { isAuthenticated, setCurrentUser, realAppUser, setIsAuthenticated } =
    useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const { update } = useIntercom();

  const updateMixpanel = async (user) => {
    if (user != null) {
      const distinctId = await mixpanel.get_distinct_id();
      mixpanel.identify(user.uid);

      if (distinctId !== user.uid) {
        mixpanel.alias(distinctId, user.uid);
      }

      mixpanel.people.set({
        $email: user.email,
        $name: user.displayName,
        $phone: user.phoneNumber,
        $avatar: user.photoURL,
      });
    }
  };

  const updateHotjar = async (user) => {
    if (user != null) {
      if (hotjar.initialized()) {
        hotjar.identify(user.id, {
          email: user.email,
          name: user.displayName,
          phone: user.phoneNumber,
          avatar: user.photoURL,
        });
      }
    }
  };

  useEffect(() => {
    track('app_startup');
  }, []);

  useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.stateChange(location.pathname);
    }

    track('page_view', location.pathname);
  }, [location]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user && !user.isAnonymous) {
        update({
          name: user.displayName,
          email: user.email,
        });
        setIsAuthenticated(true);
        if (searchParams.get('goto')) {
          console.log('xxx', searchParams.get('goto'));
          navigate(searchParams.get('goto'));
        } else if (
          !window.location.pathname.includes('dashboard') &&
          !window.location.pathname.includes('plans') &&
          !window.location.pathname.includes('privacy') &&
          !window.location.pathname.includes('terms') &&
          !window.location.pathname.includes('order') &&
          window.location.pathname !== '/'
        ) {
          console.log('window.location.pathname', window.location.pathname);
          navigate({
            pathname: '/dashboard/calendar',
          });
        }
      } else {
        setIsAuthenticated(false);
      }
      updateMixpanel(user);
      updateHotjar(user);
    });

    return () => {
      unsubscribe();
    };
  }, [setCurrentUser, setIsAuthenticated, location, searchParams]);

  const PrivateRoute = ({ isAuthenticated }) => {
    return isAuthenticated ? (
      <Outlet />
    ) : (
      <>
        {navigate({
          pathname: '/login',
          search: `?goto=${window.location.pathname + window.location.search}`,
        })}
        {setUpdateComponent(...[])}
      </>
    );
  };

  return (
    <div className="App" style={{ overflowX: 'hidden' }}>
      {/* <Hidden smUp>
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            background: 'white',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'black',
            zIndex: 99999999,
          }}
        >
          <Stack
            direction={'column'}
            spacing={3}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ p: 3 }}
          >
            <img
              src={require('./assets/images/blueLogo.png')}
              height={38}
              alt=""
              style={{ marginRight: 0, cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
            <Typography>
              Sorry but we are not yet available for mobile. The experience from
              the computer is much more convenient, try there please.
            </Typography>
          </Stack>
        </div>
      </Hidden> */}
      {isAuthenticated === null ? (
        <Loader />
      ) : (
        <Routes>
          <Route path="/__/auth/action" element={<AuthActions />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/order/:orderId" element={<OrderPage />} />
          <Route
            exact
            path="/dashboard"
            element={<PrivateRoute isAuthenticated={isAuthenticated} />}
          >
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="manage-accounts" element={<ManageAccounts />} />
              <Route path="calendar" element={<Calender />} />
              <Route path="upload" element={<Upload />} />
              <Route path="account" element={<Account />} />
              <Route path="" element={<Navigate to={'calendar'} />} />
              <Route path="edit/:scheduleId" element={<Upload editMode />} />
              {realAppUser?.role === 'admin' && (
                <Route exact path="admin">
                  <Route path="" element={<Admin />} />
                </Route>
              )}
            </Route>
          </Route>
          <Route path="/order/:orderId" element={<OrderPage />} />
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
