import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ width: 24, height: 24 }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  title,
  open,
  setOpen,
  children,
  maxWidth = 'sm',
  actions = [],
  canClose = true,
  actionsPrefix,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BootstrapDialog
      onClose={canClose ? handleClose : () => null}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={maxWidth}
      fullWidth
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={canClose ? handleClose : null}
        style={{ fontSize: 24 }}
      >
        {title}
      </BootstrapDialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions.length > 0 && (
        <DialogActions sx={{ marginInline: '24px', mb: 1 }}>
          <Box key={'actionsPrefix'}>{actionsPrefix && actionsPrefix}</Box>
          <Box key={'spacer'} sx={{ flex: 1 }} />
          {actions.map((action) => {
            switch (action.type) {
              case 'loader':
                return action.disabled ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <Box key={JSON.stringify(action)}></Box>
                ) : (
                  <CircularProgress
                    key={JSON.stringify(action)}
                    size={24}
                    sx={{ marginInline: 1 }}
                  />
                );

              default:
                return (
                  <Button
                    key={JSON.stringify(action)}
                    onClick={action.function}
                    variant={action.variant}
                    disabled={action.disabled}
                  >
                    {action.title}
                  </Button>
                );
            }
          })}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}
