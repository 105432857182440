import { LoadingButton } from '@mui/lab';
import {
  Card,
  Container,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useState } from 'react';
import VerifyBeforeCancelSubscriptionDialog from '../components/VerifyBeforeCancelSubscriptionDialog';
import { AuthContext } from '../contexts/Auth';
import { SnackbarContext } from '../contexts/Snackbar';
import { track } from '../utils/analytics';

const Account = () => {
  const { show } = useContext(SnackbarContext);
  const { appUser, activeSubscription, currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [openVerifyCancelDialog, setOpenVerifyCancelDialog] = useState(false);
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const functions = getFunctions();

  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    if (currentUser) {
      setDisplayName(currentUser.displayName);
    }
  }, [currentUser]);

  const cancelSubscriptionFunction = httpsCallable(
    functions,
    'payment-cancelSubscription',
  );

  const cancelSubscription = async (reason) => {
    track('account_screen_cancel_subscription_for_sure_button_click', {
      subscriptionId: activeSubscription.id,
      reason,
    });

    setOpenVerifyCancelDialog(false);
    setLoading(true);
    cancelSubscriptionFunction({
      subscriptionId: activeSubscription.id,
      reason,
    })
      .then((res) => {
        if (res.data.results.code === 0) {
          show('Subscription cancelled successfully', 'success');
        } else {
          show(res.data.results.description, 'error');
        }
      })
      .catch((e) => {
        show(JSON.stringify(e), 'error');
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uppercaseFirstLetter = (name) => {
    return name[0].toUpperCase() + name.slice(1);
  };

  const updateProfile = () => {
    track('account_screen_update_profile_button_click', { displayName });

    setUpdatingProfile(true);
    if (displayName.length === 0) {
      show('Name can not be empty!', 'error');
      return;
    }
    currentUser
      .updateProfile({
        displayName,
      })
      .then(() => {
        show('Updated successfully', 'success');
      })
      .catch((error) => {
        show(error?.message, 'error');
      })
      .finally(() => setUpdatingProfile(false));
  };

  const getSocialSetNumber = (s) => {
    if (s.items[0]?.name === 'starter') {
      return 1;
    } else if (s.items[0]?.name === 'creator') {
      return 3;
    } else if (s.items[0]?.name === 'agency') {
      return 10;
    }
    return 0;
  };

  const getPostsNumber = (s) => {
    if (s.items[0]?.name === 'starter') {
      return '30 per social set';
    } else if (s.items[0]?.name === 'creator') {
      return '150 per social set';
    } else if (s.items[0]?.name === 'agency') {
      return '500 per social set';
    }
    return 0;
  };

  return (
    <Container maxWidth={'lg'}>
      <VerifyBeforeCancelSubscriptionDialog
        open={openVerifyCancelDialog}
        setOpen={setOpenVerifyCancelDialog}
        cancelSubscription={cancelSubscription}
        loading={loading}
      />
      <Stack direction={'column'} sx={{ mt: 3, pb: 3 }} spacing={3}>
        <Typography sx={{ fontSize: 28, fontWeight: 700, textAlign: 'start' }}>
          My account
        </Typography>
        <Card elevation={0} sx={{ p: 3, textAlign: 'start' }}>
          <Stack direction={'column'} spacing={3}>
            <Typography
              sx={{ fontSize: 21, fontWeight: 700, textAlign: 'start' }}
            >
              Personal details
            </Typography>
            <Stack direction={'row'} spacing={2}>
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label="Full name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </Stack>
            <LoadingButton
              sx={{ width: 'fit-content' }}
              fullWidth={false}
              variant="outlined"
              loading={updatingProfile}
              onClick={() => updateProfile()}
            >
              Update
            </LoadingButton>
          </Stack>
        </Card>
        {activeSubscription && (
          <Card elevation={0} sx={{ p: 3, textAlign: 'start' }}>
            <Stack direction={'column'} spacing={3}>
              <Stack direction={'row'} spacing={3}>
                <Typography
                  sx={{ fontSize: 21, fontWeight: 700, textAlign: 'start' }}
                >
                  Subscription
                </Typography>
                <Typography
                  sx={{ fontSize: 21, fontWeight: 400, textAlign: 'start' }}
                >
                  {uppercaseFirstLetter(
                    activeSubscription.data().items[0]?.name,
                  )}{' '}
                  / Monthly
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={4}>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 500, textAlign: 'start' }}
                  >
                    Start date
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 400, textAlign: 'start' }}
                  >
                    {activeSubscription.data().start_date}
                  </Typography>
                </Stack>
                {activeSubscription.data().end_date && (
                  <Stack direction={'column'} spacing={1}>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: 500, textAlign: 'start' }}
                    >
                      End date
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: 400, textAlign: 'start' }}
                    >
                      {activeSubscription.data().end_date}
                    </Typography>
                  </Stack>
                )}
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 500, textAlign: 'start' }}
                  >
                    Charges
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 400, textAlign: 'start' }}
                  >
                    {activeSubscription.data().number_of_charges}
                  </Typography>
                </Stack>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 500, textAlign: 'start' }}
                  >
                    Social set
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 400, textAlign: 'start' }}
                  >
                    {getSocialSetNumber(activeSubscription.data())}
                  </Typography>
                </Stack>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 500, textAlign: 'start' }}
                  >
                    Posts
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 400, textAlign: 'start' }}
                  >
                    {getPostsNumber(activeSubscription.data())}
                  </Typography>
                </Stack>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 500, textAlign: 'start' }}
                  >
                    Price
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 400, textAlign: 'start' }}
                  >
                    {`$${activeSubscription.data().amount}`}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <LoadingButton
                  sx={{ width: 'fit-content' }}
                  fullWidth={false}
                  variant="contained"
                  onClick={() => {
                    track('account_screen_upgrade_button_click');

                    show('Contact support');
                  }}
                >
                  Upgrade
                </LoadingButton>
                <LoadingButton
                  sx={{
                    width: 'fit-content',
                  }}
                  fullWidth={false}
                  variant="outlined"
                  loading={loading}
                  onClick={() => {
                    track('account_screen_cancel_subscription_button_click', {
                      subscriptionId: activeSubscription.id,
                    });

                    setOpenVerifyCancelDialog(true);
                  }}
                >
                  Cancel
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        )}
        <Card elevation={0} sx={{ p: 3, textAlign: 'start' }}>
          <Stack direction={'column'} spacing={3}>
            <Typography
              sx={{ fontSize: 21, fontWeight: 700, textAlign: 'start' }}
            >
              Help & Support
            </Typography>
            <Typography
              sx={{ fontSize: 16, fontWeight: 500, textAlign: 'start' }}
            >
              Contact us for any question or help you need.
            </Typography>
            <Stack direction={'row'} spacing={2}>
              <LoadingButton
                sx={{ width: 'fit-content' }}
                fullWidth={false}
                variant="contained"
                href="mailto:admin@socialoop.io"
              >
                Contact us
              </LoadingButton>
            </Stack>
          </Stack>
        </Card>
        <Stack direction={'row'} spacing={2} sx={{ pb: 4 }}>
          <Link href="/terms">Terms of Use</Link>
          <div style={{ textAlign: 'center', width: 20, color: 'white' }}>
            |
          </div>
          <Link href="/privacy">Privacy Policy</Link>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Account;
