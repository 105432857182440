import moment from 'moment-timezone';
import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from '../Firebase';
import { chunkArray, distinct } from '../utils';
import { AuthContext } from './Auth';
import { GroupsContext } from './Groups';
import { SnackbarContext } from './Snackbar';

export const SchedulesContext = createContext();

function SchedulesContextProvider(props) {
  const { isAuthenticated, currentUserId } = useContext(AuthContext);
  const { show } = useContext(SnackbarContext);
  const { groups, allChannels } = useContext(GroupsContext);
  const [firstDay, setFirstDay] = useState(null);

  const [schedulesMap, setSchedulesMap] = useState({});

  useEffect(() => {
    setSchedulesMap({});
  }, [currentUserId]);

  useEffect(() => {
    setFirstDay(moment().startOf('week'));
  }, []);

  useEffect(() => {
    if (!isAuthenticated || !firstDay) return;

    const channels = allChannels();
    if (!channels.length) return;

    const snapshots = fetchSchedules(moment(firstDay), channels);

    return () => {
      snapshots.forEach((s) => s());
    };
  }, [isAuthenticated, firstDay, groups]);

  const refresh = () => {
    setFirstDay((c) => moment(c));
  };

  const removeScheduleFromMap = (schedule) => {
    setSchedulesMap((current) => {
      const startWeekDate = moment(schedule.data().scheduledOn.seconds * 1000)
        .startOf('week')
        .valueOf();

      const schedules = current[startWeekDate];
      if (!schedules) return current;

      current[startWeekDate] = schedules.filter((s) => s.id !== schedule.id);
      return { ...current };
    });
  };

  const fetchScheduleById = async (scheduleId) => {
    const doc = await firebase
      .firestore()
      .collection('schedule')
      .doc(scheduleId)
      .get();
    const temp = schedulesMap;
    const startWeekDate = moment(doc.data().scheduledOn.seconds * 1000)
      .startOf('week')
      .valueOf();
    if (temp[startWeekDate]) {
      temp[startWeekDate] = [...temp[startWeekDate], startWeekDate];
    } else {
      temp[startWeekDate] = [doc];
    }
    setSchedulesMap({ ...temp });
  };

  const fetchSchedules = (start, channels) => {
    const snapshots = chunkArray(channels, 10).map((chunk) =>
      firebase
        .firestore()
        .collection('schedule')
        .where(
          'scheduledOn',
          '>=',
          firebase.firestore.Timestamp.fromDate(start.toDate()),
        )
        .where(
          'scheduledOn',
          '<',
          firebase.firestore.Timestamp.fromDate(
            moment(start).add(7, 'days').toDate(),
          ),
        )
        .where(
          'channels',
          'array-contains-any',
          chunk.map((c) => ({
            channelId: c.id,
            groupId: c.ref.parent.parent.id,
          })),
        )
        .onSnapshot(
          (event) => {
            setSchedulesMap((map) => {
              const docsSet = new Set(event.docs.map((d) => d.id));
              const current = map[start.toDate().getTime()]?.filter(
                (a) => !docsSet.has(a.id),
              );

              map[start.toDate().getTime()] = distinct(
                [...(current ?? []), ...event.docs],
                (a) => a.id,
              ).sort(
                (a, b) =>
                  a.data().scheduledOn.toMillis() <
                  b.data().scheduledOn.toMillis(),
              );

              return { ...map };
            });
          },
          (e) => {
            // show(e.message, 'error');
          },
        ),
    );

    return snapshots;
  };

  return (
    <SchedulesContext.Provider
      value={{
        schedulesMap,
        firstDay,
        setFirstDay,
        fetchScheduleById,
        refresh,
        removeScheduleFromMap,
      }}
    >
      <div>{props.children}</div>
    </SchedulesContext.Provider>
  );
}

export default SchedulesContextProvider;
