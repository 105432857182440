import { LoadingButton } from '@mui/lab';
import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { track } from '../utils/analytics';

const ScheduleCard = ({ orderId, link }) => {
  return (
    <Box
      sx={{
        // border: '1px solid #dce4ec',
        borderRadius: 1,
        p: 2,
        pt: 3,
        pb: 3,
        bgcolor: 'primary.main',
        boxShadow: '0px 4px 12px #1976D220',
        width: '100%',
        flexGrow: 1,
      }}
    >
      <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
        <Typography
          sx={{
            fontSize: 32,
            fontWeight: 700,
            textAlign: 'start',
            color: 'white',
          }}
        >
          Schedule videos
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            color: 'white',

            textAlign: 'start',
          }}
        >
          Save time and schedule all the videos automatically
        </Typography>
        <div></div>
        <Divider />
        <div></div>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 400,
            textAlign: 'start',
            color: 'white',

            opacity: 0.7,
            minHeight: 120,
          }}
        >
          Use Socialoop and streamline your social media game. Schedule and
          auto-post our captivating short videos across all your accounts and
          channels. Enjoy hands-free convenience while maintaining a consistent
          online presence. Choose Socialoop for seamless efficiency.
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            color: 'white',

            textAlign: 'start',
          }}
        >
          Signup and get 20 FREE automatic schedules for your videos
        </Typography>
        <div></div>
        <LoadingButton
          variant="contained"
          onClick={() => {
            track('order_page_click_schedule_videos', {
              orderId,
              link,
            });
            window.open(link, '_blank');
          }}
          size="large"
          sx={{
            'height': 56,
            'bgcolor': 'white',
            'boxShadow': `0 4px 12px ${'#FFFFFF'}50`,
            'pl': 2,
            'color': 'primary.main',
            'pr': 2,
            'width': '100%',
            ':hover': {
              bgcolor: 'white',
              color: 'primary.main',
            },
          }}
          startIcon={
            <img
              src={require('../assets/images/iconLogo.png')}
              alt=""
              width={42}
              height={42}
              style={{ marginRight: 8 }}
            />
          }
        >
          Schedule with Socialoop
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default ScheduleCard;
