import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthContext } from '../contexts/Auth';
import { SnackbarContext } from '../contexts/Snackbar';
import { track } from '../utils/analytics';

const Plan = ({
  color,
  title,
  subtitle,
  price,
  productId,
  includes = [],
  features = [],
  lp = false,
}) => {
  const functions = getFunctions();
  const { show } = useContext(SnackbarContext);
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const generatePaymentLink = httpsCallable(functions, 'payment-generateLink');
  const navigate = useNavigate();

  const startTrial = async () => {
    console.log('lp', lp);
    if (lp) {
      if (currentUser) {
        navigate('/dashboard/calendar');
      } else {
        navigate('/signup');
      }
      return;
    }
    setLoading(true);
    generatePaymentLink({ price, productId })
      .then((result) => {
        console.log('result', result);
        if (result.data.results.code === 0) {
          window.location.replace(result.data.data.payment_page_link);
        } else {
          show(result.data.results.description, 'error');
        }
      })
      .catch((e) => {
        console.log('e', e);
        show(JSON.stringify(e), 'error');
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        // border: '1px solid #dce4ec',
        borderRadius: 1,
        p: 2,
        pt: 3,
        pb: 3,
        bgcolor: 'white',
        boxShadow: '0px 4px 12px #1976D220',
        width: '100%',
        flexGrow: 1,
      }}
    >
      <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
        <Typography
          sx={{
            fontSize: 32,
            fontWeight: 700,
            textAlign: 'start',
            color: color,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            textAlign: 'start',
          }}
        >
          {subtitle}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            textAlign: 'start',
          }}
        >
          From
        </Typography>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Typography
            sx={{
              fontSize: 56,
              fontWeight: 700,
              textAlign: 'start',
            }}
          >
            {`$${price}`}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              textAlign: 'start',
              p: 1,
              borderRadius: 1,
              color: color,
              bgcolor: `${color}40`,
            }}
          >
            month
          </Typography>
        </Stack>
        <div></div>
        <Divider />
        <div></div>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 700,
            textAlign: 'start',
            color: 'black',
          }}
        >
          Includes
        </Typography>
        {includes?.map((item, index) => (
          <Stack
            direction={'row'}
            spacing={1}
            key={`features_${item.price}`}
            alignItems="center"
          >
            <Check fontSize="14px" sx={{ color: color }} />
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 400,
                textAlign: 'start',
                opacity: 0.7,
              }}
            >
              {item}
            </Typography>
          </Stack>
        ))}
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 700,
            textAlign: 'start',
            color: 'black',
          }}
        >
          Features
        </Typography>
        {features?.map((item, index) => (
          <Stack
            direction={'row'}
            spacing={1}
            key={`features_${item.price}`}
            alignItems="center"
          >
            <Check fontSize="14px" sx={{ color: color }} />
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 400,
                textAlign: 'start',
                opacity: 0.7,
              }}
            >
              {item}
            </Typography>
          </Stack>
        ))}
        <div></div>
        <div></div>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => {
            track('plans_screen_start_trial_button_click', {
              price,
              productId,
            });

            startTrial(price);
          }}
          size="large"
          sx={{
            height: 56,
            bgcolor: color,
            boxShadow: `0 4px 12px ${color}50`,
            pl: 2,
            pr: 2,
            width: '100%',
          }}
        >
          {price === 39.99 ? 'Coming Soon...' : 'Start plan'}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default Plan;
