import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';

const CreateNewConnectionGroupCard = ({ onAddGroupClick }) => {
  return (
    <Grid item sm={12} md={12} lg={6}>
      <Box
        sx={{
          border: '1px dashed #42A5F5',
          borderRadius: 3,
          p: 4,
          height: 440,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack
          direction={'column'}
          spacing={3}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ width: '100%' }}
        >
          <img
            src={require('../assets/icons/plus.png')}
            height={50}
            width={50}
            alt=""
            style={{ opacity: 0.4 }}
          />
          <Button
            variant="outlined"
            size="large"
            sx={{ height: 40 }}
            fullWidth={false}
            onClick={onAddGroupClick}
          >
            Create new connection group
          </Button>
          <Typography sx={{ color: 'gray.800', fontSize: 12, maxWidth: 290 }}>
            Using connection groups, you can organize your accounts, for
            example, &quot;Public Accounts, Private Accounts, Work, Hobby,
            etc...&quot;
          </Typography>
        </Stack>
      </Box>
    </Grid>
  );
};

export default CreateNewConnectionGroupCard;
