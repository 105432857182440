import mixpanel from 'mixpanel-browser';
import React, { createContext, useEffect, useState } from 'react';
import firebase from '../Firebase';

export const AuthContext = createContext();

function AuthContextProvider(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [appUser, setAppUser] = useState(null);
  const [realAppUser, setRealAppUser] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(undefined);
  const [subscriptionMaybeActive, setSubscriptionMaybeActive] = useState(null);

  useEffect(() => {
    setCurrentUserId(firebase.auth().currentUser?.uid);
  }, [currentUser]);

  useEffect(() => {
    if (currentUserId && currentUserId !== firebase.auth().currentUser.uid) {
      const snapshot = firebase
        .firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.uid)
        .onSnapshot((value) => {
          setRealAppUser(value.data() || {});
        });

      return () => {
        snapshot();
      };
    }
  }, [currentUserId]);

  useEffect(() => {
    if (currentUserId) {
      const snapshot = firebase
        .firestore()
        .collection('users')
        .doc(currentUserId || firebase.auth().currentUser.uid)
        .onSnapshot((value) => {
          setAppUser(value.data() || {});
          if (currentUserId === firebase.auth().currentUser.uid) {
            setRealAppUser(value.data() || {});
          }
        });

      return () => {
        snapshot();
      };
    }
  }, [currentUserId]);

  useEffect(() => {
    if (currentUser && firebase.auth().currentUser.uid && appUser) {
      const snapshot = firebase
        .firestore()
        .collection('users')
        .doc(currentUserId || firebase.auth().currentUser.uid)
        .collection('subscriptions')
        .onSnapshot((value) => {
          const activeSubscriptions = value.docs.filter(
            (s) => s.data().valid === true,
          );
          if (activeSubscriptions.length > 0) {
            setActiveSubscription(activeSubscriptions[0]);
          } else {
            setActiveSubscription(null);
          }
        });

      return () => {
        snapshot();
      };
    }
  }, [appUser]);

  const logout = () => {
    firebase.auth().signOut();
    mixpanel.reset();
  };

  const resendValidationEmail = async () => {
    const res = await firebase.auth().currentUser.sendEmailVerification();
    return res;
  };

  const signInAnonymously = () => {
    firebase
      .auth()
      .signInAnonymously()
      .then((res) => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('error', error);
        // ...
      });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        currentUser: currentUser,
        setCurrentUser: setCurrentUser,
        appUser,
        setIsAuthenticated: setIsAuthenticated,
        logout: logout,
        resendValidationEmail: resendValidationEmail,
        signInAnonymously: signInAnonymously,
        activeSubscription: activeSubscription,
        setSubscriptionMaybeActive: setSubscriptionMaybeActive,
        subscriptionMaybeActive: subscriptionMaybeActive,
        currentUserId,
        setCurrentUserId,
        realAppUser,
        setRealAppUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
