import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import AddNewAccountDialog from '../components/AddNewAccountDialog';
import AddNewGroupDialog from '../components/AddNewGroupDialog';
import { GroupsContext } from '../contexts/Groups';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import firebase from '../Firebase';

import { HelpOutline } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AreYouSureDialog from '../components/AreYouSureDialog';
import ChannelsSelection from '../components/ChannelsSelection';
import ChannelsToggleButton from '../components/ChannelsToggleButton';
import DragAndDropFile from '../components/DragAndDropFile';
import UploadProgressDialog from '../components/UploadProgressDialog';
import UploadScheduleDialog from '../components/UploadScheduleDialog';
import WritingAssistantDialog from '../components/WritingAssistantDialog';
import { SchedulesContext } from '../contexts/Schedules';
import { SnackbarContext } from '../contexts/Snackbar';
import useResponsive from '../hooks/useResponsive';
import { getChannelConfigProperty } from '../utils';
import { track } from '../utils/analytics';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  'border': 'none',
  'width': '100%',
  'boxShadow': 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    boxShadow: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  'backgroundColor': 'white',
  'padding': 0,
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(0),
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  border: 'none',
}));

const LIMITS = {
  youtube: {
    title: 100,
    description: 1000,
    tags: 500,
  },
  facebook: {
    description: 255,
  },
  instagram: {
    description: 150,
  },
  tiktok: {
    description: 80,
  },
};

const Upload = ({ editMode = false }) => {
  const [openAddNewGroupDialog, setOpenAddNewGroupDialog] =
    React.useState(false);
  const [openWritingAssistantDialog, setOpenWritingAssistantDialog] =
    React.useState(false);
  const [openAddNewAccountDialog, setOpenAddNewAccountDialog] =
    React.useState(null);
  const [openUploadProgressDialog, setOpenUploadProgressDialog] =
    React.useState(null);
  const isDesktop = useResponsive('up', 'md');
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
  const [openAreYouSureDialog, setOpenAreYouSureDialog] = useState(null);
  const [scheduleId, setScheduleId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [datetime, setDatetime] = useState(Date.now());

  const { scheduleId: scheduleIdFromUrl } = useParams();
  const [searchParams] = useSearchParams();

  const { schedulesMap, firstDay, fetchScheduleById, removeScheduleFromMap } =
    useContext(SchedulesContext);

  if (!firstDay) return <CircularProgress />;

  const schedules = firstDay && schedulesMap[firstDay.toDate().getTime()];

  useEffect(() => {
    const schedules = Object.values(schedulesMap).flat();
    if (editMode) {
      setScheduleId(scheduleIdFromUrl);
      if (schedules) {
        if (schedules.find((s) => s.id === scheduleIdFromUrl)) {
          setSchedule(schedules.find((s) => s.id === scheduleIdFromUrl));
        } else {
          fetchScheduleById(scheduleIdFromUrl);
        }
      }
    } else {
      setScheduleId(firebase.firestore().collection('schedule').doc().id);
    }
  }, [schedules]);

  useEffect(() => {
    if (editMode) return;

    const scheduleAtFromUrl = searchParams.get('scheduleAt');
    if (scheduleAtFromUrl && (!datetime || datetime <= Date.now())) {
      if (+scheduleAtFromUrl >= Date.now()) {
        setDatetime(+scheduleAtFromUrl);
      }
    }
  }, [searchParams]);

  const navigate = useNavigate();
  const [channels, setChannels] = React.useState([]);
  const [currentChannel, setCurrentChannel] = React.useState('default');
  const { groups, channelsByGroupId, channelById } = useContext(GroupsContext);
  const { show } = useContext(SnackbarContext);
  const [alignment, setAlignment] = React.useState('1');
  const [loading, setLoading] = React.useState(false);
  const [isLoadingDeletion, setIsLoadingDeletion] = React.useState(false);
  const [schedule, setSchedule] = React.useState(null);
  const [config, setConfig] = React.useState({});

  useEffect(() => {
    if (schedule) {
      setConfig({ ...schedule.data().config });
      setChannels([...schedule.data().channels]);
      setDatetime(schedule?.data()?.scheduledOn.seconds * 1000);
    }
  }, [schedule]);

  const handleChange = (_event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [file, setFile] = useState(null);

  const handleFile = (files) => {
    if (files.length === 0) return;
    const f = files[0];
    setFile(f);
  };

  const deleteChannel = (id) => {
    setChannels(channels.filter((channel) => channel.channelId !== id));
  };

  const checkIfAllGroupAccountsAreSelected = (groupId) => {
    let allSelected = true;
    const channelsIds = channels.map((channel) => channel.channelId);
    for (let index = 0; index < channelsByGroupId(groupId).length; index++) {
      const channel = channelsByGroupId(groupId)[index];
      if (!channelsIds.includes(channel.id)) allSelected = false;
    }
    return allSelected;
  };

  const selectOrUnselectAllChannelsByGroupId = (select, groupId) => {
    if (!channelsByGroupId(groupId)) return [];

    let newChannelArray = channels?.filter(
      (channel) => channel.groupId !== groupId,
    );
    if (select) {
      newChannelArray = [
        ...newChannelArray,
        ...channelsByGroupId(groupId).map((c) => ({
          channelId: c.id,
          groupId,
        })),
      ];
    }
    setChannels(newChannelArray);
  };

  const getTotalCharsForTags = () => {
    let count = 0;
    getChannelConfigProperty('tags', config, null, 'youtube')?.forEach(
      (tag) => {
        count += tag.length;
      },
    );
    return count;
  };

  const getErrorDescription = () => {
    if (currentChannel === 'default') {
      if (
        channels.find(
          (channel) =>
            channelById(channel.groupId, channel.channelId)?.data().type ===
            'facebook',
        ) &&
        facebookDescription?.length > LIMITS.facebook.description
      ) {
        return true;
      }
      if (
        channels.find(
          (channel) =>
            channelById(channel.groupId, channel.channelId)?.data().type ===
            'tiktok',
        ) &&
        tiktokDescription?.length > LIMITS.tiktok?.description
      ) {
        return true;
      }
      if (
        channels.find(
          (channel) =>
            channelById(channel.groupId, channel.channelId)?.data().type ===
            'youtube',
        ) &&
        youtubeDescription?.length > LIMITS.youtube?.description
      ) {
        return true;
      }
      if (
        channels.find(
          (channel) =>
            channelById(channel.groupId, channel.channelId)?.data().type ===
            'instagram',
        ) &&
        instagramDescription?.length > LIMITS.instagram?.description
      ) {
        return true;
      }
    }
    if (
      currentChannel === 'facebook' &&
      facebookDescription?.length > LIMITS.facebook?.description
    )
      return true;
    if (
      currentChannel === 'instagram' &&
      instagramDescription?.length > LIMITS.instagram?.description
    )
      return true;
    if (
      currentChannel === 'tiktok' &&
      tiktokDescription?.length > LIMITS.tiktok?.description
    )
      return true;
    if (
      currentChannel === 'youtube' &&
      youtubeDescription?.length > LIMITS.youtube?.description
    )
      return true;
    return false;
  };

  const handleChangeDescription = (e) => {
    const value = e.target.value;
    setConfig((conf) => {
      if (!conf[currentChannel]) conf[currentChannel] = {};
      conf[currentChannel]['description'] = value;
      return { ...conf };
    });
  };

  const handleChangeTitle = (e) => {
    const value = e.target.value;
    setConfig((conf) => {
      if (!conf[currentChannel]) conf[currentChannel] = {};
      conf[currentChannel]['title'] = value;

      return { ...conf };
    });
  };

  const uploadVideo = async () => {
    track('upload_screen_video_upload_started', { scheduleId });

    if (openScheduleDialog === 'schedule') {
      setOpenScheduleDialog(false);
    }
    setProgress(0);
    setOpenUploadProgressDialog(true);
    const storageRef = firebase.storage().ref(`/schedule/${scheduleId}/`);
    const uploadTask = storageRef.child(uuidv4()).put(file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const _progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(_progress);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...

        track('upload_screen_video_upload_completed', { scheduleId });

        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          track('upload_screen_video_upload_completed_with_url', {
            scheduleId,
            downloadURL,
          });

          createPost(downloadURL, datetime);
        });
      },
    );
  };

  const createPost = async (videoUrl, scheduledOn = Date.now()) => {
    const data = {
      createdBy: firebase.auth().currentUser.uid,
      createdAt: firebase.firestore.Timestamp.now(),
      lastUpdatedAt: firebase.firestore.Timestamp.now(),
      scheduledOn: firebase.firestore.Timestamp.fromMillis(
        Math.max(scheduledOn, Date.now() + 10 * 1000),
      ),
      videoUrl,
      thumbnailUrl: null,
      config,
      status: 'pending',
      channels,
    };
    firebase
      .firestore()
      .collection('schedule')
      .doc(scheduleId)
      .set(data)
      .then((_res) => {
        track('upload_screen_post_created', data);

        show('Uploaded successfully', 'success');
        navigate('/dashboard/calendar');
      })
      .catch((error) => {
        show(error.message, 'error');
      })
      .finally(() => {
        setLoading(false);
        setOpenUploadProgressDialog(false);
      });
  };

  const saveChanges = async () => {
    setLoading(true);
    const data = {
      lastUpdatedAt: firebase.firestore.Timestamp.now(),
      scheduledOn: firebase.firestore.Timestamp.fromMillis(datetime.valueOf()),
      config,
      status: 'pending',
      channels,
    };
    firebase
      .firestore()
      .collection('schedule')
      .doc(scheduleId)
      .update(data)
      .then((_res) => {
        show('Saved successfully', 'success');
        navigate('/dashboard/calendar');
      })
      .catch((error) => {
        show(error.message, 'error');
      })
      .finally(() => {
        setLoading(false);
        setOpenUploadProgressDialog(false);
      });
  };

  const validate = (action) => {
    track('upload_post_button_click', { action, config });

    if (
      !getChannelConfigProperty('description', config, null, 'default') ||
      getChannelConfigProperty('description', config, null, 'default') === ''
    ) {
      show('Description cannot be empty', 'error');
      return;
    }
    if (!editMode && file === null) {
      show('File is empty', 'error');
      return;
    }
    if (
      channels.find(
        (channel) =>
          channelById(channel.groupId, channel.channelId)?.data().type ===
          'youtube',
      )
    ) {
      if (
        !getChannelConfigProperty('title', config, null, 'youtube') ||
        getChannelConfigProperty('title', config, null, 'youtube') === ''
      ) {
        show('Title cannot be empty under YouTube', 'error');
        return;
      }
    }
    if (editMode) {
      saveChanges();
      return;
    }
    if (action === 'upload') {
      uploadVideo();
    } else if (action === 'schedule') {
      setOpenScheduleDialog('schedule');
    }
  };

  const onWritingAssistantResult = (result) => {
    setOpenWritingAssistantDialog(false);

    setConfig((current) => {
      const defaultConf = current?.default || {};

      defaultConf.title = result.title;
      defaultConf.description = result.description;

      return { ...current, default: defaultConf };
    });
  };

  const onDeleteScheduleClick = async () => {
    setOpenAreYouSureDialog(true);
  };

  const deleteSchedule = async () => {
    setIsLoadingDeletion(true);

    await firebase
      .firestore()
      .collection('schedule')
      .doc(scheduleId)
      .delete()
      .then(() => {
        removeScheduleFromMap(schedule);
        navigate('/dashboard/calendar');
        show('Deleted succefully', 'info');
      })
      .catch((e) => {
        show(e.message);
      })
      .finally(() => {
        setIsLoadingDeletion(false);
      });
  };

  const facebookDescription =
    getChannelConfigProperty('description', config, null, 'facebook') || '';
  const youtubeDescription =
    getChannelConfigProperty('description', config, null, 'youtube') || '';
  const tiktokDescription =
    getChannelConfigProperty('description', config, null, 'tiktok') || '';
  const instagramDescription =
    getChannelConfigProperty('description', config, null, 'instagram') || '';

  if (editMode && !schedule)
    return (
      <Box sx={{ mt: 2 }}>
        <CircularProgress />
      </Box>
    );

  return (
    <Container maxWidth={false} disableGutters>
      <WritingAssistantDialog
        open={openWritingAssistantDialog}
        setOpen={setOpenWritingAssistantDialog}
        onGenerated={onWritingAssistantResult}
      />
      <AddNewGroupDialog
        open={openAddNewGroupDialog}
        setOpen={setOpenAddNewGroupDialog}
        groupId={openAddNewAccountDialog}
      />
      <AddNewAccountDialog
        open={openAddNewAccountDialog}
        setOpen={setOpenAddNewAccountDialog}
      />
      <UploadProgressDialog
        open={openUploadProgressDialog}
        setOpen={setOpenUploadProgressDialog}
        progress={progress}
      />
      <UploadScheduleDialog
        open={openScheduleDialog}
        setOpen={setOpenScheduleDialog}
        setDatetime={setDatetime}
        datetime={datetime}
        actions={[{ title: 'Schedule', function: uploadVideo }]}
      />
      <AreYouSureDialog
        open={openAreYouSureDialog}
        setOpen={setOpenAreYouSureDialog}
        action={deleteSchedule}
        title={'Are you sure you want to delete this?'}
      />
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        spacing={3}
        sx={{ width: '100%', bgcolor: 'white' }}
      >
        <Stack
          direction={'column'}
          spacing={3}
          sx={{ width: '100%', bgcolor: '#F6FAFD', maxWidth: 522, p: 3 }}
        >
          {/* {!editMode && (
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              sx={{ height: 40 }}
              fullWidth
            >
              <ToggleButton value={'1'}>Upload video</ToggleButton>
              <ToggleButton value={'2'}>Choose from Google Drive</ToggleButton>
            </ToggleButtonGroup>
          )} */}
          {file === null && !schedule ? (
            <DragAndDropFile handleFile={handleFile} />
          ) : (
            <Stack direction={'column'} spacing={2}>
              <video width="100%" style={{ maxHeight: 600 }} controls>
                <source
                  src={
                    schedule
                      ? schedule.data().videoUrl
                      : window.URL.createObjectURL(file)
                  }
                />
              </video>
              {!editMode && (
                <Button variant={'outlined'} onClick={() => setFile(null)}>
                  Change video
                </Button>
              )}
            </Stack>
          )}
        </Stack>
        <Stack
          direction={'column'}
          spacing={2}
          alignItems={'start'}
          sx={{
            width: '100%',
            bgcolor: '#FFFFFF',
            flex: 1,
            minHeight: '100vh',
            p: 3,
          }}
        >
          <Typography sx={{ fontSize: 24, fontWeight: 700 }}>
            {editMode ? 'Edit Post' : 'Create Post'}
          </Typography>
          <div></div>
          <ChannelsSelection
            groups={groups}
            channelsByGroupId={channelsByGroupId}
            channels={channels}
            channelById={channelById}
            checkIfAllGroupAccountsAreSelected={
              checkIfAllGroupAccountsAreSelected
            }
            selectOrUnselectAllChannelsByGroupId={
              selectOrUnselectAllChannelsByGroupId
            }
            setChannels={setChannels}
            deleteChannel={deleteChannel}
          />
          {channels.length > 0 && (
            <>
              <ChannelsToggleButton
                currentChannel={currentChannel}
                setCurrentChannel={setCurrentChannel}
                channels={channels}
                channelById={channelById}
              />
              {currentChannel === 'youtube' && (
                <Stack
                  direction={'column'}
                  spacing={1}
                  alignItems={'start'}
                  sx={{ width: '100%' }}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                    Title
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="What would you like to share?"
                    value={getChannelConfigProperty(
                      'title',
                      config,
                      null,
                      currentChannel,
                    )}
                    onChange={handleChangeTitle}
                  />
                </Stack>
              )}
              <Stack
                direction={'column'}
                spacing={1}
                alignItems={'stretch'}
                sx={{ width: '100%' }}
              >
                <Stack direction={'row'} alignItems={'end'}>
                  <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                    Description
                  </Typography>
                  <Box flex={1}></Box>
                  <Button
                    variant="outlined"
                    onClick={() => setOpenWritingAssistantDialog(true)}
                  >
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: '#1976D2',
                        marginInlineEnd: 1,
                      }}
                    >
                      Writing Assistant
                    </Typography>
                    <HelpOutline />
                  </Button>
                </Stack>
                <TextField
                  multiline={true}
                  minRows={3}
                  fullWidth
                  placeholder="What would you like to share?"
                  onChange={handleChangeDescription}
                  value={getChannelConfigProperty(
                    'description',
                    config,
                    null,
                    currentChannel,
                  )}
                  error={getErrorDescription()}
                />
                <Stack direction="row" spacing={2} sx={{ pl: 1 }}>
                  {((currentChannel === 'default' &&
                    channels.find(
                      (channel) =>
                        channelById(channel.groupId, channel.channelId)?.data()
                          .type === 'facebook',
                    )) ||
                    currentChannel === 'facebook') && (
                    <Stack
                      direction="row"
                      alignItems={'center'}
                      justifyContent={'center'}
                      spacing={1}
                      sx={{ width: 64 }}
                    >
                      <img
                        alt=""
                        src={require('../assets/icons/facebook.png')}
                        width={12}
                        height={12}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color:
                            facebookDescription.length >
                            LIMITS.facebook.description
                              ? 'red'
                              : 'black',
                        }}
                      >{`${facebookDescription.length}/${LIMITS.facebook.description}`}</Typography>
                    </Stack>
                  )}
                  {((currentChannel === 'default' &&
                    channels.find(
                      (channel) =>
                        channelById(channel.groupId, channel.channelId)?.data()
                          .type === 'youtube',
                    )) ||
                    currentChannel === 'youtube') && (
                    <Stack
                      direction="row"
                      alignItems={'center'}
                      justifyContent={'center'}
                      spacing={1}
                      sx={{ width: 64 }}
                    >
                      <img
                        alt=""
                        src={require('../assets/icons/youtubeColored.png')}
                        width={12}
                        height={12}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color:
                            (youtubeDescription?.length ?? 0) >
                            LIMITS.youtube.description
                              ? 'red'
                              : 'black',
                        }}
                      >{`${youtubeDescription?.length}/${LIMITS.youtube.description}`}</Typography>
                    </Stack>
                  )}
                  {((currentChannel === 'default' &&
                    channels.find(
                      (channel) =>
                        channelById(channel.groupId, channel.channelId)?.data()
                          .type === 'instagram',
                    )) ||
                    currentChannel === 'instagram') && (
                    <Stack
                      direction="row"
                      alignItems={'center'}
                      justifyContent={'center'}
                      spacing={1}
                      sx={{ width: 64 }}
                    >
                      <img
                        alt=""
                        src={require('../assets/icons/instagramColored.png')}
                        width={12}
                        height={12}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color:
                            instagramDescription.length >
                            LIMITS.instagram.description
                              ? 'red'
                              : 'black',
                        }}
                      >{`${instagramDescription.length}/${LIMITS.instagram.description}`}</Typography>
                    </Stack>
                  )}
                  {((currentChannel === 'default' &&
                    channels.find(
                      (channel) =>
                        channelById(channel.groupId, channel.channelId)?.data()
                          .type === 'tiktok',
                    )) ||
                    currentChannel === 'tiktok') && (
                    <Stack
                      direction="row"
                      alignItems={'center'}
                      justifyContent={'center'}
                      spacing={1}
                      sx={{ width: 64 }}
                    >
                      <img
                        alt=""
                        src={require('../assets/icons/tiktokColored.png')}
                        width={12}
                        height={12}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color:
                            tiktokDescription?.length >
                            LIMITS.tiktok.description
                              ? 'red'
                              : 'black',
                        }}
                      >{`${tiktokDescription?.length}/${LIMITS.tiktok.description}`}</Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              {currentChannel === 'youtube' && (
                <Stack
                  direction={'column'}
                  spacing={1}
                  alignItems={'start'}
                  sx={{ width: '100%' }}
                >
                  <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                    Tags
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    fullWidth
                    onChange={(_e, v) => {
                      setConfig((conf) => {
                        if (!conf[currentChannel]) conf[currentChannel] = {};
                        conf[currentChannel]['tags'] = v;

                        return { ...conf };
                      });
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        error={getTotalCharsForTags() > 100}
                        fullWidth
                        variant="outlined"
                        helperText={`${getTotalCharsForTags()}/${
                          LIMITS.youtube.tags
                        }`}
                        {...params}
                      />
                    )}
                  />
                </Stack>
              )}
              {(currentChannel === 'tiktok' ||
                currentChannel === 'youtube') && (
                <Accordion elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                      Advanced
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack
                      direction={'column'}
                      spacing={1}
                      alignItems={'start'}
                      sx={{ width: '100%', mt: 2 }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                        Privacy status
                      </Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          getChannelConfigProperty(
                            'visibility',
                            config,
                            null,
                            currentChannel,
                          ) ?? 'public'
                        }
                        onChange={(event) => {
                          setConfig((conf) => {
                            if (!conf[currentChannel])
                              conf[currentChannel] = {};
                            conf[currentChannel]['visibility'] =
                              event.target.value;

                            return { ...conf };
                          });
                        }}
                        sx={{ minWidth: 220, textAlign: 'left' }}
                      >
                        <MenuItem value={'public'}>Public</MenuItem>
                        <MenuItem value={'unlisted'}>Unlisted</MenuItem>
                        <MenuItem value={'private'}>Private</MenuItem>
                      </Select>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )}
              {editMode && (
                <Stack
                  direction={'column'}
                  spacing={1}
                  justifyContent={'start'}
                  alignItems={'start'}
                  sx={{ width: '100%', mt: 2 }}
                >
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 700, textAlign: 'left' }}
                  >
                    Scheduled on
                  </Typography>
                  <DateTimePicker
                    label=""
                    renderInput={(params) => <TextField {...params} />}
                    value={dayjs(datetime)}
                    minDate={dayjs()}
                    onChange={(newValue) => {
                      setDatetime(newValue.toDate().getTime());
                    }}
                    minutesStep={15}
                  />
                </Stack>
              )}
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent={'end'}
                sx={{ width: '100%', mt: 2 }}
              >
                {editMode && (
                  <LoadingButton
                    loading={isLoadingDeletion}
                    color="error"
                    variant={'contained'}
                    onClick={() => onDeleteScheduleClick()}
                  >
                    {'Delete Schedule'}
                  </LoadingButton>
                )}
                {!editMode && (
                  <Button
                    onClick={() => validate('schedule')}
                    variant={'outlined'}
                  >
                    Scheudle for later
                  </Button>
                )}
                {editMode && (
                  <Button onClick={() => navigate(-1)} variant={'outlined'}>
                    Cancel edit
                  </Button>
                )}
                <LoadingButton
                  loading={loading}
                  variant={'contained'}
                  onClick={() => validate('upload')}
                >
                  {editMode ? 'Save changes' : 'Post now'}
                </LoadingButton>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default Upload;
