import { Box, Container } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { GroupsContext } from '../contexts/Groups';
import ClaimRequestDialog from './ClaimRequestDialog';
import DashboardAppBar from './DashboardAppBar';
import PendingClaimRequest from './PendingClaimRequest';
import ScheduleClaimRequestDialog from './ScheduleClaimRequestDialog';

const drawerWidth = 248;

const BACKGROUND = {
  width: '100%',
  height: '100%',
  bgcolor: 'background.default',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const DashboardLayout = () => {
  const { groups, channelsByGroupId } = useContext(GroupsContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAccountSidebar, setShowAccountSidebar] = useState(false);
  const [showClaimRequestDialog, setShowClaimRequestDialog] = useState(false);
  const [showScheduleClaimedDialog, setShowScheduleClaimedDialog] =
    useState(false);

  const location = useLocation();

  useEffect(() => {
    setShowAccountSidebar(location.pathname.includes('calendar'));
  }, [location]);

  useEffect(() => {
    let ttt;
    if (!showScheduleClaimedDialog && searchParams.get('claimRequestId')) {
      ttt = setTimeout(() => {
        if (!showScheduleClaimedDialog && searchParams.get('claimRequestId')) {
          setShowClaimRequestDialog(true);
        }
      }, 1000);
    }
    if (
      !showScheduleClaimedDialog &&
      !searchParams.get('claimRequestId') &&
      searchParams.get('scheduleClaimRequestId')
    ) {
      setShowScheduleClaimedDialog(true);
    }

    return () => {
      if (ttt) clearTimeout(ttt);
    };
  }, [searchParams]);

  return (
    <Container sx={BACKGROUND} maxWidth={false} disableGutters>
      {/* <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 9999,
          width: '100%',
          height: 50,
          bgcolor: 'red',
        }}
      >
        asdsa
      </Box> */}
      {/* <TawkMessengerReactUmd
        propertyId="640629154247f20fefe450fd"
        widgetId="1gqs10v7o"
      /> */}
      <ClaimRequestDialog
        open={showClaimRequestDialog}
        setOpen={setShowClaimRequestDialog}
        requestId={searchParams.get('claimRequestId')}
      />
      <ScheduleClaimRequestDialog
        open={showScheduleClaimedDialog}
        setOpen={setShowScheduleClaimedDialog}
        requestId={searchParams.get('scheduleClaimRequestId')}
      />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <DashboardAppBar withLogo={!showAccountSidebar} withBanner={false} />
        {/* {showAccountSidebar && (
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              ['& .MuiDrawer-paper']: {
                width: drawerWidth,
                boxSizing: 'border-box',
                border: 0,
              },
            }}
          >
            <Toolbar sx={{ mb: 8 }}>
              <img
                src={require('../assets/images/blueLogo.png')}
                height={40}
                alt=""
                style={{ cursor: 'pointer' }}
              />
            </Toolbar>
            <Box sx={{ overflow: 'auto' }}>
              {groups &&
                groups.map((group) => (
                  <List key={group.id}>
                    <ListItem
                      sx={{
                        bgcolor: '#F6FAFD',
                        borderLeft: '3px solid #1976D2',
                      }}
                    >
                      <ListItemText>{group.data().name}</ListItemText>
                    </ListItem>
                    {channelsByGroupId(group.id)?.length === 0 && (
                      <Typography
                        sx={{ fontSize: 14, textAlign: 'left', ml: 2.5, mt: 1 }}
                      >
                        No accounts
                      </Typography>
                    )}
                    {channelsByGroupId(group.id)?.map((account, index) => (
                      <AccountListItem key={index} account={account} />
                    ))}
                  </List>
                ))}
            </Box>
            <Box sx={{ pl: 1, pr: 1 }}>
              <DontHaveAccounts hideText={groups.length !== 0} />
            </Box>
          </Drawer>
        )} */}
        <Box component="main" sx={{ flexGrow: 1, width: '100%' }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
      <PendingClaimRequest />
    </Container>
  );
};

export default DashboardLayout;
