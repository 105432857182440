import { Container, Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import AddAccountFailedDialog from '../components/AddAccountFailedDialog';
import AddNewAccountDialog from '../components/AddNewAccountDialog';
import AddNewGroupDialog from '../components/AddNewGroupDialog';
import CreateNewConnectionGroupCard from '../components/CreateNewConnectionGroupCard';
import GroupCard from '../components/GroupCard';
import ManageActiveAccountsDialog from '../components/ManageActiveAccountsDialog';
import { GroupsContext } from '../contexts/Groups';
import useResponsive from '../hooks/useResponsive';
import { track } from '../utils/analytics';

const ManageAccounts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isDesktop = useResponsive('up', 'md');
  const [openAddNewGroupDialog, setOpenAddNewGroupDialog] =
    React.useState(false);
  const [openAddNewAccountDialog, setOpenAddNewAccountDialog] =
    React.useState(null);
  const [openManageActiveAccountsDialog, setOpenManageActiveAccountsDialog] =
    React.useState(null);
  const [openAddAccountFailedDialog, setOpenAddAccountFailedDialog] =
    React.useState(null);
  const [currentlyAdding, setCurrentlyAdding] = React.useState(null);
  const { groups } = useContext(GroupsContext);

  useEffect(() => {
    if (searchParams.has('addAccountFailed')) {
      setOpenAddAccountFailedDialog(true);
    }
  }, [searchParams]);

  return (
    <Container maxWidth={'xl'} sx={{ pt: 5, width: '100%', pb: 5 }}>
      <AddNewGroupDialog
        open={openAddNewGroupDialog}
        setOpen={setOpenAddNewGroupDialog}
        groupId={openAddNewGroupDialog}
      />
      <ManageActiveAccountsDialog
        open={openManageActiveAccountsDialog}
        setOpen={setOpenManageActiveAccountsDialog}
      />
      <AddNewAccountDialog
        open={openAddNewAccountDialog}
        setOpen={setOpenAddNewAccountDialog}
        groupId={openAddNewAccountDialog}
        setCurrentlyAdding={setCurrentlyAdding}
      />
      <AddAccountFailedDialog
        open={openAddAccountFailedDialog}
        currentlyAdding={currentlyAdding}
        setOpen={(open) => {
          setSearchParams((prev) => {
            prev.delete('addAccountFailed');
            return prev;
          });
          setOpenAddAccountFailedDialog(open);
        }}
      />
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={3}
        sx={{ width: '100%' }}
      >
        <Typography sx={{ fontSize: isDesktop ? 32 : 24, fontWeight: 700 }}>
          Manage Accounts
        </Typography>
        {/* <Button
          variant="contained"
          size="large"
          sx={{ height: 40 }}
          onClick={() => {
            track('manage_accounts_add_new_connection_button_click');
            setOpenAddNewGroupDialog(true);
          }}
        >
          Add new connection
        </Button> */}
      </Stack>
      <Grid container spacing={isDesktop ? 5 : 3} sx={{ mt: 2 }}>
        {groups.map((group) => (
          <GroupCard
            key={group.id}
            group={group}
            onAddAccountClick={() => {
              track('manage_accounts_add_new_account_button_click', {
                groupId: group.id,
                groupName: group.name,
              });
              setOpenAddNewAccountDialog(group.id);
            }}
            onManageActiveAccountsClick={() => {
              track('manage_accounts_manage_active_accounts_button_click');
              setOpenManageActiveAccountsDialog(true);
            }}
          />
        ))}
        <CreateNewConnectionGroupCard
          onAddGroupClick={() => {
            track('manage_accounts_add_new_connection_group_button_click');

            setOpenAddNewGroupDialog(true);
          }}
        />
      </Grid>
    </Container>
  );
};

export default ManageAccounts;
