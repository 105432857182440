import AdbIcon from '@mui/icons-material/Adb';
import { Button, Hidden, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AuthContext } from '../contexts/Auth';
import { SubscriptionContext } from '../contexts/Subscription';
import useResponsive from '../hooks/useResponsive';
import { track } from '../utils/analytics';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Account', 'Logout'];
const ADMINS = [
  'avichay@webullar.com',
  'avichay.official@gmail.com',
  'amit20897@gmail.com',
  'galzaid@gmail.com',
  'galspacedesign@gmail.com',
];

function DashboardAppBar({ withoutActions = false, withBanner = false }) {
  const { currentUser, logout, appUser, subscriptionMaybeActive } =
    React.useContext(AuthContext);
  const { leftFreeSchedules } = React.useContext(SubscriptionContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [value, setValue] = React.useState('calendar');

  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.includes('calendar')) {
      setValue('calendar');
    } else if (location.pathname.includes('admin')) {
      setValue('admin');
    } else {
      setValue('calendar');
    }
  }, [location, value]);

  const handleChange = (newValue) => {
    track(`app_bar_tabs_tab_select_${newValue}`, { newValue, value });

    setValue(newValue);
    if (newValue === 'calendar') {
      navigate('/dashboard/calendar');
    } else if (newValue === 'dashboard') {
      navigate('/dashboard/calendar');
    } else if (newValue === 'admin') {
      navigate('/dashboard/admin');
    }
  };

  const isDesktop = useResponsive('up', 'md');

  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    track('app_bar_user_menu_button_click');

    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onMenuSelect = (action) => {
    if (action === 'Logout') {
      track('app_bar_logout_button_click');

      logout();
    } else if (action === 'Account') {
      track('app_bar_account_button_click');

      navigate('/dashboard/account');
    }
    handleCloseUserMenu();
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: 'white',
          zIndex: (theme) => theme.zIndex.drawer + 0,
          top: withBanner ? 50 : 0,
        }}
        elevation={0}
      >
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <img
              // eslint-disable-next-line no-undef
              src={require('../assets/images/blueLogo.png')}
              height={40}
              alt=""
              style={{ cursor: 'pointer' }}
              onClick={() => {
                track('app_bar_main_logo_button_click');
                navigate('/dashboard/calendar');
              }}
            />
            {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography sx={{ color: 'black' }} textAlign="center">
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              LOGO
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
              }}
            >
              {!withoutActions && (
                <Tabs
                  value={value}
                  onChange={(_, newValue) => handleChange(newValue)}
                  centered
                >
                  {/* <Tab
                    value={'dashboard'}
                    label="Dashboard"
                    sx={{ height: 64 }}
                  /> */}
                  <Tab
                    onClick={() => {
                      if (value === 'calendar') handleChange('calendar');
                    }}
                    value={'calendar'}
                    label="Calendar"
                    sx={{ height: 64 }}
                  />
                  {ADMINS.includes(currentUser.email) && (
                    <Tab value={'admin'} label="Admin" sx={{ height: 64 }} />
                  )}
                </Tabs>
              )}
            </Box>

            <Hidden smDown>
              <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{ flexGrow: 0 }}
              >
                {!withoutActions &&
                  appUser?.subscriptionStatus !== 'active' &&
                  !subscriptionMaybeActive && (
                    <Box
                      sx={{
                        flexGrow: 0,
                        marginInlineEnd: '16px',
                        color: 'black',
                      }}
                    >
                      {leftFreeSchedules > 0 ? (
                        <Typography>{leftFreeSchedules} posts left</Typography>
                      ) : (
                        <Typography sx={{ color: 'red' }}>
                          No more posts left
                        </Typography>
                      )}
                    </Box>
                  )}
                {!withoutActions &&
                  appUser?.subscriptionStatus !== 'active' &&
                  !subscriptionMaybeActive && (
                    <Button
                      variant="contained"
                      sx={{ bgcolor: '#089d6f', mr: 3 }}
                      onClick={() => {
                        track('app_bar_start_free_trial_button_click', {
                          location: window.location.href,
                        });

                        navigate('/plans');
                      }}
                    >
                      Get unlimited posts
                    </Button>
                  )}
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {currentUser?.photoURL ? (
                      <Avatar
                        alt={currentUser?.displayName}
                        src={currentUser?.photoURL}
                      />
                    ) : (
                      <Avatar alt={currentUser?.displayName}>
                        {currentUser?.displayName &&
                          currentUser?.displayName.length > 0 &&
                          currentUser?.displayName[0]}
                      </Avatar>
                    )}
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => onMenuSelect(setting)}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>
            </Hidden>
            <Hidden mdUp>
              <Box sx={{ flexGrow: 0 }}>
                {/* {!withoutActions &&
                  appUser?.subscriptionStatus !== 'active' &&
                  !subscriptionMaybeActive && (
                    <Button
                      variant="contained"
                      sx={{ bgcolor: '#089d6f', mr: 3 }}
                      onClick={() => {
                        track('app_bar_start_free_trial_button_click', {
                          location: window.location.href,
                        });

                        navigate('/plans');
                      }}
                    >
                      Start 14-Day free trial
                    </Button>
                  )} */}
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {currentUser?.photoURL ? (
                      <Avatar
                        alt={currentUser?.displayName}
                        src={currentUser?.photoURL}
                      />
                    ) : (
                      <Avatar alt={currentUser?.displayName}>
                        {currentUser?.displayName &&
                          currentUser?.displayName.length > 0 &&
                          currentUser?.displayName[0]}
                      </Avatar>
                    )}
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => onMenuSelect(setting)}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default DashboardAppBar;
