import firebase from '../Firebase';

const db = firebase.firestore();

export const createGroupRef = async () => {
  const groupRef = db.collection('groups').doc();
  return groupRef;
};

export const addGroup = async (name) => {
  const currentUserId = firebase.auth().currentUser.uid;

  const groupDoc = await db.collection('groups').add({
    name,
    createdAt: firebase.firestore.Timestamp.now(),
    createdBy: currentUserId,
  });
  await groupDoc.collection('team').doc(currentUserId).set({
    createdAt: firebase.firestore.Timestamp.now(),
    createdBy: currentUserId,
    status: 'approved',
    role: 'owner',
    userId: currentUserId,
  });
  return groupDoc;
};

export const renameGroup = async (groupId, name) => {
  const snapshot = await db.collection('groups').doc(groupId).update({ name });
  return snapshot;
};

export const deleteGroup = async (groupId) => {
  const channels = await db
    .collection('groups')
    .doc(groupId)
    .collection('channels')
    .get();
  const members = await db
    .collection('groups')
    .doc(groupId)
    .collection('team')
    .get();

  const batch = firebase.firestore().batch();

  channels.docs.forEach((c) => batch.delete(c.ref));
  members.docs.forEach((m) => batch.delete(m.ref));
  batch.delete(db.collection('groups').doc(groupId));

  await batch.commit();

  return { ok: true };
};
