import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import OnboardingLayout from '../components/OnboardingLayout';
import { AuthContext } from '../contexts/Auth';
import firebase from '../Firebase';
import { track } from '../utils/analytics';
import { emailIsValid, passwordIsValid } from '../utils/validator';

const HAVE_ACCOUNT = {
  marginTop: 24,
  marginBottom: 8,
};

const FORM = {
  width: '100%',
};

const TERMS = {
  marginTop: 16,
  paddingLeft: 32,
  paddingRight: 32,
  fontSize: 14,
};

const ERROR_MESSAGE = {
  color: 'red',
  fontSize: 14,
  textAlign: 'start',
};

const Login = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const checkValidation = () => {
    let isValid = true;
    if (!emailIsValid(email)) {
      setErrorEmail('Email is not valid');
      isValid = false;
    }
    if (!passwordIsValid(password)) {
      setErrorPassword('Password is not valid');
      isValid = false;
    }
    return isValid;
  };

  const onLogin = async (provider) => {
    setError(null);
    if (provider === 'facebook') {
      track('login_screen_login_with_facebook_button_click');
      loginWithFacebook();
    } else if (provider === 'google') {
      track('login_screen_login_with_google_button_click');
      loginWithGoogle();
    } else {
      track('login_screen_login_with_email_password_button_click');
      if (checkValidation()) {
        loginWithEmailPaswword();
      }
    }
  };

  const loginWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().useDeviceLanguage();
    firebase.auth().signInWithPopup(provider);
  };

  const loginWithGoogle = async () => {
    track('login_with_google_button_click');

    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();
    firebase.auth().signInWithPopup(provider);
  };

  const loginWithEmailPaswword = async () => {
    track('login_with_email_button_click', { email });

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // var user = userCredential.user;
        setLoading(false);
      })
      .catch((error) => {
        track('login_with_email_failed', { error: error.message });

        setLoading(false);
        setError(error.message);
      });
  };

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    track('login_screen_view');
  }, []);

  return (
    <OnboardingLayout>
      <Stack direction={'column'} spacing={2} sx={FORM}>
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={1}
        >
          <img src={require('../assets/icons/profile.png')} width={32} alt="" />
        </Stack>
        <div></div>
        <Typography sx={{ fontSize: 32, fontWeight: 600, color: 'gray.800' }}>
          Welcome Back
        </Typography>
        <div></div>
        <LoadingButton
          onClick={() => onLogin('google')}
          startIcon={
            <img
              alt="logo"
              src={require('../assets/icons/google.png')}
              height={24}
              width={24}
              style={{ marginInlineEnd: 8 }}
            />
          }
          variant="white"
          size="large"
        >
          Continue with Google
        </LoadingButton>
        <div></div>

        <Stack
          direction={'row'}
          sx={{ width: '100%' }}
          alignItems={'center'}
          spacing={4}
        >
          <div
            style={{
              height: 1,
              width: 'auto',
              flex: 1,
              backgroundColor: '#E0E0E0',
            }}
          ></div>
          <Typography sx={{ fontSize: 16, color: 'gray.700' }}>Or</Typography>
          <div
            style={{
              height: 1,
              width: 'auto',
              flex: 1,
              backgroundColor: '#E0E0E0',
            }}
          ></div>
        </Stack>
        <div></div>
        <TextField
          label="Email"
          fullWidth
          type={'email'}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorEmail(null);
          }}
          error={errorEmail !== null}
          helperText={errorEmail}
        />
        <TextField
          label="Password"
          fullWidth
          type={'password'}
          onChange={(e) => {
            setPassword(e.target.value);
            setErrorPassword(null);
          }}
          error={errorPassword !== null}
          helperText={errorPassword}
        />
        <Stack
          direction={'row'}
          justifyContent={'end'}
          alignItems={'center'}
          spacing={1}
        >
          <Button
            onClick={() => {
              track('login_screen_reset_password_button_click');
              navigate('/reset-password');
            }}
            size="small"
          >
            Forgot passowrd?
          </Button>
        </Stack>
        <LoadingButton
          loading={loading}
          onClick={() => onLogin('email')}
          variant="contained"
          size="large"
        >
          Login
        </LoadingButton>
        {error && <Typography sx={ERROR_MESSAGE}>{error}</Typography>}
        <Stack
          style={HAVE_ACCOUNT}
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={1}
        >
          <Typography sx={{ fontSize: 16 }}>
            Don&apos;t have an account?
          </Typography>
          <Button
            onClick={() => {
              track('login_screen_switch_to_signup_button_click');

              navigate('/signup');
            }}
            size="medium"
          >
            Signup
          </Button>
        </Stack>
        <p style={TERMS}>
          By signing in I accept the{' '}
          <a target="_blank" href="https://socialoop.io/terms" rel="noreferrer">
            Terms & Conditions
          </a>{' '}
          and the{' '}
          <a
            target="_blank"
            href="https://socialoop.io/privacy"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </Stack>
    </OnboardingLayout>
  );
};

export default Login;
