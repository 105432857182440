import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import * as React from 'react';
import useResponsive from '../hooks/useResponsive';
import {
  facebookOauth,
  instagramOauth,
  requestFacebookOauthToken,
  requestInstagramOauthToken,
  requestTikTokOauthToken,
  requestYouTubeOauthToken,
  tikTokOauth,
  youTubeOauth,
} from '../services/Channels';
import { track } from '../utils/analytics';
import CustomizedDialogs from './Dialog';
import NewConnectionSelection from './NewConnectionSelection';

export default function AddNewAccountDialog({
  open,
  setOpen,
  groupId,
  setCurrentlyAdding,
}) {
  const isDesktop = useResponsive('up', 'md');
  const [loadingChannel, setLoadingChannel] = React.useState();
  const [openBeforeInstagramDialog, setOpenBeforeInstagramDialog] =
    React.useState(false);
  const [openBeforeTikTokDialog, setOpenBeforeTikTokDialog] =
    React.useState(false);

  const onConnect = async (channelType) => {
    track(`add_new_account_dialog_${channelType}_button_click`, {
      groupId,
      channelType,
    });
    setCurrentlyAdding(channelType);
    switch (channelType) {
      case 'youtube':
        setLoadingChannel(channelType);
        requestYouTubeOauthToken(groupId).then((res) => {
          if (res.data.url) {
            window.location.href = res.data.url;
          } else {
            youTubeOauth(res.data.requestId);
          }
        });
        break;

      case 'tiktok':
        setOpenBeforeTikTokDialog(true);
        break;

      case 'facebook':
        setLoadingChannel(channelType);
        requestFacebookOauthToken(groupId).then((res) => {
          if (res.data.url) {
            window.location.href = res.data.url;
          } else {
            facebookOauth(res.data.requestId);
          }
        });
        break;

      case 'instagram':
        setOpenBeforeInstagramDialog(true);
        break;
    }
  };

  const onConnectInstagram = () => {
    track('add_new_instagram_account_explainer_dialog_connect_button_click', {
      groupId,
    });

    setLoadingChannel('instagram');
    requestInstagramOauthToken(groupId).then((res) => {
      if (res.data.url) {
        window.location.href = res.data.url;
      } else {
        instagramOauth(res.data.requestId);
      }
    });
  };

  const onConnectTikTok = () => {
    track('add_new_tiktok_account_explainer_dialog_connect_button_click', {
      groupId,
    });

    setLoadingChannel('tiktok');
    requestTikTokOauthToken(groupId).then((res) => {
      if (res.data.url) {
        window.location.href = res.data.url;
      } else {
        tikTokOauth(res.data.requestId);
      }
    });
  };

  return (
    <>
      <CustomizedDialogs
        title={'Select a connection'}
        open={!!open}
        setOpen={setOpen}
      >
        <Stack direction={isDesktop ? 'row' : 'column'} spacing={3}>
          <NewConnectionSelection
            channel={'youtube'}
            label={'YouTube'}
            isLoading={loadingChannel === 'youtube'}
            disabled={!!loadingChannel}
            onConnect={onConnect}
          />
          <NewConnectionSelection
            channel={'facebook'}
            label={'Facebook Pages'}
            isLoading={loadingChannel === 'facebook'}
            disabled={!!loadingChannel}
            onConnect={onConnect}
          />
        </Stack>
        <Stack
          direction={isDesktop ? 'row' : 'column'}
          spacing={3}
          sx={{ mt: 3 }}
        >
          <NewConnectionSelection
            channel={'tiktok'}
            label={'TikTok Inbox'}
            isLoading={loadingChannel === 'tiktok'}
            disabled={!!loadingChannel}
            onConnect={onConnect}
            // comingSoon={true}
          />
          <NewConnectionSelection
            channel={'instagram'}
            label={'Instagram Business'}
            isLoading={loadingChannel === 'instagram'}
            disabled={!!loadingChannel}
            onConnect={onConnect}
          />
        </Stack>
      </CustomizedDialogs>
      <CustomizedDialogs
        title={
          'To connect an Instagram account, you need to authenticate with Facebook'
        }
        open={!!openBeforeInstagramDialog}
        setOpen={setOpenBeforeInstagramDialog}
      >
        <Stack direction={'column'} spacing={3}>
          <Typography sx={{ fontWeight: 700 }}>
            Wait...why do I have to authenticate with Facebook?
          </Typography>
          <Typography>
            Facebook requires Instagram business accounts to be connected to a
            Facebook Page. For Socialoop to work as intended, we need to
            authenticate through Facebook.
          </Typography>
          <Typography sx={{ fontWeight: 700 }}>What happens next?</Typography>
          <Typography>
            Once we transfer you, sign in to the Facebook profile that has Admin
            permissions for the Facebook Page connected with your Instagram
            account.
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label={
                <p style={{}}>
                  Is your instagram account an account of type &quot;Instagram
                  Business account&quot;?{' '}
                  <a
                    target="_blank"
                    href="https://socialoop.io/blog?p=converting-your-instagram-account-to-a-business-account"
                    rel="noreferrer"
                  >
                    See how
                  </a>
                </p>
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={
                <p style={{}}>
                  Do you have a facebook page connected to your instagram
                  account?{' '}
                  <a
                    target="_blank"
                    href="https://socialoop.io/blog?p=converting-your-instagram-account-to-a-business-account"
                    rel="noreferrer"
                  >
                    See how
                  </a>
                </p>
              }
            />
          </FormGroup>
          <p style={{}}>
            Check out{' '}
            <a
              target="_blank"
              href="https://socialoop.io/blog?p=converting-your-instagram-account-to-a-business-account"
              rel="noreferrer"
            >
              Instagram connection guide
            </a>{' '}
            for more
          </p>
          <LoadingButton
            sx={{ width: '100%' }}
            variant={'contained'}
            size="large"
            loading={loadingChannel === 'instagram'}
            onClick={() => onConnectInstagram()}
          >
            Authenticate with Facebook
          </LoadingButton>
          <Button
            sx={{ width: '100%' }}
            variant={'outlined'}
            size="large"
            onClick={() => setOpenBeforeInstagramDialog(false)}
          >
            Cancel
          </Button>
        </Stack>
      </CustomizedDialogs>
      <CustomizedDialogs
        title={'TikTok Inbox account explainer'}
        open={!!openBeforeTikTokDialog}
        setOpen={setOpenBeforeTikTokDialog}
      >
        <Stack direction={'column'} spacing={3}>
          <Typography>
            Using this account type we will upload the video to the TikTok
            account but it won&apos;t be automatically published, the video will
            go to your TikTok inbox and then you&apos;ll need to publish it with
            just 1 click.
          </Typography>
          <LoadingButton
            sx={{ width: '100%' }}
            variant={'contained'}
            size="large"
            loading={loadingChannel === 'tiktok'}
            onClick={() => onConnectTikTok()}
          >
            Connect
          </LoadingButton>
          <Button
            sx={{ width: '100%' }}
            variant={'outlined'}
            size="large"
            onClick={() => setOpenBeforeTikTokDialog(false)}
          >
            Cancel
          </Button>
        </Stack>
      </CustomizedDialogs>
    </>
  );
}
