import {
  Avatar,
  Box,
  Checkbox,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as React from 'react';
import { GroupsContext } from '../contexts/Groups';
import { SnackbarContext } from '../contexts/Snackbar';
import { SubscriptionContext } from '../contexts/Subscription';
import { distinct } from '../utils';
import ChannelIcon from './ChannelIcon';
import CustomizedDialogs from './Dialog';

const AccounItem = ({ channel, checked, disabled, onChange }) => {
  const getPlatformName = (platform) => {
    switch (platform) {
      case 'youtube':
        return 'YouTube';
      case 'instagram':
        return 'Instagram';
      case 'facebook':
        return 'Facebook';
      case 'tiktok':
        return 'TikTok';
      default:
        return 'Unknown';
    }
  };

  return (
    <Box
      disablePadding
      sx={{
        bgcolor: '#F6FAFD',
        borderRadius: 1.5,
        border: '1px solid #E3F2FD',
        p: 2,
        width: '100%',
      }}
    >
      <Stack direction="row" spacing={2} alignItems={'center'}>
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={(value) => onChange(value.target.value)}
        />
        <ListItemIcon sx={{ position: 'relative' }}>
          <Avatar src={channel.data().thumbnailUrl} />
          <img
            src={require(`../assets/icons/${channel.data().type}Colored.png`)}
            height={22}
            style={{ position: 'absolute', bottom: -4, right: 12 }}
          />
        </ListItemIcon>
        <Stack sx={{ width: '100%', flexGrow: 1 }}>
          <Typography
            sx={{ textAlign: 'start', fontSize: 16, fontWeight: 700 }}
          >
            {channel.data().channelName}
          </Typography>
          <Typography
            sx={{ textAlign: 'start', fontSize: 14, fontWeight: 400 }}
          >
            {`${getPlatformName(channel.data().type)} Account`}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

const channelTypes = ['youtube', 'tiktok', 'facebook', 'instagram'];

export default function ManageActiveAccountsDialog({ open, setOpen }) {
  const { allChannels } = React.useContext(GroupsContext);
  const { show } = React.useContext(SnackbarContext);

  const { subscriptionAllowedAccountSets } =
    React.useContext(SubscriptionContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const [activeAccountsById, setActiveAccountsById] = React.useState({});

  const getActiveChannelsMap = (channels) => {
    return Object.fromEntries(
      channels
        .filter((c) => c.data().mode === 'active')
        .map((c) => [c.id, true]),
    );
  };

  const changed = React.useMemo(() => {
    const old = getActiveChannelsMap(allChannels());
    const oldActiveIdsSet = new Set(
      Object.entries(old)
        .filter(([id, active]) => active)
        .map(([id]) => id),
    );
    const currentActiveIds = Object.entries(activeAccountsById)
      .filter(([id, active]) => active)
      .map(([id]) => id);

    if (currentActiveIds.length !== oldActiveIdsSet.size) return true;

    return !currentActiveIds.every((id) => oldActiveIdsSet.has(id));
  }, [activeAccountsById, allChannels]);

  React.useEffect(() => {
    if (!open) return;
    setActiveAccountsById(getActiveChannelsMap(allChannels()));
  }, [open]);

  const activeCountByType = React.useMemo(
    () =>
      Object.fromEntries(
        channelTypes.map((type) => [
          type,
          distinct(allChannels(), (c) => c.id).filter(
            (c) => c.data().type === type && activeAccountsById[c.id],
          ).length,
        ]),
      ),
    [allChannels, activeAccountsById],
  );

  const channels = distinct(allChannels(), (c) => c.id);

  const onAccountValueChange = (channel) => {
    setActiveAccountsById((current) => {
      current[channel.id] = !current[channel.id];
      return { ...current };
    });
  };

  const onUpdateButtonClick = () => {
    setIsLoading(true);
    const functions = getFunctions(getApp());

    const setActiveChannels = httpsCallable(
      functions,
      'channels-setActiveChannels',
    );

    const currentActiveIds = Object.entries(activeAccountsById)
      .filter(([id, active]) => active)
      .map(([id]) => id);
    setActiveChannels({ activeChannels: currentActiveIds })
      .then((result) => {
        setOpen(false);
      })
      .catch((e) => {
        show(`Failed to update active accounts, ${e.toString()}`, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ActiveChannels = ({
    activeCountByType,
    subscriptionAllowedAccountSets,
  }) => {
    return (
      <Stack direction={'row'} gap={2}>
        {channelTypes.map((type) => (
          <Stack key={type} direction={'row'}>
            <ChannelIcon channelType={type} />
            <Typography sx={{ marginInlineStart: 1 }}>
              {activeCountByType[type] ?? 0}/{subscriptionAllowedAccountSets}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  };

  return (
    <CustomizedDialogs
      title={'Select enabled accounts'}
      open={!!open}
      setOpen={setOpen}
      actionsPrefix={
        <ActiveChannels
          activeCountByType={activeCountByType}
          subscriptionAllowedAccountSets={subscriptionAllowedAccountSets}
        />
      }
      actions={[
        // {
        //   title: 'Upgrade',
        //   variant: 'outlined',
        // },
        {
          type: 'loader',
          disabled: !isLoading,
        },
        {
          title: 'Update',
          variant: 'contained',
          disabled: !changed || isLoading,
          function: onUpdateButtonClick,
        },
      ]}
    >
      <Stack direction={'column'} spacing={1}>
        {channels.map((channel) => (
          <AccounItem
            key={channel.id}
            channel={channel}
            checked={activeAccountsById[channel.id] ?? false}
            disabled={
              !activeAccountsById[channel.id] &&
              (activeCountByType[channel.data().type] ?? 0) >=
                subscriptionAllowedAccountSets
            }
            onChange={() => onAccountValueChange(channel)}
          />
        ))}
      </Stack>
    </CustomizedDialogs>
  );
}
