import { Button, Stack, Typography } from '@mui/material';
import * as React from 'react';

export default function DragAndDropFile({ handleFile }) {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      id="form-file-upload"
      style={{ width: '100%' }}
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? 'drag-active' : ''}
        style={{ padding: 64 }}
      >
        <Stack
          direction={'column'}
          spacing={2}
          sx={{ p: 1 }}
          alignItems={'center'}
        >
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
            sx={{ width: '100%' }}
          >
            <img
              src={require('../assets/icons/upload.png')}
              width={50}
              style={{ opacity: 0.6 }}
              alt=""
            />
          </Stack>
          <Typography sx={{ fontSize: 24, fontWeight: 600, color: 'gray.800' }}>
            Choose a video to upload
          </Typography>
          <div></div>
          <Typography sx={{ fontSize: 18, fontWeight: 400, color: '#616161' }}>
            or drag & drop
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, color: '#616161' }}>
            MP4 or WebM
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, color: '#616161' }}>
            Resolution of at least 720 by 1280
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, color: '#616161' }}>
            Up to 30 mins
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, color: '#616161' }}>
            Less than 2 GB
          </Typography>
          <div></div>
          <Button
            variant="outlined"
            sx={{ width: 124 }}
            onClick={onButtonClick}
          >
            Choose file
          </Button>
        </Stack>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}
