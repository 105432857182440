import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import firebase from '../Firebase';

import { Close } from '@mui/icons-material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as moment from 'moment-timezone';
import { useNavigate } from 'react-router';
import ChannelsSelection from '../components/ChannelsSelection';
import { GroupsContext } from '../contexts/Groups';
import { SnackbarContext } from '../contexts/Snackbar';
import { groupBy, insertBetweenItems } from '../utils';
import { track } from '../utils/analytics';
import AntSwitch from './AntSwitch';
import ChannelIcon from './ChannelIcon';
import CustomizedDialogs from './Dialog';

const allowedGridItem = (allowed) => {
  return allowed ? {} : { backgroundColor: '#E0E0E0' };
};

const ScheduleClaimRequestDialog = ({ open, setOpen, requestId }) => {
  const { show } = useContext(SnackbarContext);
  const { groups, channelsByGroupId, channelById } = useContext(GroupsContext);

  const navigate = useNavigate();

  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const [request, setRequest] = useState(null);
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [timeType, setTimeType] = useState('everyDay');
  const [hour, setHour] = useState(10);
  const [minutes, setMinutes] = useState(0);
  const [ampm, setAmpm] = useState('am');
  const [channels, setChannels] = React.useState([]);
  const [postingTimes, setPostingTimes] = useState([]);
  const [allowedDays, setAllowedDays] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
  });

  const timeTypes = [
    { id: 'everyDay', label: 'Every Day' },
    { id: 'monday', label: 'Monday' },
    { id: 'tuesday', label: 'Tuesday' },
    { id: 'wednesday', label: 'Wednesday' },
    { id: 'thursday', label: 'Thursday' },
    { id: 'friday', label: 'Friday' },
    { id: 'saturday', label: 'Saturday' },
    { id: 'sunday', label: 'Sunday' },
  ];

  const days = [
    { id: 'monday', label: 'Monday', num: 1 },
    { id: 'tuesday', label: 'Tuesday', num: 2 },
    { id: 'wednesday', label: 'Wednesday', num: 3 },
    { id: 'thursday', label: 'Thursday', num: 4 },
    { id: 'friday', label: 'Friday', num: 5 },
    { id: 'saturday', label: 'Saturday', num: 6 },
    { id: 'sunday', label: 'Sunday', num: 0 },
  ];

  const ampnOptions = ['am', 'pm'];

  useEffect(() => {
    if (!requestId) return;

    const snapshot = firebase
      .firestore()
      .collection('claimRequests')
      .doc(requestId)
      .onSnapshot((doc) => {
        setRequest(doc.exists ? doc : null);
      });

    return () => {
      snapshot();
    };
  }, [requestId]);

  const onAddPostingTimeButtonClick = () => {
    track('schedule_claim_request_dialog_add_posting_time_button_click', {
      channelsCount: channels.length,
      timeType,
    });

    if (!channels.length) {
      show('Select at least one channel');
      return;
    }

    let daysValues;

    switch (timeType) {
      case 'everyDay':
        daysValues = Object.fromEntries(days.map((d) => [d.num, true]));
        break;
      default:
        const day = days.find((d) => d.id === timeType);
        if (day) {
          daysValues = { [day.num]: true };
        }
    }

    if (!daysValues) return;

    setPostingTimes((current) => {
      current.push({
        id: Date.now(),
        channels,
        hour: hour + (ampm === 'pm' ? 12 : 0),
        minutes,
        timezone,
        timezoneOffset: moment().tz(timezone).utcOffset(),
        days: daysValues,
      });

      return [
        ...current.sort(
          (a, b) =>
            a.hour * 1000 - b.hour * 1000 + (a.minutes * 10 - b.minutes * 10),
        ),
      ];
    });
  };

  const onClearAllPostingTimes = () => {
    track('schedule_claim_request_dialog_clear_all_button_click');

    setPostingTimes([]);
  };

  const onDeleteDayClick = (postingTime, day) => {
    setPostingTimes((current) => {
      const single = current.find((a) => a.id === postingTime.id);
      if (!single) return current;

      single.days[day.num] = false;

      if (Object.values(single.days).every((a) => !a)) {
        return [...current.filter((c) => c.id !== single.id)];
      }

      return [...current];
    });
  };

  const deleteChannel = (id) => {
    setChannels(channels.filter((channel) => channel.channelId !== id));
  };

  const checkIfAllGroupAccountsAreSelected = (groupId) => {
    let allSelected = true;
    const channelsIds = channels.map((channel) => channel.channelId);
    for (let index = 0; index < channelsByGroupId(groupId).length; index++) {
      const channel = channelsByGroupId(groupId)[index];
      if (!channelsIds.includes(channel.id)) allSelected = false;
    }
    return allSelected;
  };

  const selectOrUnselectAllChannelsByGroupId = (select, groupId) => {
    if (!channelsByGroupId(groupId)) return [];

    let newChannelArray = channels?.filter(
      (channel) => channel.groupId !== groupId,
    );
    if (select) {
      newChannelArray = [
        ...newChannelArray,
        ...channelsByGroupId(groupId).map((c) => ({
          channelId: c.id,
          groupId,
        })),
      ];
    }
    setChannels(newChannelArray);
  };

  const onScheduleAllButtonClick = () => {
    track('schedule_claim_request_dialog_schedule_button_click', {
      claimRequestId: requestId,
    });

    setIsLoadingAccept(true);

    const functions = getFunctions();

    const scheduleRequestFiles = httpsCallable(
      functions,
      'claim-scheduleRequestFiles',
    );
    scheduleRequestFiles({
      claimRequestId: requestId,
      postingTimes,
      allowedDays,
    })
      .then((result) => {
        track('schedule_claim_request_dialog_schedule_action_success', {
          claimRequestId: requestId,
        });
        show(
          'This might take a couple of minutes, then you will start seeing the videos in the calendar',
        );
        navigate('/dashboard/calendar');
      })
      .catch((e) => {
        track('schedule_claim_request_dialog_schedule_action_failed', {
          claimRequestId: requestId,
        });
        show(e.message);
      })
      .finally(() => {
        setIsLoadingAccept(false);
      });
  };

  return (
    <CustomizedDialogs
      title={'Posting schedule'}
      open={!!open}
      setOpen={setOpen}
      maxWidth={'md'}
      actions={[
        {
          type: 'loader',
          disabled: !isLoadingAccept,
        },
        {
          title: 'Schedule All',
          function: onScheduleAllButtonClick,
          variant: 'contained',
          disabled: isLoadingAccept,
        },
      ]}
    >
      {!request && <CircularProgress />}
      {!!request && (
        <Stack direction={'column'}>
          <Typography sx={{ mb: 3 }}>
            The library has {request.data()?.driveFilesCount ?? 0} videos to
            schedule
          </Typography>
          {false && (
            <>
              <Stack direction={'row'}>
                <Stack direction={'column'} spacing={1}>
                  <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                    Time zone
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ height: '40px' }}
                    value={timezone}
                    onChange={(event) => {
                      setTimezone(event.target.value);
                    }}
                  >
                    {moment.tz.names().map((tz) => (
                      <MenuItem key={tz} value={tz}>
                        {tz}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Stack>
              <Divider sx={{ marginBlock: '16px' }} />
            </>
          )}
          <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
            Select posting times and accounts
          </Typography>
          <Stack
            direction={'row'}
            spacing={1}
            sx={{ mt: 1 }}
            alignItems={'center'}
          >
            <Select
              labelId="time-type"
              id="time-type-select"
              sx={{ height: '40px' }}
              value={timeType}
              onChange={(event) => {
                setTimeType(event.target.value);
              }}
            >
              {timeTypes.map((timeTypeOption) => (
                <MenuItem key={timeTypeOption.id} value={timeTypeOption.id}>
                  {timeTypeOption.label}
                </MenuItem>
              ))}
            </Select>
            <Select
              labelId="hours"
              id="hours-select"
              sx={{ height: '40px' }}
              value={hour}
              onChange={(event) => {
                setHour(event.target.value);
              }}
            >
              {new Array(12).fill(0).map((_, hour) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={`hour: ${hour}`} value={hour}>
                  {hour.toString().padStart(2, '0')}
                </MenuItem>
              ))}
            </Select>
            <Select
              labelId="minutes"
              id="minutes-select"
              sx={{ height: '40px' }}
              value={minutes}
              onChange={(event) => {
                setMinutes(event.target.value);
              }}
            >
              {new Array(4).fill(0).map((_, minute) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={`minute: ${minute}`} value={minute * 15}>
                  {(minute * 15).toString().padStart(2, '0')}
                </MenuItem>
              ))}
            </Select>
            <Select
              labelId="am-pm"
              id="am-pm-select"
              sx={{ height: '40px' }}
              value={ampm}
              onChange={(event) => {
                setAmpm(event.target.value);
              }}
            >
              {ampnOptions.map((value) => (
                <MenuItem key={value} value={value}>
                  {value.toString()}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Box sx={{ mt: 2 }}>
            <ChannelsSelection
              groups={groups}
              channelsByGroupId={channelsByGroupId}
              channels={channels}
              channelById={channelById}
              checkIfAllGroupAccountsAreSelected={
                checkIfAllGroupAccountsAreSelected
              }
              selectOrUnselectAllChannelsByGroupId={
                selectOrUnselectAllChannelsByGroupId
              }
              setChannels={setChannels}
              deleteChannel={deleteChannel}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              size="large"
              sx={{ height: 40 }}
              fullWidth={false}
              onClick={onAddPostingTimeButtonClick}
              disabled={isLoadingAccept}
            >
              Add Posting Time
            </Button>
          </Box>
          <Divider sx={{ marginBlock: '16px' }} />
          <Stack direction={'row'} alignItems={'center'}>
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              Posting times
            </Typography>
            <Box flex={1}></Box>
            <Button
              variant="outlined"
              size="small"
              sx={{ height: 40 }}
              fullWidth={false}
              onClick={onClearAllPostingTimes}
              disabled={isLoadingAccept}
            >
              Clear all Posting Times
            </Button>
          </Stack>
          <Stack
            direction={'column'}
            sx={{
              borderColor: '#E0E0E0',
              borderWidth: 1,
              borderStyle: 'solid',
              borderRadius: '4px',
              mt: 1,
            }}
          >
            <Stack direction={'row'}>
              {insertBetweenItems(
                days.map((day) => (
                  <Stack
                    key={day.id}
                    alignItems={'center'}
                    direction={'column'}
                    flex={1}
                    sx={[
                      { padding: '8px' },
                      allowedGridItem(allowedDays[day.num]),
                    ]}
                  >
                    <Typography variant="subtitle2">{day.label}</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle2">On</Typography>
                      <AntSwitch
                        checked={allowedDays[day.num]}
                        onChange={(value) =>
                          setAllowedDays((val) => {
                            val[day.num] = !val[day.num];
                            return { ...val };
                          })
                        }
                        disabled={isLoadingAccept}
                        inputProps={{ 'aria-label': 'ant design' }}
                      />
                    </Stack>
                  </Stack>
                )),
                <Divider orientation="vertical" flexItem />,
              )}
            </Stack>
            {!!postingTimes.length && (
              <Divider sx={{ padding: 0, margin: 0 }} />
            )}
            {insertBetweenItems(
              postingTimes.map((postingTime) => (
                <Stack key={postingTime.id} direction={'row'}>
                  {insertBetweenItems(
                    days.map((day) => {
                      const value = postingTime.days[day.num];
                      if (!value)
                        return (
                          <Box
                            key={day.id}
                            flex={1}
                            sx={[
                              { padding: '8px' },
                              allowedGridItem(allowedDays[day.num]),
                            ]}
                          ></Box>
                        );

                      const channels = postingTime.channels.map((channel) =>
                        channelById(channel.groupId, channel.channelId),
                      );
                      return (
                        <Box
                          flex={1}
                          key={day.id}
                          sx={[
                            { padding: '8px', position: 'relative' },
                            allowedGridItem(allowedDays[day.num]),
                            {
                              '& .hidden-button': {
                                display: 'none',
                              },
                              '&:hover .hidden-button': {
                                display: 'flex',
                              },
                            },
                          ]}
                        >
                          <Stack alignItems={'center'} direction={'column'}>
                            <Stack direction={'row'}>
                              {Object.entries(
                                groupBy(
                                  channels,
                                  (channel) => channel.data().type,
                                ),
                              ).map(([channelType, channels], i) => (
                                <Tooltip
                                  title={
                                    <Box
                                      sx={{
                                        width: 100,
                                        whiteSpace: 'normal',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {channels
                                        .map((c) => c.data().channelName)
                                        .join('\n')}
                                    </Box>
                                  }
                                  key={channelType}
                                >
                                  <Box
                                    sx={{
                                      marginInlineStart: i === 0 ? 0 : '-4px',
                                    }}
                                  >
                                    <ChannelIcon
                                      channelType={channelType}
                                      size={16}
                                    />
                                  </Box>
                                </Tooltip>
                              ))}
                            </Stack>

                            <Typography variant="subtitle2">
                              {postingTime.hour.toString().padStart(2, '0')}:
                              {postingTime.minutes.toString().padStart(2, '0')}
                            </Typography>
                          </Stack>
                          <Box
                            className="hidden-button"
                            sx={{
                              position: 'absolute',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              right: '8px',
                              top: 0,
                              bottom: 0,
                            }}
                          >
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => onDeleteDayClick(postingTime, day)}
                            >
                              <Close fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </Box>
                      );
                    }),
                    <Divider orientation="vertical" flexItem />,
                  )}
                </Stack>
              )),
              <Divider sx={{ padding: 0, margin: 0 }} />,
            )}
          </Stack>
        </Stack>
      )}
    </CustomizedDialogs>
  );
};

export default ScheduleClaimRequestDialog;
