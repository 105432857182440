import { CloseFullscreen, OpenInFull } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ClaimRequestsContext } from '../contexts/ClaimRequests';
import { GroupsContext } from '../contexts/Groups';
import { SnackbarContext } from '../contexts/Snackbar';
import { track } from '../utils/analytics';

const PendingClaimRequest = () => {
  const navigate = useNavigate();

  const { claimRequests } = useContext(ClaimRequestsContext);
  const { allChannels } = useContext(GroupsContext);
  const { show } = useContext(SnackbarContext);

  const [isMini, setIsMini] = useState(false);

  useEffect(() => {
    try {
      setIsMini(window.localStorage.getItem('isPendingRequestMini') === 'true');
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (!claimRequests?.length) return;

    track('pending_claim_request_is_mini_updated', {
      isMini,
      channelsCount: allChannels().length,
      claimRequestId: claimRequests?.[0].id,
    });
  }, [isMini]);

  const updateIsMini = (value) => {
    setIsMini(value);
    try {
      window.localStorage.setItem(
        'isPendingRequestMini',
        value ? 'true' : 'false',
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onClaimButtonClick = () => {
    track('pending_claim_request_claim_button_click', {
      channelsCount: allChannels().length,
      claimRequestId: claimRequests?.[0]?.id,
    });

    if (allChannels().length) {
      navigate(
        `/dashboard/calendar?scheduleClaimRequestId=${claimRequests[0].id}`,
      );
    } else {
      navigate('/dashboard/manage-accounts');
      show('you need to add at least 1 account to proceed claiming the videos');
    }
  };

  if (!claimRequests?.length) return <></>;

  return (
    <>
      <Card
        sx={{
          width: isMini ? null : '400px',
          position: 'fixed',
          top: isMini ? null : 70,
          bottom: isMini ? 16 : null,
          right: 0,
          zIndex: 1000,
          padding: 2,
          margin: 2,
        }}
        elevation={24}
      >
        <Box sx={{ position: 'relative' }}>
          <Stack
            direction={isMini ? 'row' : 'column'}
            spacing={isMini ? 2 : 0}
            alignItems={'center'}
          >
            <Typography variant={isMini ? 'body1' : 'h5'}>
              You have videos to claim
            </Typography>
            {!isMini && (
              <Typography>
                Click here to claim the videos and schedule all at once
              </Typography>
            )}
            <Button
              variant="contained"
              sx={{ mt: isMini ? 0 : 2 }}
              onClick={onClaimButtonClick}
            >
              Claim now
            </Button>
            {isMini && (
              <IconButton onClick={() => updateIsMini(false)}>
                <OpenInFull />
              </IconButton>
            )}
          </Stack>
          {!isMini && (
            <IconButton
              sx={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() => updateIsMini(true)}
            >
              <CloseFullscreen />
            </IconButton>
          )}
        </Box>
      </Card>
    </>
  );
};

export default PendingClaimRequest;
