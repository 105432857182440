/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
import { Box, Container, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import WebsiteAppBar from '../components/WebsiteAppBar';

const Privacy = () => {
  useEffect(() => {}, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ maxWidth: '100vw', minHeight: '30vh', bgcolor: 'primary.main' }}
      >
        <WebsiteAppBar />
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          direction={'row'}
          sx={{ height: 'calc(30vh - 80px)', width: '100%' }}
        >
          <Typography
            sx={{
              fontSize: 42,
              fontWeight: 700,
              color: 'white',
              textAlign: 'left',
            }}
          >
            Privacy Policy
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={'md'}>
        <Box style={{ paddingRight: '0.25in', lineBreak: 'anywhere' }}>
          <div className="row">
            <div className="col-lg-12" style={{ color: '#4A4A4A' }}>
              <ol>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Introduction</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakInside: 'auto' }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                Welcome to&nbsp;<b>Socialoop, Inc.</b>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Socialoop, Inc.&nbsp;(“us”, “we”, or “our”)
                operates&nbsp;https://socialoop.com&nbsp;(hereinafter referred
                to as “<b>Service</b>”).
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Our Privacy Policy governs your visit
                to&nbsp;https://socialoop.com, and explains how we collect,
                safeguard and disclose information that results from your use of
                our Service.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We use your data to provide and improve Service. By using
                Service, you agree to the collection and use of information in
                accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Our Terms and Conditions (“<b>Terms</b>”) govern all use of our
                Service and together with the Privacy Policy constitutes your
                agreement with us (“<b>agreement</b>”).
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={2}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Definitions</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                <b>SERVICE&nbsp;</b>means the&nbsp;https://socialoop.com
                website&nbsp;operated by&nbsp;Socialoop, Inc.
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                <b>PERSONAL DATA</b>&nbsp;means data about a living individual
                who can be identified from those data (or from those and other
                information either in our possession or likely to come into our
                possession).
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                <b>USAGE DATA</b>&nbsp;is data collected automatically either
                generated by the use of Service or from Service infrastructure
                itself (for example, the duration of a page visit).
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                <b>COOKIES</b>&nbsp;are small files stored on your device
                (computer or mobile device).
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                <b>DATA CONTROLLER</b>
                <span lang="uk-UA">
                  &nbsp;means a natural or legal person who (either alone or
                  jointly or in common with other persons) determines the
                  purposes for which and the manner in which any personal data
                  are, or are to be, processed. For the purpose of this Privacy
                  Policy, we are a Data Controller of your data.
                </span>
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b>
                <span lang="uk-UA">
                  &nbsp;means any natural or legal person who processes the data
                  on behalf of the Data Controller. We may use the services of
                  various Service Providers in order to process your data more
                  effectively.
                </span>
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                <b>DATA SUBJECT&nbsp;</b>is any living individual who is the
                subject of Personal Data.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                <b>THE USER&nbsp;</b>is the individual using our Service. The
                User corresponds to the Data Subject, who is the subject of
                Personal Data.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={3}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Information Collection and Use</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={4}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Types of Data Collected</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <b>Personal Data</b>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (“<b>Personal Data</b>”). Personally
                identifiable information may include, but is not limited to:
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakAfter: 'auto' }}
              ></p>

              <ol>
                <ol type="a">
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      Email address
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={2}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      First name and last name
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={3}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      Phone number
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={4}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      Address, State, Province, ZIP/Postal code, City
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={5}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      Cookies and Usage Data
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us&nbsp;by following the
                unsubscribe link.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.2in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <b>Usage Data</b>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We may also collect information that your browser sends whenever
                you visit our Service or when you access Service by or through a
                mobile device (“<b>Usage Data</b>”).
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                This Usage Data may include information such as your computer's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                When you access Service with a mobile device, this Usage Data
                may include information such as the type of mobile device you
                use, your mobile device unique ID, the IP address of your mobile
                device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other
                diagnostic data.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.2in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <b>Location Data</b>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                We may use and store information about your location if you give
                us permission to do so (“<b>Location Data</b>”). We use this
                data to provide features of our Service, to improve and
                customize our Service.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                You can enable or disable location services when you use our
                Service at any time by way of your device settings.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.2in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <b>Tracking Cookies Data</b>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                Examples of Cookies we use:
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      <b>Session Cookies:</b>&nbsp;We use Session Cookies to
                      operate our Service.
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={2}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      <b>Preference Cookies:</b>&nbsp;We use Preference Cookies
                      to remember your preferences and various settings.
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={3}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      <b>Security Cookies:</b>&nbsp;We use Security Cookies for
                      security purposes.
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={4}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      <b>Advertising Cookies:</b>&nbsp;Advertising Cookies are
                      used to serve you with advertisements that may be relevant
                      to you and your interests.
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <b>Other Data</b>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                While using our Service, we may also collect the following
                information: sex, age, date of birth, place of birth, passport
                details, citizenship, registration at place of residence and
                actual address, telephone number (work, mobile), details of
                documents on education, qualification, professional training,
                employment agreements, non-disclosure agreements, information on
                bonuses and compensation, information on marital status, family
                members, social security (or other taxpayer identification)
                number, office location and other data.
              </p>

              <p
                className="western"
                style={{ marginBottom: '0in', pageBreakAfter: 'auto' }}
              ></p>

              <ol start={5}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Use of Data</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakInside: 'auto' }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                Socialoop, Inc.&nbsp;uses the collected data for various
                purposes:
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakInside: 'auto' }}
              ></p>

              <ol>
                <ol type="a">
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to provide and maintain our Service;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={2}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to notify you about changes to our Service;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={3}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to allow you to participate in interactive features of our
                      Service when you choose to do so;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={4}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to provide customer support;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={5}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to gather analysis or valuable information so that we can
                      improve our Service;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={6}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to monitor the usage of our Service;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={7}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to detect, prevent and address technical issues;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={8}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to fulfill any other purpose for which you provide it;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={9}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to carry out our obligations and enforce our rights
                      arising from any contracts entered into between you and
                      us, including for billing and collection;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={10}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to provide you with notices about your account and/or
                      subscription, including expiration and renewal notices,
                      email-instructions, etc.;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={11}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      to provide you with news, special offers and general
                      information about other goods, services and events which
                      we offer that are similar to those that you have already
                      purchased or enquired about unless you have opted not to
                      receive such information;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={12}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      in any other way we may describe when you provide the
                      information;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={13}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      for any other purpose with your consent.
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={6}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Retention of Data</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakInside: 'auto' }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period, except
                when this data is used to strengthen the security or to improve
                the functionality of our Service, or we are legally obligated to
                retain this data for longer time periods.
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={7}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Transfer of Data</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Your information, including Personal Data, may be transferred to
                – and maintained on – computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                If you are located outside United States and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to United States and process it there.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginRight: '0in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Socialoop, Inc.&nbsp;will take all the steps reasonably
                necessary to ensure that your data is treated securely and in
                accordance with this Privacy Policy and no transfer of your
                Personal Data will take place to an organisation or a country
                unless there are adequate controls in place including the
                security of your data and other personal information.
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={8}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Disclosure of Data</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                We may disclose personal information that we collect, or you
                provide:
              </p>

              <p
                className="western"
                style={{ marginBottom: '0in', pageBreakAfter: 'auto' }}
              ></p>

              <ol>
                <ol type="a">
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        marginTop: 24,
                        color: '#4A4A4A',
                      }}
                    >
                      <b>Disclosure for Law Enforcement.</b>
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Under certain circumstances, we may be required to disclose your
                Personal Data if required to do so by law or in response to
                valid requests by public authorities.
              </p>

              <p
                className="western"
                style={{
                  marginLeft: '0.3in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={2}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        marginTop: 24,
                        color: '#4A4A4A',
                      }}
                    >
                      <b>Business Transaction.</b>
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                If we or our subsidiaries are involved in a merger, acquisition
                or asset sale, your Personal Data may be transferred.
              </p>

              <p
                className="western"
                style={{
                  marginLeft: '0.3in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={3}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        marginTop: 24,
                        color: '#4A4A4A',
                      }}
                    >
                      <b>Other cases. We may disclose your information also:</b>
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.49in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i">
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        to our subsidiaries and affiliates;
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.75in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={2}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        to contractors, service providers, and other third
                        parties we use to support our business;
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.75in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={3}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        to fulfill the purpose for which you provide it;
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.75in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={4}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        for the purpose of including your company’s logo on our
                        website;
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.75in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={5}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        for any other purpose disclosed by us when you provide
                        the information;
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.75in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={6}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        with your consent in any other cases;
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.75in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={7}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        if we believe disclosure is necessary or appropriate to
                        protect the rights, property, or safety of the Company,
                        our customers, or others.
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.75in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={9}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Security of Data</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakInside: 'auto' }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={10}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>
                        Your Data Protection Rights Under General Data
                        Protection Regulation (GDPR)
                      </b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                If you are a resident of the European Union (EU) and European
                Economic Area (EEA), you have certain data protection rights,
                covered by GDPR. – See more at
                https://eur-lex.europa.eu/eli/reg/2016/679/oj
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We aim to take reasonable steps to allow you to correct, amend,
                delete, or limit the use of your Personal Data.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                If you wish to be informed what Personal Data we hold about you
                and if you want it to be removed from our systems, please email
                us at contact@socialoop.com.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                In certain circumstances, you have the following data protection
                rights:
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      the right to access, update or to delete the information
                      we have on you;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={2}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      the right of rectification. You have the right to have
                      your information rectified if that information is
                      inaccurate or incomplete;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={3}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      the right to object. You have the right to object to our
                      processing of your Personal Data;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={4}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      the right of restriction. You have the right to request
                      that we restrict the processing of your personal
                      information;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={5}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      the right to data portability. You have the right to be
                      provided with a copy of your Personal Data in a
                      structured, machine-readable and commonly used format;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={6}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      the right to withdraw consent.&nbsp;You also have the
                      right to withdraw your consent at any time where we rely
                      on your consent to process your personal information;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Please note that we may ask you to verify your identity before
                responding to such requests. Please note, we may not able to
                provide Service without some necessary data.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data. For more
                information, please contact your local data protection authority
                in the European Economic Area (EEA).
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={11}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>
                        Your Data Protection Rights under the California Privacy
                        Protection Act (CalOPPA)
                      </b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                CalOPPA is the first state law in the nation to require
                commercial websites and online services to post a privacy
                policy. The law’s reach stretches well beyond California to
                require a person or company in the United States (and
                conceivable the world) that operates websites collecting
                personally identifiable information from California consumers to
                post a conspicuous privacy policy on its website stating exactly
                the information being collected and those individuals with whom
                it is being shared, and to comply with this policy. – See more
                at:
                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                According to CalOPPA we agree to the following:
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakInside: 'auto' }}
              ></p>

              <ol>
                <ol type="a">
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      users can visit our site anonymously;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={2}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      our Privacy Policy link includes the word “Privacy”, and
                      can easily be found on the page specified above on the
                      home page of our website;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={3}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      users will be notified of any privacy policy changes on
                      our Privacy Policy Page;
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={4}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      users are able to change their personal information by
                      emailing us at contact@socialoop.com.
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                Our Policy on “Do Not Track” Signals:
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakInside: 'auto' }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                We honor Do Not Track signals and do not track, plant cookies,
                or use advertising when a Do Not Track browser mechanism is in
                place. Do Not Track is a preference you can set in your web
                browser to inform websites that you do not want to be tracked.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakInside: 'auto' }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                You can enable or disable Do Not Track by visiting the
                Preferences or Settings page of your web browser.
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={12}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>
                        Your Data Protection Rights under the California
                        Consumer Privacy Act (CCPA)
                      </b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                If you are a California resident, you are entitled to learn what
                data we collect about you, ask to delete your data and not to
                sell (share) it. To exercise your data protection rights, you
                can make certain requests and ask us:
              </p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              ></p>

              <ol>
                <ol type="a">
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      <b>What personal information we have about you</b>. If you
                      make this request, we will return to you:
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i">
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        The categories of personal information we have collected
                        about you.
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.75in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={2}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        The categories of sources from which we collect your
                        personal information.
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={3}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        The business or commercial purpose for collecting or
                        selling your personal information.
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={4}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        The categories of third parties with whom we share
                        personal information.
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={5}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        The specific pieces of personal information we have
                        collected about you.
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={6}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        A list of categories of personal information that we
                        have sold, along with the category of any other company
                        we sold it to. If we have not sold your personal
                        information, we will inform you of that fact.
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a">
                  <ol type="i" start={7}>
                    <li>
                      <p
                        align="JUSTIFY"
                        style={{
                          marginBottom: '0in',
                          pageBreakInside: 'auto',
                          pageBreakAfter: 'auto',
                        }}
                      >
                        A list of categories of personal information that we
                        have disclosed for a business purpose, along with the
                        category of any other company we shared it with.
                      </p>
                    </li>
                  </ol>
                </ol>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.49in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Please note, you are entitled to ask us to provide you with this
                information up to two times in a rolling twelve-month period.
                When you make this request, the information provided may be
                limited to the personal information we collected about you in
                the previous 12&nbsp;months.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={2}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      <b>To delete your personal information</b>. If you make
                      this request, we will delete the personal information we
                      hold about you as of the date of your request from our
                      records and direct any service providers to do the same.
                      In some cases, deletion may be accomplished through
                      de-identification of the information. If you choose to
                      delete your personal information, you may not be able to
                      use certain functions that require your personal
                      information to operate.<b> </b>
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol>
                <ol type="a" start={3}>
                  <li>
                    <p
                      align="JUSTIFY"
                      style={{
                        marginBottom: '0in',
                        pageBreakInside: 'auto',
                        pageBreakAfter: 'auto',
                      }}
                    >
                      <b>To stop selling your personal information</b>. If you
                      submit a request to stop selling your personal
                      information, we will stop selling it. If you are a
                      California resident, to opt-out of the sale of your
                      personal information, click “Do Not Sell My Personal
                      Information” at the bottom of our home page to submit your
                      request.
                    </p>
                  </li>
                </ol>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.49in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.49in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Please note, if you ask us to delete or stop selling your data,
                it may impact your experience with us, and you may not be able
                to participate in certain programs or membership services which
                require the usage of your personal information to function. But
                in no circumstances, we will discriminate against you for
                exercising your rights.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.49in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.49in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                To exercise your California data protection rights described
                above, please send your request(s) by one of the following
                means:
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.49in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                By email: contact@socialoop.com
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                By visiting this page on our website:
                https://socialoop.com/s/contact
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.5in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                Your data protection rights, described above, are covered by the
                CCPA, short for the California Consumer Privacy Act. To find out
                more, visit the official California Legislative Information
                website. The CCPA took effect on 01/01/2020.
              </p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              ></p>

              <ol start={13}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Service Providers</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We may employ third party companies and individuals to
                facilitate our Service (“<b>Service Providers</b>”), provide
                Service on our behalf, perform Service-related services or
                assist us in analysing how our Service is used.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={14}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Analytics</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakInside: 'auto' }}
              ></p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                <b>Google Analytics</b>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its
                own advertising network.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:
                https://policies.google.com/privacy?hl=en
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                  lineBreak: 'auto',
                }}
              >
                We also encourage you to review the Google's policy for
                safeguarding your data:
                https://support.google.com/analytics/answer/6004245.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <b>Firebase</b>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Firebase is analytics service provided by Google Inc.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                You may opt-out of certain Firebase features through your mobile
                device settings, such as your device advertising settings or by
                following the instructions provided by Google in their Privacy
                Policy: https://policies.google.com/privacy?hl=en
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                For more information on what type of information Firebase
                collects, please visit the Google Privacy Terms web page:
                https://policies.google.com/privacy?hl=en
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={15}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>CI/CD tools</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                We may use third-party Service Providers to automate the
                development process of our Service.
              </p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              ></p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                <b>GitHub</b>
              </p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                GitHub is provided by GitHub, Inc.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                GitHub is a development platform to host and review code, manage
                projects, and build software.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              {/* <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                For more information on what data GitHub collects for what
                purpose and how the protection of the data is ensured, please
                visit GitHub Privacy Policy page:<u></u>
                <a href="https://help.github.com/en/articles/github-privacy-statement">
                  https://help.github.com/en/articles/github-privacy-statement.
                </a>
              </p> */}

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              ></p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                <b>GitLab CI/CD</b>
              </p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                GitLab CI/CD is provided by GitLab, Inc.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                GitLab CI (Continuous Integration) service is a part of GitLab
                that build and test the software whenever developer pushes code
                to application.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                GitLab CD (Continuous Deployment) is a software service that
                places the changes of every code in the production which results
                in every day deployment of production.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                For more information on what data GitLab CI/CD collects for what
                purpose and how the protection of the data is ensured, please
                visit GitLab CI/CD Privacy Policy page:
                https://about.gitlab.com/privacy/.
              </p>

              <ol start={16}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Behavioral Remarketing</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginBottom: '0in', pageBreakInside: 'auto' }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Socialoop, Inc.&nbsp;uses remarketing services to advertise on
                third party websites to you after you visited our Service. We
                and our third-party vendors use cookies to inform, optimise and
                serve ads based on your past visits to our Service.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                <b>Google Ads (AdWords)</b>
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Google Ads (AdWords) remarketing service is provided by Google
                Inc.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                You can opt-out of Google Analytics for Display Advertising and
                customize the Google Display Network ads by visiting the Google
                Ads Settings page: http://www.google.com/settings/ads
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Google also recommends installing the Google Analytics Opt-out
                Browser Add-on – https://tools.google.com/dlpage/gaoptout – for
                your web browser. Google Analytics Opt-out Browser Add-on
                provides visitors with the ability to prevent their data from
                being collected and used by Google Analytics.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:
                https://policies.google.com/privacy?hl=en
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                <b>Facebook</b>
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Facebook remarketing service is provided by Facebook Inc.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                You can learn more about interest-based advertising from
                Facebook by visiting this page:
                https://www.facebook.com/help/164968693837950
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                To opt-out from Facebook's interest-based ads, follow these
                instructions from Facebook:
                https://www.facebook.com/help/568137493302217
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Facebook adheres to the Self-Regulatory Principles for Online
                Behavioural Advertising established by the Digital Advertising
                Alliance. You can also opt-out from Facebook and other
                participating companies through the Digital Advertising Alliance
                in the USA http://www.aboutads.info/choices/, the Digital
                Advertising Alliance of Canada in Canada
                http://youradchoices.ca/ or the European Interactive Digital
                Advertising Alliance in Europe http://www.youronlinechoices.eu/,
                or opt-out using your mobile device settings.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                For more information on the privacy practices of Facebook,
                please visit Facebook's Data Policy:
                https://www.facebook.com/privacy/explanation
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={17}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Payments</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We may provide paid products and/or services within Service. In
                that case, we use third-party services for payment processing
                (e.g. payment processors).
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We will not store or collect your payment card details. That
                information is provided directly to our third-party payment
                processors whose use of your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                Mastercard, American Express and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                The payment processors we work with are:
              </p>

              <p
                className="western"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="LEFT"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                <b>Stripe:</b>
              </p>

              <p
                className="western"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Their Privacy Policy can be viewed at:
                https://stripe.com/us/privacy
              </p>

              <p
                className="western"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={18}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Links to Other Sites</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>

              <p
                className="western"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={19}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Children's Privacy</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                Our Services are not intended for use by children under the age
                of 13 (“<b>Children</b>”).
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              ></p>

              <p
                className="western"
                align="JUSTIFY"
                style={{ marginLeft: '0.25in', marginBottom: '0in' }}
              >
                We do not knowingly collect personally identifiable information
                from Children under 13. If you become aware that a Child has
                provided us with Personal Data, please contact us. If we become
                aware that we have collected Personal Data from Children without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              ></p>

              <ol start={20}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Changes to This Privacy Policy</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update
                “effective date” at the top of this Privacy Policy.
              </p>

              <p
                className="western"
                align="JUSTIFY"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>

              <p
                className="western"
                style={{
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <ol start={21}>
                <li>
                  <p
                    align="JUSTIFY"
                    style={{
                      marginBottom: '0in',
                      pageBreakInside: 'auto',
                      marginTop: 24,
                      color: '#4A4A4A',
                    }}
                  >
                    <u>
                      <b>Contact Us</b>
                    </u>
                  </p>
                </li>
              </ol>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                <u> </u>
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              >
                If you have any questions about this Privacy Policy, please
                contact us:
              </p>

              <p
                align="JUSTIFY"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                }}
              ></p>

              <p
                className="western"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                By email: contact@socialoop.com.
              </p>

              <p
                className="western"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>

              <p
                className="western"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              >
                By visiting this page on our website:
                https://socialoop.com/s/contact.
              </p>

              <p
                className="western"
                style={{
                  marginLeft: '0.25in',
                  marginBottom: '0in',
                  pageBreakInside: 'auto',
                  pageBreakAfter: 'auto',
                }}
              ></p>
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default Privacy;
