import { Avatar } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect } from 'react';

const AccountListItem = ({ account, onClick }) => {
  useEffect(() => {}, []);

  return (
    <ListItem key={account.name} disablePadding onClick={onClick}>
      <ListItemButton>
        <ListItemIcon sx={{ position: 'relative' }}>
          <Avatar src={account.data().thumbnailUrl} />
          {account.data().type && (
            <img
              src={require(`../assets/icons/${account.data().type}Colored.png`)}
              height={22}
              style={{ position: 'absolute', bottom: -4, right: 12 }}
            />
          )}
        </ListItemIcon>
        <ListItemText primary={account.data().channelName} />
      </ListItemButton>
    </ListItem>
  );
};

export default AccountListItem;
