import 'firebase/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/performance';

const config = {
  apiKey: 'AIzaSyAfC9p5y-3rdUKxWp3DBMydXV2sYzeg-iM',
  authDomain: 'socialoop.firebaseapp.com',
  projectId: 'socialoop',
  storageBucket: 'socialoop.appspot.com',
  messagingSenderId: '195106465165',
  appId: '1:195106465165:web:511bfacc74fbed415909c4',
};

const app = firebase.initializeApp(config);

// const functions = getFunctions(app);
// connectFunctionsEmulator(functions, 'localhost', 5001);

export default firebase;
