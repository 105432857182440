import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from '../Firebase';
import { AuthContext } from './Auth';
import { SnackbarContext } from './Snackbar';

export const ClaimRequestsContext = createContext();

function ClaimRequestsContextProvider(props) {
  const { isAuthenticated, currentUserId } = useContext(AuthContext);
  const { show } = useContext(SnackbarContext);

  const [claimRequests, setClaimRequests] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) return;

    const snapshot = firebase
      .firestore()
      .collection('claimRequests')
      .where(
        'acceptedAt',
        '>=',
        firebase.firestore.Timestamp.fromMillis(
          Date.now() - 7 * 24 * 60 * 60 * 1000,
        ),
      )
      .where(
        'acceptedBy',
        '==',
        currentUserId || firebase.auth().currentUser.uid,
      )
      .where('status', '==', 'accepted')
      .onSnapshot(
        (value) => {
          console.log('claim requests', value.docs);
          setClaimRequests(value.docs);
        },
        (err) => {
          console.log('claim requests', err);
          show(err.toString());
        },
      );

    return () => {
      snapshot();
    };
  }, [isAuthenticated]);

  return (
    <ClaimRequestsContext.Provider
      value={{
        claimRequests,
      }}
    >
      <div>{props.children}</div>
    </ClaimRequestsContext.Provider>
  );
}

export default ClaimRequestsContextProvider;
