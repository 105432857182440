import { Add, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { GroupsContext } from '../contexts/Groups';
import { track } from '../utils/analytics';
import AccounItemInGroupCard from './AccounItemInGroupCard';
import EditGroupDialog from './EditGroupDialog';

const GroupCard = ({
  group,
  onAddAccountClick,
  onManageActiveAccountsClick,
}) => {
  const [openEditGroupDialog, setOpenEditGroupDialog] = React.useState(null);
  const { channelsByGroupId } = React.useContext(GroupsContext);

  const deleteAccount = (channel) => {
    channel.ref.delete();
  };

  const moveAccountTo = async (channel, group) => {
    await group.ref.collection('channels').doc(channel.id).set(channel.data());
    await channel.ref.delete();
  };

  return (
    <Grid item sm={12} md={12} lg={6}>
      <EditGroupDialog
        open={openEditGroupDialog}
        setOpen={setOpenEditGroupDialog}
        groupId={openEditGroupDialog}
      />
      <Stack
        direction={'column'}
        spacing={2}
        sx={{
          width: '100%',
          borderRadius: 2,
          height: 440,
          bgcolor: 'white',
          boxShadow: '0px 0px 30px rgba(102, 102, 102, 0.08)',
          overflowY: 'scroll',
        }}
      >
        <Stack
          direction={'row'}
          spacing={3}
          alignItems={'center'}
          flexShrink={0}
          sx={{
            width: '100%',
            bgcolor: 'primary.main',
            height: '64px',
            borderTopRightRadius: 16,
            borderTopLeftRadius: 16,
            pl: 3,
            pr: 3,
            position: 'sticky',
            top: 0,
            zIndex: 1000,
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontSize: 20,
              flexGrow: 1,
              textAlign: 'left',
            }}
          >
            {group.data().name}
          </Typography>
          <IconButton>
            <Edit
              sx={{ color: 'white' }}
              onClick={() => {
                track('group_card_edit_button_click', {
                  groupId: group.id,
                });
                setOpenEditGroupDialog(group.id);
              }}
            />
          </IconButton>
        </Stack>
        {channelsByGroupId(group.id)
          ?.sort((a, b) =>
            (a.data()?.mode ?? '').localeCompare(b.data()?.mode ?? ''),
          )
          .map((channel) => (
            <Box key={channel.id} sx={{ pl: 2, pr: 2 }}>
              <AccounItemInGroupCard
                groupId={group.id}
                account={{
                  type: channel.data().type,
                  name: channel.data().channelName,
                  avatar:
                    channel.data().thumbnailUrl ||
                    'https://lh3.googleusercontent.com/a-/AD5-WCm2ZxdeSd8sVkEFKccOftYgkvWIKR7RfjXXOZt1hWc0XvANpOGtjIfzw9Mv7Xbi3E4wK8QgpmqpRLziCLNuuWwp9BeWMVLXBHXEW-GQlCsIi9MTD-pCxMOnJfScBepQ3EWQqFoescSkXgC3VvTb89pOa22RYS1-LO3gU3-doKGisUoy9HGY4yLfgr2siwye28S1Kt8igyYL370tK7nlmF67O7z7tDbYZgpzbLtv3vjt_DcDbSCgk4wvECBJy8M3e_9doOUtT2ejUOdfyp_2lWZugTcRG9L1ISoy_ZJgqkrSPnDKWv8DisIysap40IoF7pglihSQxwudZ0p6e866PozDKwYmTdGgyvOuMWfb8WFx_crrdV5vQLA-KVWY-6mwd6NQKv_7YGFJhPIigRr3BXI0_EgBF2XLfKCSOH5bwiK-q0ruzNIEBJuo_oka6_udUA65Witsf4lk4hDgTKMpbOEWphWzmpsflsexEAYq9GHlZ2Vg506W7AtcYPFLZnCK42_cFDhSCahkFGFkZseMQk_SIScbCf56tlj913VBaQTeN5JvmhbHT0IGD1ky_FIRaCHNPnCuTwwLIQZTKXNj4ysS-B2vuaWi0QicKimi6g_z9p1Xj4IH6Iznf-acDHqzVCbzMCIN3hwcl-cqAai74ZMvieqAveu1DfNEobKd_LwGaAMYL78n0N7BXq92sNpZzL4SGMrXAv-7HOE4lRnH4kU_lZqWHwt3FClO0UgUH6avqTW71MctZjb3rHpaowbjM05FV76yB4FAUlkkQTlbrBrQzGMUXY3r-_ItfxSkV3f8Q2kYKn5rBJfyY8I4zV4toHrjD0K9LXzORkU_7ppUUAYqcItuLEE2sv4Ztgm3yF1rUKIZ24C-idNFRo-z0XaPAg4l9_lXw1wC1O85k0dC1azjQwNnbWquFYgJVi-wpU0HIbnP3KDuuJSEx11a=s96-c',
                  mode: channel.data()?.mode,
                }}
                onDeleteAccountClick={() => {
                  track('group_card_delete_account_button_click', {
                    channelId: channel.id,
                  });

                  deleteAccount(channel);
                }}
                onMoveAccount={(group) => {
                  track('group_card_move_account_button_click', {
                    channelId: channel.id,
                    moveToGroupId: group.id,
                  });
                  moveAccountTo(channel, group);
                }}
                onManageLockedAccounts={(group) => {
                  onManageActiveAccountsClick();
                }}
              />
            </Box>
          ))}
        <Box
          sx={{
            position: 'sticky',
            bottom: '0px',
            backgroundColor: 'white',
            width: '100%',
            display: 'flex',
            alignItems: 'start',
            p: 1,
          }}
        >
          <Button onClick={onAddAccountClick} startIcon={<Add />}>
            new account
          </Button>
        </Box>
      </Stack>
    </Grid>
  );
};

export default GroupCard;
