import { Button, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import CustomizedDialogs from './Dialog';

export default function AddAccountFailedDialog({
  open,
  setOpen,
  currentlyAdding,
}) {
  const [searchParams] = useSearchParams();
  return (
    <CustomizedDialogs
      title={'Adding the new account failed'}
      open={!!open}
      setOpen={setOpen}
    >
      <Stack direction={'column'} spacing={3}>
        <Typography>{searchParams.get('addAccountFailed')}</Typography>
        {currentlyAdding === 'instagram' && (
          <Stack direction={'column'} spacing={3}>
            <Typography sx={{ fontWeight: 700 }}>
              What do I need to make it work?
            </Typography>
            <ul>
              <li>
                Make sure your instagram account configured as business account.
              </li>
              <li>
                Make sure your instagram account is connected to a facebook
                page.
              </li>
            </ul>
            <p style={{}}>
              Check out{' '}
              <a
                target="_blank"
                href="https://socialoop.io/blog?p=converting-your-instagram-account-to-a-business-account"
                rel="noreferrer"
              >
                Instagram connection guide
              </a>{' '}
              for more
            </p>
            <Button
              sx={{ width: '100%' }}
              variant={'outlined'}
              size="large"
              onClick={() => setOpen(false)}
            >
              Ok
            </Button>
          </Stack>
        )}
      </Stack>
    </CustomizedDialogs>
  );
}
