import { Edit, Facebook, Instagram, YouTube } from '@mui/icons-material';
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import TikTokIcon from './TikTokIcon';

const ChannelsToggleButton = ({
  currentChannel,
  setCurrentChannel,
  channels,
  channelById,
}) => {
  useEffect(() => {}, []);

  const handleChangeChannel = (_event, newChannel) => {
    setCurrentChannel(newChannel);
  };

  return (
    <Stack
      direction={'column'}
      spacing={1}
      alignItems={'start'}
      sx={{ width: '100%' }}
    >
      <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
        Network tab
      </Typography>
      <ToggleButtonGroup
        color="primary"
        value={currentChannel}
        exclusive
        onChange={handleChangeChannel}
        aria-label="Platform"
        sx={{ height: 40 }}
        fullWidth
      >
        <ToggleButton value={'default'}>
          <Edit fontSize="small" sx={{ mr: 1 }} />
          Original
        </ToggleButton>
        {channels?.find(
          (channel) =>
            channelById(channel.groupId, channel.channelId)?.data().type ===
            'facebook',
        ) && (
          <ToggleButton value={'facebook'}>
            <Facebook fontSize="small" sx={{ mr: 1 }} />
            Facebook
          </ToggleButton>
        )}
        {channels?.find(
          (channel) =>
            channelById(channel.groupId, channel.channelId)?.data().type ===
            'instagram',
        ) && (
          <ToggleButton value={'instagram'}>
            <Instagram fontSize="small" sx={{ mr: 1 }} />
            Instagram
          </ToggleButton>
        )}
        {channels?.find(
          (channel) =>
            channelById(channel.groupId, channel.channelId)?.data().type ===
            'tiktok',
        ) && (
          <ToggleButton value={'tiktok'}>
            <TikTokIcon fontSize="small" sx={{ mr: 1 }} />
            TikTok
          </ToggleButton>
        )}
        {channels?.find(
          (channel) =>
            channelById(channel.groupId, channel.channelId)?.data().type ===
            'youtube',
        ) && (
          <ToggleButton value={'youtube'}>
            <YouTube fontSize="samll" sx={{ mr: 1 }} />
            YouTube
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Stack>
  );
};

export default ChannelsToggleButton;
