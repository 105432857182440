/* eslint-disable require-jsdoc */
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as React from 'react';
import { useEffect } from 'react';
import CustomizedDialogs from './Dialog';

export default function CreateNewClaimRequestDialog({ open, setOpen }) {
  const [isLoading, setIsLoading] = React.useState();
  const [tag, setTag] = React.useState();
  const [creationError, setCreationError] = React.useState();
  const [googleDriveLink, setGoogleDriveLink] = React.useState();
  const [inviteUrl, setInviteUrl] = React.useState();

  useEffect(() => {
    if (!open) {
      setTag(null);
      setCreationError(null);
      setGoogleDriveLink(null);
      setInviteUrl(null);
      setIsLoading(false);
    }
  }, [open]);

  const onCreationClick = () => {
    setCreationError(null);
    setIsLoading(true);

    const functions = getFunctions(getApp());

    const createClaimRequest = httpsCallable(functions, 'claim-createRequest');
    createClaimRequest({ tag, googleDriveLink })
      .then((result) => {
        setInviteUrl(result.data.url);
      })
      .catch((e) => {
        setCreationError(e.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCopyInviteUrlButtonClick = () => {
    navigator.clipboard.writeText(inviteUrl);
  };

  return (
    <CustomizedDialogs
      title={'Create claim request'}
      open={!!open}
      setOpen={setOpen}
    >
      <Stack>
        <TextField
          label="Tag"
          fullWidth
          type={'text'}
          sx={{ mt: 1 }}
          onChange={(e) => {
            setTag(e.target.value);
            setCreationError(null);
          }}
          error={!tag}
          helperText={!tag && 'tag is required'}
          disabled={!!inviteUrl}
        />
        <TextField
          label="Google drive link"
          fullWidth
          type={'text'}
          sx={{ mt: 4 }}
          onChange={(e) => {
            setGoogleDriveLink(e.target.value);
            setCreationError(null);
          }}
          error={!googleDriveLink}
          helperText={!googleDriveLink && 'google drive link is required'}
          disabled={!!inviteUrl}
        />
        {!isLoading && (
          <Button
            variant="outlined"
            size="large"
            sx={{ height: 40, marginTop: '24px' }}
            fullWidth={false}
            onClick={onCreationClick}
            disabled={!!inviteUrl}
          >
            Create
          </Button>
        )}
        {isLoading && (
          <CircularProgress size={24} sx={{ height: 40, marginTop: '24px' }} />
        )}
        {!inviteUrl && creationError}
        {inviteUrl && (
          <>
            <Typography
              sx={{
                color: 'black',
                fontSize: 11,
                fontWeight: 700,
                textAlign: 'start',
                paddingTop: '24px',
              }}
            >
              Invite Link:
            </Typography>
            <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
              {inviteUrl}
              <Button fullWidth={false} onClick={onCopyInviteUrlButtonClick}>
                Copy Link
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </CustomizedDialogs>
  );
}
