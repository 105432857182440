import { Box, Button, Card, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useContext, useEffect } from 'react';
import firebase from '../Firebase';
import CreateNewClaimRequestDialog from '../components/CreateNewClaimRequestDialog';
import { AuthContext } from '../contexts/Auth';
import { SnackbarContext } from '../contexts/Snackbar';

const Admin = () => {
  const { setCurrentUserId } = useContext(AuthContext);
  const { show } = useContext(SnackbarContext);
  const [openCreateClaimRequestDialog, setOpenCreateClaimRequestDialog] =
    React.useState(null);
  const [claimRequests, setClaimRequests] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [rows, setRows] = React.useState(null);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      valueGetter: ({ value }) => value && value.toDate(),
      valueFormatter: ({ value }) =>
        value &&
        value.toLocaleString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
    },
    {
      field: 'tag',
      headerName: 'Tag',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
    },
    {
      field: 'driveFilesCount',
      headerName: 'Files',
      width: 30,
    },
    {
      field: 'acceptedAt',
      headerName: 'Accepted At',
      width: 200,
      valueGetter: ({ value }) => value && value.toDate(),
      valueFormatter: ({ value }) =>
        value &&
        value.toLocaleString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
    },
    {
      field: 'acceptedByEmail',
      headerName: 'Accepted By Email',
      width: 200,
    },
    {
      field: 'usedAt',
      headerName: 'Used At',
      width: 200,
      valueGetter: ({ value }) => value && value.toDate(),
      valueFormatter: ({ value }) =>
        value &&
        value.toLocaleString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
    },
    {
      field: 'subscriptionStatus',
      headerName: 'Subscription',
      width: 100,
    },
    {
      field: 'freeUploadedSchedules',
      headerName: 'Free Uploaded Schedules',
      width: 200,
    },
    {
      field: 'channelsCount',
      headerName: 'Channels Count',
      width: 120,
    },
    {
      field: '*',
      headerName: 'Actions',
      sortable: false,
      width: 400,
      renderCell: (params) => (
        <Stack direction={'row'} gap={1}>
          <strong>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://app.socialoop.io/dashboard/calendar?claimRequestId=${params.row.id}`,
                );

                show('Copied', 'success');
              }}
            >
              Copy Link
            </Button>
          </strong>
          <strong>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://app.socialoop.io/order/${params.row.id}?utm_source=fiverr`,
                );

                show('Copied', 'success');
              }}
            >
              Copy Global Link
            </Button>
          </strong>
          {params.row.acceptedBy && (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                console.log('params', params);
                setCurrentUserId(params.row.acceptedBy);
              }}
            >
              Impersonate
            </Button>
          )}
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    const snapshotClaim = firebase
      .firestore()
      .collection('claimRequests')
      .onSnapshot((event) => {
        setClaimRequests(event.docs);
      });

    const snapshotUsers = firebase
      .firestore()
      .collection('users')
      .onSnapshot(
        (event) => {
          setUsers(event.docs);
        },
        (e) => show(e.toString(), 'success'),
      );

    return () => {
      snapshotClaim();
      snapshotUsers();
    };
  }, []);

  useEffect(() => {
    if (!claimRequests) return;

    const usersMap = new Map(users.map((u) => [u.id, u.data()]));
    const rows = claimRequests.map((d) => {
      const user = usersMap.get(d.data().acceptedBy);

      return {
        ...d.data(),
        id: d.id,
        subscriptionStatus: user?.subscriptionStatus,
        freeUploadedSchedulesIds: user?.freeUploadedSchedules?.length,
        channelsCount: user?.allowedChannels?.length,
      };
    });

    setRows(rows);
  }, [claimRequests, users]);

  return (
    <>
      <CreateNewClaimRequestDialog
        open={openCreateClaimRequestDialog}
        setOpen={setOpenCreateClaimRequestDialog}
      />
      <Box sx={{ padding: '24px' }}>
        <Stack direction={'row-reverse'} sx={{ marginBottom: '24px' }}>
          <Button
            variant="outlined"
            size="large"
            sx={{ height: 40 }}
            fullWidth={false}
            onClick={() => setOpenCreateClaimRequestDialog(true)}
          >
            Create
          </Button>
        </Stack>
        <Card sx={{ height: '500px', width: '100%' }}>
          <DataGrid
            rows={rows ?? []}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            disableSelectionOnClick
            loading={rows === null}
            initialState={{
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
              },
            }}
          />
        </Card>
      </Box>
    </>
  );
};

export default Admin;
