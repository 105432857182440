import { InfoRounded } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';

import { SnackbarContext } from '../contexts/Snackbar';
import AntSwitch from './AntSwitch';
import CustomizedDialogs from './Dialog';

const MultiOptionButton = ({ title, selected, disabled, onClick }) => {
  return (
    <Button
      variant={selected ? 'contained' : 'outlined'}
      onClick={onClick}
      disabled={disabled}
      sx={{ width: '102px', backgroundColor: selected ? '#1565C0' : '#F6FAFD' }}
    >
      <Stack direction={'column'} alignItems={'center'} sx={{ p: 1 }}>
        <Box
          sx={{
            borderColor: selected ? 'white' : '#1976D2',
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: '10px',
            width: '20px',
            height: '20px',
            mb: '6px',
            p: '3px',
          }}
        >
          {selected && (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                backgroundColor: selected ? 'white' : '#1976D2',
                borderRadius: '10px',
              }}
            />
          )}
        </Box>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: 12,
            color: selected ? 'white' : '#212121',
          }}
        >
          {title}
        </Typography>
      </Stack>
    </Button>
  );
};

const toneVoiceOptions = [
  {
    code: 'proffesional',
    label: 'Proffesional',
  },
  {
    code: 'casual',
    label: 'Casual',
  },
  {
    code: 'serious',
    label: 'Serious',
  },
  {
    code: 'funny',
    label: 'Funny',
  },
  {
    code: 'informative',
    label: 'Informative',
  },
  {
    code: 'somber',
    label: 'Somber',
  },
  {
    code: 'flirtatious',
    label: 'Flirtatious',
  },
];

const WritingAssistantDialog = ({ open, setOpen, onGenerated }) => {
  const { show } = useContext(SnackbarContext);

  const [isGeneratingText, setIsGeneratingText] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [characterLimit, setCharacterLimit] = useState(null);
  const [addHashtags, setAddHashtags] = useState(false);
  const [voiceTone, setVoiceTone] = useState(toneVoiceOptions[0].code);

  const onGenerateTextButtonClick = () => {
    setIsGeneratingText(true);
    const functions = getFunctions(getApp());

    const writingAssistant = httpsCallable(functions, 'ai-writingAssistant');
    writingAssistant({
      prompt,
      keywords,
      characterLimit: characterLimit && +characterLimit,
      addHashtags,
      voiceTone,
    })
      .then((result) => {
        onGenerated(result.data);
      })
      .catch((e) => {
        show(`Failed to generate text, try again, ${e.toString()}`, 'error');
      })
      .finally(() => {
        setIsGeneratingText(false);
      });
  };

  return (
    <CustomizedDialogs
      title={'Writing  Assistant'}
      open={!!open}
      setOpen={setOpen}
      maxWidth={'md'}
      actions={[
        ...(isGeneratingText
          ? [
              {
                type: 'loader',
              },
            ]
          : []),
        {
          title: 'Generate Text',
          variant: 'contained',
          disabled: isGeneratingText,
          function: onGenerateTextButtonClick,
        },
      ]}
    >
      <Stack direction={'column'} gap={2}>
        <Stack direction={'column'}>
          <Typography sx={{ fontSize: 16, fontWeight: 700 }}>Prompt</Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#616161' }}>
            In a few sentences, describe what you want our AI to write about.
          </Typography>
          <TextField
            sx={{ mt: 1 }}
            placeholder={'You can be as specific as you want.'}
            value={prompt}
            onChange={(value) => setPrompt(value.target.value)}
            disabled={isGeneratingText}
          />
        </Stack>
        <Stack direction={'column'}>
          <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
            Keywords
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#616161' }}>
            These keywords will be used in the generated text
          </Typography>
          <Autocomplete
            multiple
            fullWidth
            id="keywords"
            sx={{ mt: 1 }}
            freeSolo={true}
            options={[]}
            value={keywords}
            disabled={isGeneratingText}
            onChange={(_e, v) => {
              setKeywords([...v]);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder="Add any keyword that may be related"
              />
            )}
          />
        </Stack>
        <Stack direction={'column'}>
          <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
            Character limit
          </Typography>
          <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
            <InfoRounded fontSize="20" sx={{ color: '#1976D2' }} />
            <Typography
              sx={{ fontSize: 14, fontWeight: 400, color: '#616161' }}
            >
              Every social network has its own character limit
            </Typography>
          </Stack>
          <TextField
            sx={{ mt: 1 }}
            placeholder={'100'}
            value={characterLimit}
            onChange={(value) => setCharacterLimit(value.target.value)}
            disabled={isGeneratingText}
          />
        </Stack>
        <Stack direction={'column'}>
          <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
            Include hashtags
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#616161' }}>
            hashtags counts as characters too
          </Typography>
          <AntSwitch
            sx={{ mt: 1 }}
            value={addHashtags}
            onChange={(value) => setAddHashtags(value.target.value)}
            disabled={isGeneratingText}
          />
        </Stack>
        <Stack direction={'column'}>
          <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
            Tone of voice
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#616161' }}>
            the generated text will vary depending on the selected tone
          </Typography>
          <Stack
            direction={'row'}
            gap={1}
            sx={{ mt: 1 }}
            justifyContent={'space-between'}
          >
            {toneVoiceOptions.map((t) => (
              <MultiOptionButton
                key={t.code}
                title={t.label}
                selected={voiceTone === t.code}
                disabled={isGeneratingText}
                onClick={() => setVoiceTone(t.code)}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </CustomizedDialogs>
  );
};

export default WritingAssistantDialog;
