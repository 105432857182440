import { Button, Stack } from '@mui/material';
import * as React from 'react';
import CustomizedDialogs from './Dialog';

export default function AreYouSureDialog({
  open,
  setOpen,
  action,
  title,
  positiveTitle,
  negativeTitle,
}) {
  return (
    <CustomizedDialogs
      title={title || 'Are you sure you want to continue?'}
      open={!!open}
      setOpen={setOpen}
    >
      <Stack spacing={3} direction={'row'}>
        <Button onClick={() => action()} variant="contained" size="large">
          {positiveTitle || 'Yes'}
        </Button>
        <Button onClick={() => setOpen(false)} variant="contained" size="large">
          {negativeTitle || 'No'}
        </Button>
      </Stack>
    </CustomizedDialogs>
  );
}
