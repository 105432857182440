export function chunkArray(array, chunkSize = 10) {
  const chunkedArray = [];
  let index = 0;

  while (index < array.length) {
    chunkedArray.push(array.slice(index, index + chunkSize));
    index += chunkSize;
  }

  return chunkedArray;
}

export const getChannelConfigProperty = (
  property,
  config,
  channelId,
  channelType,
) => {
  return (
    config[channelId]?.[property] ||
    config[channelType]?.[property] ||
    config['default']?.[property]
  );
};

export const insertBetweenItems = (arr, thing) =>
  [].concat(...arr.map((n) => [n, thing])).slice(0, -1);

export function distinct(arr, identifier) {
  const unique = new Map();
  return arr.filter((obj) => {
    const id = identifier(obj);
    return unique.has(id) ? false : unique.set(id, true);
  });
}

export function groupBy(arr, keyFunc) {
  const groups = new Map();
  for (const obj of arr) {
    const key = keyFunc(obj);
    const group = groups.get(key) || [];
    group.push(obj);
    groups.set(key, group);
  }
  return Object.fromEntries(groups);
}
