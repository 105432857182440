import React from 'react';
import ReactDOM from 'react-dom/client';

import mixpanel from 'mixpanel-browser';
mixpanel.init('8ba0323623541e042019336ad477d059', {
  debug: process.env.NODE_ENV === 'development',
});

import { hotjar } from 'react-hotjar';
hotjar.initialize(3408141, 6, process.env.NODE_ENV === 'development');

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'dayjs';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import packageInfo from '../package.json';
import App from './App';
import AuthContextProvider from './contexts/Auth.js';
import ClaimRequestsContextProvider from './contexts/ClaimRequests';
import GroupsContextProvider from './contexts/Groups.js';
import SchedulesContextProvider from './contexts/Schedules.js';
import SnackbarContextProvider from './contexts/Snackbar';
import SubscriptionContextProvider from './contexts/Subscription';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './theme';

import 'swiper/css';

const INTERCOM_APP_ID = 'iaqbrxej';

Sentry.init({
  dsn: 'https://b70c4071620d4074a2a993e0ce69c513@o4504836005036032.ingest.sentry.io/4504836301586432',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  release: packageInfo.version,
  environment:
    process.env.NODE_ENV === 'development' ? 'development' : 'production',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthContextProvider>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
          <GroupsContextProvider>
            <ThemeProvider>
              <SnackbarContextProvider>
                <SchedulesContextProvider>
                  <SubscriptionContextProvider>
                    <ClaimRequestsContextProvider>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </ClaimRequestsContextProvider>
                  </SubscriptionContextProvider>
                </SchedulesContextProvider>
              </SnackbarContextProvider>
            </ThemeProvider>
          </GroupsContextProvider>
        </IntercomProvider>
      </AuthContextProvider>
    </LocalizationProvider>
  </React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
