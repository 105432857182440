import { getChannelConfigProperty } from '.';

export const isValidScheduleConfig = (schedule, channelTypes) => {
  return !getScheduleConfigIssues(schedule, channelTypes).length;
};

export const getScheduleConfigIssues = (schedule, channelTypes) => {
  if (!schedule?.config) return ['Missing config'];

  const { config } = schedule;
  const channelTypesSet = new Set(channelTypes);

  const issues = [];

  if (
    !getChannelConfigProperty('description', config, null, 'default') ||
    getChannelConfigProperty('description', config, null, 'default') === ''
  ) {
    issues.push('Description cannot be empty');
  }

  if (
    channelTypesSet.has('youtube') &&
    (!getChannelConfigProperty('title', config, null, 'youtube') ||
      getChannelConfigProperty('title', config, null, 'youtube') === '')
  ) {
    issues.push('Title cannot be empty under YouTube');
  }

  return issues;
};
