import { Lock, MoreHoriz } from '@mui/icons-material';
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useContext, useEffect } from 'react';
import { GroupsContext } from '../contexts/Groups';
import useResponsive from '../hooks/useResponsive';

const AccounItemInGroupCard = ({
  groupId,
  account,
  onDeleteAccountClick,
  onMoveAccount,
  onManageLockedAccounts,
}) => {
  useEffect(() => {}, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isDesktop = useResponsive('up', 'md');
  const { groups } = useContext(GroupsContext);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getPlatformName = (platform) => {
    switch (platform) {
      case 'youtube':
        return 'YouTube';
      case 'instagram':
        return 'Instagram';
      case 'facebook':
        return 'Facebook';
      case 'tiktok':
        return 'TikTok';
      default:
        return 'Unknown';
    }
  };

  return (
    <Box
      key={account.name}
      disablePadding
      sx={{
        bgcolor: '#F6FAFD',
        borderRadius: 1.5,
        border: '1px solid #E3F2FD',
        p: 2,
        width: '100%',
        position: 'relative',
      }}
    >
      <Stack direction="row" spacing={2} alignItems={'center'}>
        <ListItemIcon sx={{ position: 'relative' }}>
          <Avatar src={account.avatar} />
          <img
            src={require(`../assets/icons/${account.type}Colored.png`)}
            height={22}
            style={{ position: 'absolute', bottom: -4, right: 12 }}
          />
        </ListItemIcon>
        <Stack sx={{ width: '100%', flexGrow: 1 }}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography
              sx={{ textAlign: 'start', fontSize: 16, fontWeight: 700 }}
            >
              {account.name}
            </Typography>
            {!isDesktop && account.mode === 'locked' && (
              <Lock fontSize={'14px'} />
            )}
          </Stack>
          <Typography
            sx={{ textAlign: 'start', fontSize: 14, fontWeight: 400 }}
          >
            {`${getPlatformName(account.type)} Account`}
          </Typography>
        </Stack>
        {isDesktop && account.mode === 'locked' && <Lock />}
        {isDesktop ? (
          <IconButton>
            <MoreHoriz onClick={handleOpenMenu} />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              position: isDesktop ? 'relative' : 'absolute',
              top: 16,
              right: 4,
            }}
          >
            <MoreHoriz onClick={handleOpenMenu} />
          </IconButton>
        )}
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {groups
          .filter((g) => g.id !== groupId)
          .map((group) => (
            <MenuItem key={group.id} onClick={() => onMoveAccount(group)}>
              {`Move to ${group.data().name}`}
            </MenuItem>
          ))}
        {account.mode === 'locked' && (
          <MenuItem onClick={onManageLockedAccounts}>Enable Account</MenuItem>
        )}
        <MenuItem sx={{ color: 'red' }} onClick={onDeleteAccountClick}>
          Delete Account
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AccounItemInGroupCard;
