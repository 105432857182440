import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField } from '@mui/material';
import * as React from 'react';
import { track } from '../utils/analytics';
import CustomizedDialogs from './Dialog';

export default function VerifyBeforeCancelSubscriptionDialog({
  open,
  setOpen,
  cancelSubscription,
  loading,
}) {
  const [openCancellationDialog, setOpenCancellationDialog] =
    React.useState(false);
  const [reason, setReason] = React.useState('');
  const openCancelDialog = () => {
    setOpen(false);
    setOpenCancellationDialog(true);
  };
  return (
    <>
      <CustomizedDialogs
        title={'Are you sure you want to unsubscribe?'}
        open={!!open}
        setOpen={setOpen}
      >
        <Stack spacing={3} direction={'row'}>
          <Button
            onClick={() => {
              track(
                'verify_before_cancel_subscription_dialog_yes_button_click',
              );
              openCancelDialog();
            }}
            variant="contained"
            size="large"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              track('verify_before_cancel_subscription_dialog_no_button_click');
              setOpen(false);
            }}
            variant="contained"
            size="large"
          >
            No, keep it
          </Button>
        </Stack>
      </CustomizedDialogs>
      <CustomizedDialogs
        title={'Cacnel subscription'}
        open={!!openCancellationDialog}
        setOpen={setOpenCancellationDialog}
      >
        <Stack spacing={3} direction={'column'}>
          <TextField
            rows={4}
            multiline
            sx={{ mt: 2 }}
            label="Why do you want to cancel?"
            onChange={(e) => setReason(e.target.value)}
          />
          <LoadingButton
            onClick={() => {
              track('cancel_subscription_dialog_cancel_button_click');

              cancelSubscription(reason);
            }}
            variant="contained"
            size="large"
            sx={{ width: '100%' }}
            disabled={reason.length === 0}
            loading={loading}
          >
            Cancel subscription
          </LoadingButton>
        </Stack>
      </CustomizedDialogs>
    </>
  );
}
