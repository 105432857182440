import React from 'react';

const ChannelIcon = ({ channelType, size = 24 }) => {
  if (!channelType) return <></>;

  let icon;

  try {
    icon = require(`../assets/icons/${channelType}Colored.png`);
  } catch (error) {
    console.error(error);
  }

  if (!icon) return <></>;

  return <>{channelType && <img src={icon} height={size} />}</>;
};

export default ChannelIcon;
