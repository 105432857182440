import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import OnboardingLayout from '../components/OnboardingLayout';
import { AuthContext } from '../contexts/Auth';
import firebase from '../Firebase';
import { passwordIsValid } from '../utils/validator';

const HAVE_ACCOUNT = {
  marginTop: 24,
  marginBottom: 8,
};

const FORM = {
  width: '100%',
};

const ERROR_MESSAGE = {
  color: 'red',
  fontSize: 14,
  textAlign: 'start',
};

const AuthActions = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const [error, setError] = useState(null);
  const [resetPasswordSuccessfully, setResetPasswordSuccessfully] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [errorPassword, setErrorPassword] = useState(null);
  const [errorRepeatPassword, setErrorRepeatPassword] = useState(null);

  const [oobCode, setOobCode] = useState(null);
  const [mode, setMode] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('mode')) {
      setMode(searchParams.get('mode'));
    }
    if (searchParams.has('oobCode')) {
      setOobCode(searchParams.get('oobCode'));
    }
  }, []);

  //   useEffect(() => {
  //     if (oobCode) verifyEmail(oobCode);
  //   }, [oobCode]);

  //   const verifyEmail = async () => {
  //     if (mode !== 'verifyEmail') return
  //     let res = await User.verifyEmail(oobCode)
  //     if (res.status) {
  //         setEmailVerified(true)
  //     } else {
  //         setEmailVerified(false)
  //         setErrorMessage(res.message)
  //     }
  // };

  const navigate = useNavigate();

  const checkValidation = () => {
    let isValid = true;
    setErrorPassword(null);
    setErrorRepeatPassword(null);
    if (!passwordIsValid(password)) {
      setErrorPassword('Password is not valid');
      isValid = false;
    }
    if (password !== repeatPassword) {
      setErrorRepeatPassword('Passwords are not match');
      isValid = false;
    }
    return isValid;
  };

  const onResetPassword = async () => {
    setError(null);
    if (checkValidation()) {
      setLoading(true);
      firebase
        .auth()
        .verifyPasswordResetCode(oobCode)
        .then(() => {
          firebase
            .auth()
            .confirmPasswordReset(oobCode, password)
            .then((resp) => {
              setResetPasswordSuccessfully(true);
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              setError(error.message);
            });
        })
        .catch((error) => {
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
          setLoading(false);
          setError(error.message);
        });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  return (
    <OnboardingLayout>
      <Stack direction={'column'} spacing={2} sx={FORM}>
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={1}
        >
          <img
            src={require('../assets/icons/resetPassword.png')}
            width={32}
            alt=""
          />
        </Stack>
        <div></div>
        {resetPasswordSuccessfully ? (
          <Typography sx={{ fontSize: 32, fontWeight: 600, color: 'gray.800' }}>
            Password Successfully Changed
          </Typography>
        ) : (
          <Typography sx={{ fontSize: 32, fontWeight: 600, color: 'gray.800' }}>
            Reset Password
          </Typography>
        )}
        <div></div>
        {resetPasswordSuccessfully && (
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: 'gray.700' }}>
            Your password has been succesfully changed. Please proceed to log in
            with your new password below!
          </Typography>
        )}
        <div></div>
        <div></div>
        {!resetPasswordSuccessfully && (
          <TextField
            label="Password"
            fullWidth
            type={'password'}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorPassword(null);
            }}
            error={errorPassword !== null}
            helperText={errorPassword}
          />
        )}
        {!resetPasswordSuccessfully && (
          <TextField
            label="Repeat password"
            fullWidth
            type={'password'}
            onChange={(e) => {
              setRepeatPassword(e.target.value);
              setErrorRepeatPassword(null);
            }}
            error={errorRepeatPassword !== null}
            helperText={errorRepeatPassword}
          />
        )}
        {!resetPasswordSuccessfully && (
          <LoadingButton
            loading={loading}
            onClick={() => onResetPassword()}
            variant="contained"
            size="large"
          >
            Reset Password
          </LoadingButton>
        )}
        {resetPasswordSuccessfully && (
          <LoadingButton
            onClick={() => navigate('/login')}
            variant="contained"
            size="large"
          >
            Back to Login
          </LoadingButton>
        )}
        {error && <Typography sx={ERROR_MESSAGE}>{error}</Typography>}
        {!resetPasswordSuccessfully && (
          <Stack
            style={HAVE_ACCOUNT}
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={0}
          >
            <Typography sx={{ fontSize: 16 }}>Back to</Typography>
            <Button onClick={() => navigate('/login')} size="medium">
              login
            </Button>
          </Stack>
        )}
      </Stack>
    </OnboardingLayout>
  );
};

export default AuthActions;
