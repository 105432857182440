import { Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import firebase from '../Firebase';
import useResponsive from '../hooks/useResponsive';
import DownloadCard from './DownloadCard';
import ScheduleCard from './ScheduleCard';

const OrderPage = () => {
  const { orderId } = useParams();
  const isDesktop = useResponsive('up', 'md');
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    fetchOrderData();
  }, []);

  const fetchOrderData = async () => {
    const res = await firebase
      .firestore()
      .collection('claimRequests')
      .doc(orderId)
      .get();
    setOrderData(res.data());
  };

  return (
    <Container maxWidth={'lg'} disableGutters>
      <Stack direction={'column'} spacing={3} sx={{ mt: 2, p: 2 }}>
        <Typography sx={{ fontSize: { xs: 24, md: 24 }, fontWeight: 700 }}>
          Your order from gal_designs is ready!
        </Typography>
        <Typography>
          You can download your videos or automatically schedule them using
          Socialoop
        </Typography>
        {orderData && (
          <Grid container spacing={isDesktop ? 2 : 0} gap={!isDesktop ? 2 : 0}>
            <Grid item sx={12} sm={12} md={6}>
              <DownloadCard
                orderId={orderId}
                link={orderData?.googleDriveLink}
              />
            </Grid>
            <Grid item sx={12} sm={12} md={6}>
              <ScheduleCard
                orderId={orderId}
                link={`https://app.socialoop.io/dashboard/calendar?claimRequestId=${orderId}`}
              />
            </Grid>
          </Grid>
        )}
      </Stack>
    </Container>
  );
};

export default OrderPage;
