import { Warning } from '@mui/icons-material';
import { Stack, TextField, Typography } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as React from 'react';
import { GroupsContext } from '../contexts/Groups';
import { SnackbarContext } from '../contexts/Snackbar';
import CustomizedDialogs from './Dialog';

export default function ManageYoutubeAccountDialog({
  open,
  setOpen,
  channelId,
  groupId,
}) {
  const { show } = React.useContext(SnackbarContext);
  const { channelById } = React.useContext(GroupsContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [channelName, setChannelName] = React.useState(null);
  const [description, setDescription] = React.useState(null);

  const changed = React.useMemo(() => {
    const channel = channelById(groupId, channelId);
    if (!channel) return false;

    if (channelName && channelName !== channel.data().channelName) {
      return true;
    }
    if (description && description !== channel.data().description) {
      return true;
    }
  }, [channelName, description, channelById]);

  const onSaveClick = () => {
    setIsLoading(true);

    const functions = getFunctions();

    const updateChannelInfo = httpsCallable(
      functions,
      'channels-manage-youtube-updateChannelInfo',
    );
    updateChannelInfo({
      channelId,
      groupId,
      title: channelName,
      description,
    })
      .then((result) => {
        setOpen(false);
        setChannelName(null);
        setDescription(null);
        show('Channel updated');
      })
      .catch((e) => {
        show(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomizedDialogs
      title={`Manage Channel: ${
        channelById(groupId, channelId)?.data().channelName
      }`}
      open={!!open}
      setOpen={setOpen}
      actionsPrefix={
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={1}
        >
          <Warning sx={{ height: 16, width: 16 }} />
          <Typography>
            caution, this will update your youtube channel
          </Typography>
        </Stack>
      }
      actions={[
        { type: 'loader', disabled: !isLoading },
        {
          variant: 'contained',
          title: 'Save',
          disabled: isLoading || !changed,
          function: onSaveClick,
        },
      ]}
    >
      <Stack direction={'column'} spacing={1}>
        {/* <TextField
          sx={{ mt: 1 }}
          label={'Title'}
          value={
            channelName ??
            channelById(groupId, channelId)?.data().channelName ??
            ''
          }
          onChange={(value) => setChannelName(value.target.value)}
          disabled={isLoading}
        /> */}
        <TextField
          sx={{ mt: 1 }}
          label={'Description'}
          value={
            description ??
            channelById(groupId, channelId)?.data().description ??
            ''
          }
          onChange={(value) => setDescription(value.target.value)}
          disabled={isLoading}
        />
      </Stack>
    </CustomizedDialogs>
  );
}
