import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import OnboardingLayout from '../components/OnboardingLayout';
import { AuthContext } from '../contexts/Auth';
import firebase from '../Firebase';
import { emailIsValid, passwordIsValid } from '../utils/validator';

const HAVE_ACCOUNT = {
  marginTop: 24,
  marginBottom: 8,
};

const FORM = {
  width: '100%',
};

const TERMS = {
  marginTop: 16,
  paddingLeft: 32,
  paddingRight: 32,
  fontSize: 14,
};

const ERROR_MESSAGE = {
  color: 'red',
  fontSize: 14,
  textAlign: 'start',
};

const Signup = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [fullName, setFullName] = useState('');

  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorRepeatPassword, setErrorRepeatPassword] = useState(null);
  const [errorFullName, setErrorFullName] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const checkValidation = () => {
    setErrorEmail(null);
    setErrorFullName(null);
    setErrorPassword(null);
    setErrorRepeatPassword(null);
    let isValid = true;
    if (!emailIsValid(email)) {
      setErrorEmail('Email is not valid');
      isValid = false;
    }
    if (fullName.length < 3) {
      setErrorFullName('Full name is too short');
      isValid = false;
    }
    if (!passwordIsValid(password)) {
      setErrorPassword('Password is not valid');
      isValid = false;
    }
    if (password !== repeatPassword) {
      setErrorRepeatPassword('Passwords are not match');
      isValid = false;
    }
    return isValid;
  };

  const onSignup = async (provider) => {
    setError(null);
    if (provider === 'google') {
      signupWithGoogle();
    } else {
      if (checkValidation()) {
        signupWithEmailPaswword();
      }
    }
  };

  const signupWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();
    firebase.auth().signInWithPopup(provider);
  };

  const signupWithEmailPaswword = async () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        user.updateProfile({
          displayName: fullName,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  return (
    <OnboardingLayout>
      <Stack direction={'column'} spacing={2} sx={FORM}>
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={1}
        >
          <img src={require('../assets/icons/profile.png')} width={32} alt="" />
        </Stack>
        <div></div>
        <Typography sx={{ fontSize: 32, fontWeight: 600, color: 'gray.800' }}>
          Create Your Account
        </Typography>
        <div></div>
        <LoadingButton
          onClick={() => onSignup('google')}
          startIcon={
            <img
              alt="logo"
              src={require('../assets/icons/google.png')}
              height={24}
              width={24}
              style={{ marginInlineEnd: 8 }}
            />
          }
          variant="white"
          size="large"
        >
          Continue with Google
        </LoadingButton>
        <div></div>
        <Stack
          direction={'row'}
          sx={{ width: '100%' }}
          alignItems={'center'}
          spacing={4}
        >
          <div
            style={{
              height: 1,
              width: 'auto',
              flex: 1,
              backgroundColor: '#E0E0E0',
            }}
          ></div>
          <Typography sx={{ fontSize: 16, color: 'gray.700' }}>Or</Typography>
          <div
            style={{
              height: 1,
              width: 'auto',
              flex: 1,
              backgroundColor: '#E0E0E0',
            }}
          ></div>
        </Stack>
        <TextField
          label="Full name"
          fullWidth
          type={'text'}
          onChange={(e) => {
            setFullName(e.target.value);
            setErrorFullName(null);
          }}
          error={errorFullName !== null}
          helperText={errorFullName}
        />
        <TextField
          label="Email"
          fullWidth
          type={'email'}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorEmail(null);
          }}
          error={errorEmail !== null}
          helperText={errorEmail}
        />
        <TextField
          label="Password"
          fullWidth
          type={'password'}
          onChange={(e) => {
            setPassword(e.target.value);
            setErrorPassword(null);
          }}
          error={errorPassword !== null}
          helperText={errorPassword}
        />
        <TextField
          label="Repeat password"
          fullWidth
          type={'password'}
          onChange={(e) => {
            setRepeatPassword(e.target.value);
            setErrorRepeatPassword(null);
          }}
          error={errorRepeatPassword !== null}
          helperText={errorRepeatPassword}
        />
        <LoadingButton
          loading={loading}
          onClick={() => onSignup('email')}
          variant="contained"
          size="large"
        >
          Sign Up
        </LoadingButton>
        {error && <Typography sx={ERROR_MESSAGE}>{error}</Typography>}
        <Stack
          style={HAVE_ACCOUNT}
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={1}
        >
          <Typography sx={{ fontSize: 16 }}>
            Already have an account?
          </Typography>
          <Button onClick={() => navigate('/login')} size="medium">
            Signin
          </Button>
        </Stack>
        <p style={TERMS}>
          By signing up I accept the{' '}
          <a target="_blank" href="https://socialoop.com" rel="noreferrer">
            Terms & Conditions
          </a>{' '}
          and the{' '}
          <a target="_blank" href="https://socialoop.com" rel="noreferrer">
            Privacy Policy
          </a>
        </p>
      </Stack>
    </OnboardingLayout>
  );
};

export default Signup;
