import { Container, Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import DashboardAppBar from '../components/DashboardAppBar';
import { AuthContext } from '../contexts/Auth';
import { SnackbarContext } from '../contexts/Snackbar';
import Plan from './Plan';

const PLANS = [
  {
    title: 'Starter',
    productId: '25e32bc0-9ad5-4453-b32c-e596614e37fa',
    subtitle: 'Best for starting on social',
    price: 18,
    includes: ['30 posts per social profile', '1 Social set'],
    color: '#8958FE',
    features: [
      'Instagram Reels auto scheduling',
      'TikTok auto scheduling',
      'YouTube Shorts auto scheduling',
      'YouTube Videos auto scheduling',
      'Facebook page posts auto scheduling',
      'AI content writer assistant',
    ],
  },
  {
    title: 'Creator',
    productId: 'a19f1660-010d-4865-aefd-8d47b4c63e8b',
    subtitle: 'Best for growing teams',
    price: 39,
    includes: ['150 posts per social profile', '3 Social sets'],
    color: '#11BDD8',
    features: [
      'Instagram Reels auto scheduling',
      'TikTok auto scheduling',
      'YouTube Shorts auto scheduling',
      'YouTube Videos auto scheduling',
      'Facebook page posts auto scheduling',
      'AI content writer assistant',
    ],
  },
  {
    title: 'Agency',
    productId: '0f01d2de-9e46-4a4e-b6ee-4adbeb56dad3',
    subtitle: 'Best for managing multiple brands',
    price: 99,
    includes: ['500 posts per social profile', '10 Social sets'],
    color: '#026FC2',
    features: [
      'Instagram Reels auto scheduling',
      'TikTok auto scheduling',
      'YouTube Shorts auto scheduling',
      'YouTube Videos auto scheduling',
      'Facebook page posts auto scheduling',
      'AI content writer assistant',
    ],
  },
];

const Plans = ({ spaceOnTop = true }) => {
  const { activeSubscription, setSubscriptionMaybeActive } =
    useContext(AuthContext);
  const { show } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (activeSubscription && spaceOnTop) navigate('/dashboard/calendar');
  }, [activeSubscription]);

  useEffect(() => {
    if (searchParams.get('result')) {
      if (searchParams.get('result') === 'success') {
        setSubscriptionMaybeActive(true);
        navigate('/dashboard/calendar');
        show('Subscription started successfully', 'success');
      } else if (searchParams.get('result') === 'failure') {
        show(
          'Start subscription failed. Try again later or contact support',
          'error',
        );
      } else if (searchParams.get('result') === 'cancel') {
        show('Start subscription cancelled', 'info');
      }
    }
  }, [searchParams]);

  return (
    <Container maxWidth={'lg'} disableGutters>
      {spaceOnTop && <DashboardAppBar withoutActions />}
      <Stack
        direction={'column'}
        spacing={3}
        sx={{ mt: spaceOnTop ? 14 : 0, p: 2 }}
      >
        <Typography sx={{ fontSize: 24, fontWeight: 700 }}>
          Choose your subscription plan
        </Typography>
        <Typography>
          Join our subscription plan. No commitment — cancel or change your plan
          anytime.
        </Typography>
        <Grid container columnSpacing={2}>
          {PLANS.map((item, index) => (
            <Grid
              item
              sx={12}
              sm={12}
              md={4}
              key={`plan_${JSON.stringify(item)}`}
            >
              <Plan
                color={item.color}
                title={item.title}
                price={item.price}
                productId={item.productId}
                subtitle={item.subtitle}
                includes={item.includes}
                features={item.features}
                lp={!spaceOnTop}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Container>
  );
};

export default Plans;
