import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import * as React from 'react';

export default function NewConnectionSelection({
  channel,
  label,
  onConnect,
  isLoading,
  disabled,
  comingSoon,
}) {
  return (
    <Box
      sx={{
        bgcolor: '#FAFAFA',
        border: '1px solid #BDBDBD',
        borderRadius: 0.5,
        p: 4,
        flexGrow: 1,
      }}
    >
      <Stack
        direction={'column'}
        spacing={1}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <img
          src={require(`../assets/icons/${channel}Clear.png`)}
          alt=""
          height={45}
        />
        <div></div>
        <div></div>
        <Typography sx={{ fontSize: 16, fontWeight: 700, lineHeight: 1 }}>
          {label}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: '#616161',
            lineHeight: 1,
          }}
        >
          {label}
        </Typography>
        <div></div>
        <div></div>
        {!comingSoon && !isLoading && (
          <Button
            onClick={() => onConnect(channel)}
            disabled={disabled}
            variant="contained"
            size="medium"
          >
            Connect
          </Button>
        )}
        {!comingSoon && isLoading && <CircularProgress size={32} />}
        {comingSoon && <Typography>Coming Soon</Typography>}
      </Stack>
    </Box>
  );
}
