import firebase from '../Firebase';

// const BASE = 'http://localhost:5001/socialoop/us-central1';
const BASE = 'https://us-central1-socialoop.cloudfunctions.net';

export const youTubeOauth = async (requestId) => {
  window.location.href =
    BASE + `/channels-creation-youtube-oauth?requestId=${requestId}`;
};

export const requestYouTubeOauthToken = async (groupId) => {
  const fn = firebase
    .functions()
    .httpsCallable('channels-creation-youtube-oauthRequest');
  return fn({ groupId });
};

export const tikTokOauth = async (requestId) => {
  window.location.href =
    BASE + `/channels-creation-tiktok-oauth?requestId=${requestId}`;
};

export const requestTikTokOauthToken = async (groupId) => {
  const fn = firebase
    .functions()
    .httpsCallable('channels-creation-tiktok-oauthRequest');
  return fn({ groupId });
};

export const facebookOauth = async (requestId) => {
  window.location.href =
    BASE + `/channels-creation-facebook-oauth?requestId=${requestId}`;
};

export const requestFacebookOauthToken = async (groupId) => {
  const fn = firebase
    .functions()
    .httpsCallable('channels-creation-facebook-oauthRequest');
  return fn({ groupId });
};

export const instagramOauth = async (requestId) => {
  window.location.href =
    BASE + `/channels-creation-instagram-oauth?requestId=${requestId}`;
};

export const requestInstagramOauthToken = async (groupId) => {
  const fn = firebase
    .functions()
    .httpsCallable('channels-creation-instagram-oauthRequest');
  return fn({ groupId });
};
