import { Add, ArrowBackIos, ArrowForwardIos, Menu } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import * as moment from 'moment-timezone';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { GroupsContext } from '../contexts/Groups';
import { SchedulesContext } from '../contexts/Schedules';
import useResponsive from '../hooks/useResponsive';
import { track } from '../utils/analytics';
import CalendarEvent from './CalendarEvent';
import CustomizedDialogs from './Dialog';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const TIME_DATA = [
  0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1,
  2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3,
  4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5,
  6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7,
  0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1,
  2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3,
  4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5,
  6, 7, 0, 1, 2, 3, 4, 5, 6, 7,
];

const CustomCalendar = ({ setOpenAccountsDrawer, isSubscriber }) => {
  const { channelsByGroupMap } = useContext(GroupsContext);
  const { height } = useWindowDimensions();
  const navigate = useNavigate();
  const { schedulesMap, firstDay, setFirstDay } = useContext(SchedulesContext);
  const [openNeedToConnectChannelsDialog, setOpenNeedToConnectChannelsDialog] =
    useState(false);

  const isDesktop = useResponsive('up', 'md');

  const haveChannelsConnected = useMemo(
    () =>
      Object.entries(channelsByGroupMap ?? {})
        .map(([k, c]) => c)
        .flat().length > 0,
    [channelsByGroupMap],
  );

  if (!firstDay) return <CircularProgress />;

  const schedules = firstDay && schedulesMap[firstDay.toDate().getTime()];

  useEffect(() => {
    if (firstDay) {
      setFirstDay(moment(firstDay));
    }
  }, []);

  const getRelevantEvents = (datetime) => {
    const relevantSchedules = [];
    for (let index = 0; index < schedules.length; index++) {
      const schedule = schedules[index];
      const scheduleMilliseconds = moment(
        schedule.data().scheduledOn.seconds * 1000,
      )
        .subtract(1, 'hour')
        .valueOf();
      const scheduleMillisecondsPlus1Hour = moment(scheduleMilliseconds)
        .add(1, 'hour')
        .valueOf();
      if (
        datetime > scheduleMilliseconds &&
        datetime <= scheduleMillisecondsPlus1Hour
      ) {
        relevantSchedules.push(schedule);
      }
    }
    return relevantSchedules;
  };

  return (
    <>
      {!isDesktop && (
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ pl: 3, pr: 3 }}
        >
          <Button
            variant="outlined"
            startIcon={<Menu />}
            style={{ width: 'fit-content' }}
            onClick={() => {
              setOpenAccountsDrawer(true);
            }}
          >
            Accounts
          </Button>
          <Button
            variant="contained"
            startIcon={<Add />}
            style={{ width: 'fit-content' }}
            onClick={() => {
              track('calendar_create_post_button_click');
              if (haveChannelsConnected) {
                navigate('/dashboard/upload');
              } else {
                setOpenNeedToConnectChannelsDialog(true);
              }
            }}
          >
            Create post
          </Button>
        </Stack>
      )}
      <Stack
        direction={'column'}
        sx={{
          bgcolor: 'white',
          borderRadius: isDesktop ? 3 : 0,
          boxShadow: '0px 0px 30px rgba(138, 138, 138, 0.08)',
          width: '100%',
          mt: isDesktop ? 0 : 3,
        }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={isDesktop ? 'start' : 'center'}
          spacing={2}
          sx={{ p: isDesktop ? 4 : 2 }}
        >
          {isDesktop ? (
            <Button
              variant="outlined"
              startIcon={<ArrowBackIos />}
              onClick={() => {
                const newDate = moment(firstDay.subtract(7, 'days'));
                track('calendar_weeks_back_button_click', {
                  toDate: newDate.toDate(),
                });

                setFirstDay(newDate);
              }}
            >
              Prev
            </Button>
          ) : (
            <IconButton
              onClick={() => {
                const newDate = moment(firstDay.subtract(7, 'days'));
                track('calendar_weeks_back_button_click', {
                  toDate: newDate.toDate(),
                });

                setFirstDay(newDate);
              }}
            >
              <ArrowBackIos />
            </IconButton>
          )}
          <Typography
            sx={{ fontSize: isDesktop ? 24 : 18, fontWeight: 700 }}
          >{`${moment(firstDay).format('DD MMM')} - ${moment(firstDay)
            .add(7, 'days')
            .format('DD MMM')}`}</Typography>
          {isDesktop ? (
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIos />}
              onClick={() => {
                const newDate = moment(firstDay.add(7, 'days'));
                track('calendar_weeks_forward_button_click', {
                  toDate: newDate.toDate(),
                });

                setFirstDay(newDate);
              }}
            >
              Next Next
            </Button>
          ) : (
            <IconButton
              onClick={() => {
                const newDate = moment(firstDay.add(7, 'days'));
                track('calendar_weeks_forward_button_click', {
                  toDate: newDate.toDate(),
                });

                setFirstDay(newDate);
              }}
            >
              <ArrowForwardIos />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }}></Box>
          {isDesktop && (
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => {
                track('calendar_create_post_button_click');
                if (haveChannelsConnected) {
                  navigate('/dashboard/upload');
                } else {
                  setOpenNeedToConnectChannelsDialog(true);
                }
              }}
            >
              Create post
            </Button>
          )}
        </Stack>
        <Box
          sx={{
            bgcolor: 'white',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            border: '0.5px solid #EEEEEE',
            height: isDesktop
              ? height - 210
              : isSubscriber
              ? height - 214
              : height - 280,
            overflow: 'scroll',
            position: 'relative',
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
          }}
        >
          <Box
            sx={{
              bgcolor: 'white',
              border: '0.5px solid #EEEEEE',
              position: 'sticky',
              top: 0,
              zIndex: 99,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
              borderLeft: 0,
              left: 0,
            }}
          >
            Timezone {moment(firstDay).format('Z z')}
          </Box>
          {new Array(7)
            .fill(0)
            .map((_, i) => moment(firstDay.toDate()).add(i, 'days'))
            .map((item, index) => (
              <Box
                key={item.toString()}
                sx={{
                  bgcolor: 'white',
                  border: '1px solid #EEEEEE',
                  position: 'sticky',
                  zIndex: 10,
                  top: 0,
                  p: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: 147,
                  borderRight: index === 6 ? 0 : '1px solid #EEEEEE',
                }}
              >
                {item.startOf('day').valueOf() ===
                moment(new Date()).startOf('day').valueOf() ? (
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography sx={{ color: '#616161' }}>
                      {item.format('ddd')}
                    </Typography>
                    <Typography
                      sx={{
                        bgcolor: 'primary.main',
                        color: 'white',
                        width: 28,
                        height: 28,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 2,
                        fontWeight: 600,
                      }}
                    >
                      {item.format('DD')}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography sx={{ color: '#616161' }}>
                      {item.format('ddd')}
                    </Typography>
                    <Typography
                      sx={{
                        width: 28,
                        height: 28,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 2,
                        fontWeight: 600,
                      }}
                    >
                      {item.format('DD')}
                    </Typography>
                  </Stack>
                )}
              </Box>
            ))}
          {TIME_DATA.map((item, index) => {
            const relevantEvents =
              schedules &&
              getRelevantEvents(
                moment(firstDay)
                  .add(1 * item + -1, 'day')
                  .add((1 * index) / 8, 'hours')
                  .startOf('hour')
                  .startOf('minute')
                  .startOf('seconds')
                  .startOf('millisecond')
                  .valueOf(),
              );

            const slotDate = moment(firstDay)
              .startOf('day')
              .startOf('hour')
              .startOf('minute')
              .add(1 * item + -1, 'day')
              .add((1 * index) / 8, 'hours');

            const timeSlotPassed =
              moment(new Date()).valueOf() >= slotDate.valueOf();
            return (
              <Box
                key={`${item}-${slotDate.valueOf()}`}
                sx={[
                  {
                    bgcolor:
                      item === 0 || !timeSlotPassed ? 'white' : '#FAFAFA',
                    position: 'sticky',
                    left: 0,
                    zIndex: item === 0 ? 11 : 9,
                    border: '0.5px solid #EEEEEE',
                    flex: 1,
                    p: item === 0 ? 2 : 0,
                    borderLeft: item === 0 ? 0 : '0.5px solid #EEEEEE',
                    borderRight: item === 7 ? 0 : '0.5px solid #EEEEEE',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                  {
                    '& .hidden-button': {
                      display: 'none',
                    },
                    '&:hover .hidden-button': {
                      display: 'flex',
                    },
                  },
                ]}
              >
                {item === 0 ? (
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {moment(firstDay)
                        .startOf('day')
                        .startOf('hour')
                        .startOf('minute')
                        .add((1 * index) / 8, 'hours')
                        .format('h')}
                    </Typography>
                    <Typography sx={{ color: '#616161' }}>
                      {moment(firstDay)
                        .startOf('day')
                        .startOf('hour')
                        .startOf('minute')
                        .add((1 * index) / 8, 'hours')
                        .format('a')
                        .toUpperCase()}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack
                    direction={'column'}
                    spacing={1}
                    sx={[{ width: '100%' }]}
                  >
                    {schedules &&
                      relevantEvents.map((schedule, index) => (
                        <CalendarEvent
                          schedule={schedule}
                          key={schedule.id}
                          inTheFuture={
                            moment(new Date()).valueOf() <
                            schedule.data().scheduledOn.toMillis()
                          }
                        />
                      ))}
                    {schedules && !timeSlotPassed && !relevantEvents.length && (
                      <Box
                        className="hidden-button"
                        sx={{
                          padding: '8px',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            paddingBlock: 1,
                            width: '100%',
                            borderColor: '#1976D2',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            height: '100%',
                            borderRadius: '4px',
                            backgroundColor: '#F6FAFD',
                          }}
                          onClick={() => {
                            track(
                              'calendar_empty_slot_create_post_button_click',
                              {
                                slotDate: moment(slotDate)
                                  .startOf('hour')
                                  .toDate(),
                              },
                            );
                            if (haveChannelsConnected) {
                              navigate(
                                '/dashboard/upload?scheduleAt=' +
                                  moment(slotDate).startOf('hour'),
                              );
                            } else {
                              setOpenNeedToConnectChannelsDialog(true);
                            }
                          }}
                        >
                          <Stack
                            direction={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={'4px'}
                          >
                            <img
                              src={require('../assets/icons/addSquare.png')}
                              width={16}
                              alt=""
                            />
                            <Typography
                              sx={{
                                color: '#1976D2',
                                fontWeight: 400,
                                fontSize: 12,
                              }}
                            >
                              Create Post
                            </Typography>
                          </Stack>
                        </Button>
                      </Box>
                    )}
                  </Stack>
                )}
              </Box>
            );
          })}
        </Box>
        <CustomizedDialogs
          title={'To create new posts you need to connect your social channels'}
          open={openNeedToConnectChannelsDialog}
          setOpen={setOpenNeedToConnectChannelsDialog}
          actions={[
            {
              title: 'Connect channels',
              variant: 'contained',
              function: () => navigate('/dashboard/manage-accounts'),
            },
            {
              title: 'Close',
              function: () => setOpenNeedToConnectChannelsDialog(false),
            },
          ]}
        ></CustomizedDialogs>
      </Stack>
    </>
  );
};

export default CustomCalendar;
