import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect } from 'react';
import ChannelIcon from './ChannelIcon';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-10px',
  bottom: '4px',
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 4,
  paddingBottom: 4,
  backgroundColor: '#F6FAFD',
  zIndex: 1000,
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const ChannelsSelection = ({
  groups,
  channelsByGroupId,
  channels,
  channelById,
  checkIfAllGroupAccountsAreSelected,
  selectOrUnselectAllChannelsByGroupId,
  setChannels,
  deleteChannel,
}) => {
  useEffect(() => {}, []);

  const handleChangeChannel = (_event, newChannel) => {
    // setCurrentChannel(newChannel);
  };

  return (
    <Stack
      direction={'column'}
      spacing={1}
      alignItems={'start'}
      sx={{ width: '100%' }}
    >
      <Typography sx={{ fontSize: 16, fontWeight: 700 }}>Publish to</Typography>
      <Autocomplete
        multiple
        fullWidth
        id="publish-to-channels"
        freeSolo={false}
        options={
          groups &&
          groups
            .map((group) =>
              channelsByGroupId(group.id)?.map((c) => ({
                groupId: group.id,
                channelId: c.id,
              })),
            )
            .flat(Infinity)
        }
        getOptionLabel={(option) => {
          return channelById(option.groupId, option.channelId)?.data()
            ?.channelName;
        }}
        groupBy={(option) => option?.groupId}
        value={channels}
        isOptionEqualToValue={(option, value) => {
          // console.log('option', option, 'value', value);
          return (
            option.channelId === value.channelId &&
            option.groupId === value.groupId
          );
        }}
        renderTags={(_value, _getTagProps) => (
          <Stack direction="row" flexWrap={'wrap'} rowGap={'4px'} gap={'4px'}>
            {channels.map((channel, _index) => {
              return (
                <Chip
                  key={channel.id}
                  avatar={
                    <Box>
                      <ChannelIcon
                        channelType={
                          channelById(
                            channel.groupId,
                            channel.channelId,
                          )?.data().type
                        }
                        size={24}
                      />
                    </Box>
                  }
                  variant="contained"
                  label={
                    channelById(channel.groupId, channel.channelId)?.data()
                      .channelName
                  }
                  onDelete={() => deleteChannel(channel.channelId)}
                  {..._getTagProps}
                />
              );
            })}
          </Stack>
        )}
        disableCloseOnSelect
        renderOption={(props, option, state) => {
          return (
            <li {...props} key={`${option.groupId}-${option.channelId}`}>
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ width: '100%' }}
                spacing={2}
              >
                <Box sx={{ position: 'relative' }}>
                  <Avatar
                    alt={
                      channelById(option.groupId, option.channelId).data()
                        ?.channelName
                    }
                    src={
                      channelById(option.groupId, option.channelId).data()
                        ?.thumbnailUrl
                    }
                    sx={{ height: 36, width: 36 }}
                  />
                  <Box
                    sx={{ position: 'absolute', bottom: '-6px', right: '-2px' }}
                  >
                    <ChannelIcon
                      channelType={
                        channelById(option.groupId, option.channelId)?.data()
                          .type
                      }
                      size={16}
                    />
                  </Box>
                </Box>
                <Typography sx={{ flexGrow: 1 }}>
                  {
                    channelById(option.groupId, option.channelId)?.data()
                      .channelName
                  }
                </Typography>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={state.selected}
                />
              </Stack>
              <Divider />
            </li>
          );
        }}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ width: '100%' }}
                spacing={1}
              >
                <Typography sx={{ flexGrow: 1 }}>
                  {
                    groups
                      .filter((group) => params.group === group.id)[0]
                      .data().name
                  }
                </Typography>
                <Stack
                  direction={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  spacing={2}
                >
                  <Typography sx={{ fontSize: 12, color: '#1976D2' }}>
                    Select all
                  </Typography>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={checkIfAllGroupAccountsAreSelected(params.group)}
                    onChange={(_e, checked) =>
                      selectOrUnselectAllChannelsByGroupId(
                        checked,
                        params.group,
                      )
                    }
                  />
                </Stack>
              </Stack>
            </GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        onChange={(_e, v) => {
          setChannels([
            ...v.map((c) => ({
              groupId: c.groupId,
              channelId: c.channelId,
            })),
          ]);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            placeholder="Select social media account"
          />
        )}
      />
    </Stack>
  );
};

export default ChannelsSelection;
