import { Box, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useResponsive from '../hooks/useResponsive';

const BACKGROUND = {
  width: '100%',
  height: '100%',
  bgcolor: 'white',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  p: { mobile: 2, desktop: 4 },
};

const FORM_CONTAINER_MOBILE = {
  bgcolor: 'white',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '460px',
};

const INFO_CONTAINER = {
  background: 'linear-gradient(333.05deg, #1976D2 0%, #1565C0 97.74%);',
  width: '100%',
  height: 'calc(100vh - 64px)',
  borderRadius: 4,
  paddingLeft: 60,
};

const LOGO = {
  objectFit: 'contain',
  width: 'unset',
};

const infoSteps = [
  {
    title: 'Create an Account',
    description:
      'Supercharge your workflow and connect the social media accounts you use every day to the account',
  },
  {
    title: 'Add Integration',
    description:
      'Supercharge your workflow and connect the social media accounts you use every day to the account',
  },
  {
    title: 'Upload Posts',
    description:
      'Supercharge your workflow and connect the social media accounts you use every day to the account',
  },
  {
    title: 'Schedule Posts',
    description:
      'Supercharge your workflow and connect the social media accounts you use every day to the account',
  },
];

const OnboardingLayout = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');
  const [swiper, setSwiper] = useState(null);

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Container sx={BACKGROUND} maxWidth={false} disableGutters>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={12} md={6} lg={6} xl={6}>
          <Container sx={FORM_CONTAINER_MOBILE} maxWidth={'460px'}>
            {children}
          </Container>
        </Grid>
        {isDesktop && (
          <Grid item xs={0} sx={0} md={6} lg={6} xl={6}>
            <Box sx={{ paddingInline: 4 }}>
              <Container sx={INFO_CONTAINER}>
                <Stack
                  direction={'column'}
                  alignItems={'stretch'}
                  sx={{ p: 8, height: '100%' }}
                  justifyContent="space-between"
                >
                  <Box sx={{ display: 'flex' }}>
                    <img
                      alt="logo"
                      src={require('../assets/images/whiteLogo.png')}
                      height={60}
                      style={LOGO}
                    />
                  </Box>
                  <Stack direction={'column'} alignItems={'start'} spacing={2}>
                    <img
                      alt="logo"
                      src={require('../assets/images/iconLogo.png')}
                      height={80}
                      style={LOGO}
                    />
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: 32,
                        textAlign: 'start',
                        fontWeight: 400,
                      }}
                    >
                      Uploading, scheduling, and publishing your video content
                      to the most popular social networks.
                    </Typography>
                  </Stack>
                  <Stack direction={'column'} alignItems={'start'} spacing={2}>
                    <Swiper
                      dir={theme.direction}
                      autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                      style={{ width: '100%', height: '100%' }}
                      onSlideChange={(swiper) =>
                        setActiveIndex(swiper.activeIndex)
                      }
                      onSwiper={(swiper) => setSwiper(swiper)}
                    >
                      {infoSteps.map((step, index) => (
                        <SwiperSlide key={step.title}>
                          <Stack
                            direction={'column'}
                            alignItems={'start'}
                            spacing={2}
                          >
                            <Typography
                              sx={{
                                color: 'white',
                                fontSize: 24,
                                textAlign: 'start',
                                fontWeight: 400,
                              }}
                            >
                              {`Step ${index + 1}`}
                            </Typography>
                            <Typography
                              sx={{
                                color: 'white',
                                fontSize: 32,
                                textAlign: 'start',
                                fontWeight: 700,
                              }}
                            >
                              {step.title}
                            </Typography>
                            <Typography
                              sx={{
                                color: 'white',
                                opacity: 0.8,
                                fontSize: 16,
                                textAlign: 'start',
                                fontWeight: 400,
                              }}
                            >
                              {step.description}
                            </Typography>
                          </Stack>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div></div>
                    <Stack
                      direction={'row'}
                      sx={{ width: '100%', marginTop: 32 }}
                      spacing={2}
                    >
                      <Box
                        onClick={() => swiper?.slideTo(0)}
                        sx={{
                          height: 14,
                          bgcolor:
                            activeIndex === 0
                              ? 'white'
                              : 'rgba(255, 255, 255, 0.4)',
                          flex: 1,
                          borderRadius: 2,
                          cursor: 'pointer',
                        }}
                      ></Box>
                      <Box
                        onClick={() => swiper?.slideTo(1)}
                        sx={{
                          height: 14,
                          bgcolor:
                            activeIndex === 1
                              ? 'white'
                              : 'rgba(255, 255, 255, 0.4)',
                          flex: 1,
                          borderRadius: 2,
                          cursor: 'pointer',
                        }}
                      ></Box>
                      <Box
                        onClick={() => swiper?.slideTo(2)}
                        sx={{
                          height: 14,
                          bgcolor:
                            activeIndex === 2
                              ? 'white'
                              : 'rgba(255, 255, 255, 0.4)',
                          flex: 1,
                          borderRadius: 2,
                          cursor: 'pointer',
                        }}
                      ></Box>
                      <Box
                        onClick={() => swiper?.slideTo(3)}
                        sx={{
                          height: 14,
                          bgcolor:
                            activeIndex === 3
                              ? 'white'
                              : 'rgba(255, 255, 255, 0.4)',
                          flex: 1,
                          borderRadius: 2,
                          cursor: 'pointer',
                        }}
                      ></Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Container>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default OnboardingLayout;
